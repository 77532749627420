import { AfterViewInit, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { Utenti } from 'src/app/shared/utenti';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ChangeDetectorRef } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Md5} from 'ts-md5/dist/md5';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.css']
})
export class UtentiComponent implements OnInit {
  displayedColumns: string[] = ['username', 'nome', 'cognome', 'email', 'cliente', 'action'];
  dataSource: MatTableDataSource<Utenti>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  idutente:string;
  idcliente:string;
  idutenteload:string;
  idmodal:string;
  nome:string;
  cognome:string;
  username:string;
  email:string;
  current_password:string;
  password:string;
  confirmpassword:string;
  ruolo:string;
  attivo:string;
  Utenti: any = [];
  Clienti: any = [];
  UtenteSel: any = [];
  PiattaformeUtenti: any = [];
  Piattaforme: any = [];
  keysearch: string;
  status:string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  showList:boolean;
  schema:string;
  platform_state:string;
  share_message;
  showpwd1: boolean = false;
  showpwd2: boolean = false;

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  
  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }


  setMessage(href,page) {
    this.share_message.page_href = href;
    this.share_message.page_title = page;
    this.sharedService.sharedMessage = this.share_message;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.root_href = "/";
    this.root_title = "Dashboard";
    this.share_message = this.sharedService.sharedMessage;
    this.setMessage('utenti', 'Gestione Utenti');
    //console.log('init');
    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }

    if (sessionStorage.getItem("authorize") === null) {
      //console.log('auth null');
      this.router.navigate(['/login']);
    }else{
      var auth = sessionStorage.getItem("authorize");
      //console.log('auth = ' + auth);
      if(auth == "0000" || auth == "9999"){
        this.router.navigate(['/login']);
      }
    }
    this.showList = true;
    this.status = "master";
    this.destitle = "Nuovo utente";
    this.loadUtenti();
    this.loadClienti();
    this.keysearch = "";
    //this.breakpoint = (window.innerWidth <= 600) ? 1 : 2;
  }

  onResize(event) {
    //this.breakpoint = (event.target.innerWidth <= 600) ? 1 : 2;
  }

  loadUtenti() {
    this.keysearch = "";

    return this.restApi.getUtenti(this.token).subscribe((data: {}) => {
      this.Utenti = data;
      var count = this.Utenti.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.Utenti);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  loadPiattaforme() {
    this.keysearch = "";
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    return this.restApi.getPiattaformeList(token).subscribe((data: {}) => {
      this.Piattaforme = data;
      var count = this.Piattaforme.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }

    })
  }

  loadClienti() {
    this.keysearch = "";
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    this.restApi.getClienti(token).subscribe((data: {}) => {
      this.Clienti = data;
    });
  }

  searchItemUtenti(){
    if(this.keysearch != ""){
      return this.restApi.getRicercaUtenti(this.keysearch, this.token).subscribe((data: {}) => {
        this.Utenti = data;
        this.dataSource = new MatTableDataSource(this.Utenti);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.changeDetectorRefs.detectChanges();
      });
    }else{
      var message = "Nessuna keyword di ricerca inserita. Riprovare."
      var action = "ok"
      this.openSnackBar(message, action);
    }
  }

  loadMaster(){
    this.loadUtenti();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.status = "detail";
    console.log("detail");
  }

  utenteLoad(id){
    this.idutenteload = id;
    this.restApi.getUtente(id, this.token).subscribe((data: {}) => {
      this.UtenteSel = data;
      this.idutente =  this.UtenteSel[0].idutente;
      this.idcliente =  this.UtenteSel[0].idcliente;
      this.nome = this.UtenteSel[0].nome;
      this.cognome = this.UtenteSel[0].cognome;
      this.username = this.UtenteSel[0].username;
      this.email = this.UtenteSel[0].email;
      this.current_password = this.UtenteSel[0].password;
      console.log('current_password = ' + this.current_password);
      this.ruolo = this.UtenteSel[0].auth_level;
      this.attivo = this.UtenteSel[0].attivo;
  
      this.status = "detail";
      this.destitle = "Modifica utente";

    });

    this.restApi.getPiattaforme(id, this.token).subscribe((data: {}) => {
      this.PiattaformeUtenti = data;
    });

  }

  newUtente(){
    this.status = "detail";
    this.action = "new";
    this.nome = "";
    this.cognome = "";
    this.username = "";
    this.email = "";
    this.password = "";
    this.confirmpassword = "";
    this.ruolo = "";
    this.attivo = "";
    this.idutente = "";
  }

  saveUtente(){
   var utente: any = [];

   if(this.action == "new"){
    if(this.password != null && this.confirmpassword != null){
      console.log('password not empty');
      if(this.password == this.confirmpassword){
        //console.log('pass valid');
        const md5 = new Md5();
        var pwd = md5.appendStr(this.password).end();
        //console.log('pwd md5 = ' + pwd);

        utente = {
          "cognome": this.cognome,
          "nome": this.nome,
          "username": this.username,
          "email": this.email,
          "password": pwd,
          "auth_level": this.ruolo,
          "attivo": this.attivo, 
          "idcliente": this.idcliente
        };
      
        this.restApi.createUtente(utente, this.token).subscribe((data: {}) => {
          this.restApi.getUtentLast(this.token).subscribe((data: {}) => {
            this.UtenteSel = data;
            this.idutente =  this.UtenteSel[0].idutente;
            this.nome = this.UtenteSel[0].nome;
            this.cognome = this.UtenteSel[0].cognome;
            this.username = this.UtenteSel[0].username;
            this.email = this.UtenteSel[0].email;
            this.current_password = this.UtenteSel[0].password;
            console.log('current_password = ' + this.current_password);
            this.ruolo = this.UtenteSel[0].auth_level;
            this.attivo = this.UtenteSel[0].attivo;
          });
        });
        var message = "Salvataggio avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      }else{
        var message = "Le due password non coincidono. Impossibile continuare";
        var action = "ok"
        this.openSnackBar(message, action);        
      }
    }else{
      var message = "Password e conferma password obbligatori. Impossibile continuare";
      var action = "ok"
      this.openSnackBar(message, action);
    }
  }else{

    utente = {
        "cognome": this.cognome,
        "nome": this.nome,
        "username": this.username,
        "email": this.email,
        "auth_level": this.ruolo,
        "attivo": this.attivo,
        "idutente": this.idutente,
        "idcliente": this.idcliente
      };

      this.restApi.updateUtente(utente, this.token).subscribe((data: {}) => {});
      var message = "Aggiornamento utente avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);

  }
  }

  changePwd(){
    if(this.password != null && this.confirmpassword != null){
      console.log('password not empty');
      if(this.password == this.confirmpassword){
        //console.log('pass valid');
        const md5 = new Md5();
        var pwd = md5.appendStr(this.password).end();
        //console.log('pwd md5 = ' + pwd);

        var changepwd = {
          "password": pwd,
          "idutente": this.idutente
        };
      
        this.restApi.changePwd(changepwd, this.token).subscribe((data: {}) => {
          this.restApi.getUtentLast(this.token).subscribe((data: {}) => {
            this.UtenteSel = data;
            this.idutente =  this.UtenteSel[0].idutente;
            this.nome = this.UtenteSel[0].nome;
            this.cognome = this.UtenteSel[0].cognome;
            this.username = this.UtenteSel[0].username;
            this.email = this.UtenteSel[0].email;
            this.current_password = this.UtenteSel[0].password;
            console.log('current_password = ' + this.current_password);
            this.ruolo = this.UtenteSel[0].auth_level;
            this.attivo = this.UtenteSel[0].attivo;
          });
        });
        var message = "Cambio password avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      }else{
        var message = "Le due password non coincidono. Impossibile continuare";
        var action = "ok"
        this.openSnackBar(message, action);        
      }
    }
  }

  openModalDelete(content, idutente) {
    this.idutente = idutente;
    this.idmodal = "normal";
    this.titleModal = "Elimina Utente";
    this.bodyModal = "Vuoi eliminare l'utente selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalJoin(content, idutente) {
    this.idutente = idutente;
    this.idmodal = "normal";
    this.titleModal = "Elimina Join";
    this.bodyModal = "Vuoi eliminare il Join selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "2";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalActivateJoin(content, idutente, attiva) {
    this.idutente = idutente;
    this.platform_state = attiva;
    this.idmodal = "normal";
    this.titleModal = "Attiva/disattiva Join";
    this.bodyModal = "Vuoi cambiare lo stato del Join selezionato?";
    this.buttonAction = "Cambia";
    this.actionOp = "3";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalSelPlatform(content) {
    this.loadPiattaforme();
    this.idmodal = "selplatform";
    this.titleModal = "Nuovo Join";
    this.bodyModal = "";
    this.buttonAction = "Seleziona";
    this.actionOp = "";
    this.modalService.open(content, {size:'lg', backdrop: 'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); 
  }

  selPlatformJoin(content, idpiattaforma){
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var piattaforma = {
      "token": token,
      "idutente": this.idutenteload,
      "idpiattaforma": idpiattaforma,
      "attiva": "1"
    };

    this.restApi.createPiattaformaJoin(piattaforma).subscribe((data: {}) => {
      this.restApi.getPiattaforme(this.idutenteload, this.token).subscribe((data: {}) => {
        this.PiattaformeUtenti = data;
        var message = "Join creato con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      });
    });

  }

  deleteUtente(id){
    console.log('idutente = ' + id);
    var utente = {
      idutente: id
    }; 
    this.restApi.deleteUtente(utente, this.token).subscribe((data: {}) => {
      this.loadUtenti();
      var message = "Utente eliminato con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }

  deleteJoin(id){
    console.log('idjoin utente = ' + id);
    var utente = {
      idpiattaforma_utente: id
    }; 
    this.restApi.deleteJoinPiattaforma(utente).subscribe((data: {}) => {
      this.restApi.getPiattaforme(this.idutenteload, this.token).subscribe((data: {}) => {
        this.PiattaformeUtenti = data;
      });
    });
   
    var message = "Join eliminato con successo.";
    var action = "ok"
    this.openSnackBar(message, action);
  }

  changeStatusJoin(id){
    var status = "";
    console.log('attiva = ' + this.platform_state);
    if(this.platform_state == "1"){
      status = "0";
      console.log('status = ' + status);
    }else{
      status = "1";
      console.log('status = ' + status);
    }

    var updstate = {
      "attiva": status,
      "idpiattaforma_utente": id
    };

    this.restApi.updateJoinStatus(updstate, this.token).subscribe((data: {}) => {
      this.utenteLoad(this.idutenteload);
      var message = "Modifica status Join avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }

  showpswd1() {
    this.showpwd1 = !this.showpwd1;
  }

  showpswd2() {
    this.showpwd2 = !this.showpwd2;
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

<link rel="stylesheet" href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css" integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA==" crossorigin="" />

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />

<div class="row m-0">
  <div id="mapcontainer" class="map-container" #screen>
    <div class="map-frame">
      <div id="map" #screen></div>
    </div>
  </div>
  <div id="report" style="width: 100%; height: 90vh; margin-top: 20px; margin-left:200px; margin-left: auto; margin-bottom: 0px;
      margin-right: 0;">
  </div>
  <div id='in'>
    <div class="btn-group mr-1" role="group">
      <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{mapselected}}
      </button>
      <div class="dropdown-menu primary" aria-labelledby="btnGroupDrop1">
        <button class="dropdown-item primary" href="#" (click)="changeMap('grayscale')">Grayscale</button>
        <button class="dropdown-item primary" href="#" (click)="changeMap('streets')">Streets</button>
        <button class="dropdown-item" href="#" (click)="changeMap('satellite')">Satellite</button>
        <button class="dropdown-item" href="#" (click)="changeMap('satellite2')">Satellite 2</button>
        <button class="dropdown-item" href="#" (click)="changeMap('navigation_day')">Navigation Day</button>
        <button class="dropdown-item" href="#" (click)="changeMap('navigation_night')">Navigation Night</button>
        <button class="dropdown-item" href="#" (click)="changeMap('openseamap')">Openseamap</button>
      </div>
      <!-- <button type="button" class="btn btn-primary" (click)="mapRefreshDataApp()">TEST</button> -->
    </div>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-primary" (click)="zoomin()"><i class="fa fa-plus" aria-hidden="true"></i></button>
      <button type="button" class="btn btn-primary" (click)="zoomout()"><i class="fa fa-minus" aria-hidden="true"></i></button>
      <button type="button" class="btn btn-primary">{{currentzoom}}</button>
    </div>
    <div class="btn-group ml-2" role="group" aria-label="Basic example">
      <button type="button" [ngClass]="measurement == false ? 'btn btn-danger' : 'btn btn-danger active'" (click)="activeMeasurement()" data-toggle="tooltip" data-placement="left" title="{{messageMeasurement}}"><i class="fa fa-drafting-compass"></i></button>
      <button type="button" class="btn btn-danger" (click)="setUnits()" style="width:50px;" data-toggle="tooltip" data-placement="left" title="Change units">{{units}}</button>
      <button type="button" class="btn btn-danger" (click)="clearMeasurement()" data-toggle="tooltip" data-placement="left" title="Clear measurement"><i class="fas fa-eraser"></i></button>
    </div>
  </div>
  
  <div id='print'>
    <div class="btn-group" role="group" aria-label="Pdf">
      <button type="button" class="btn btn-danger" (click)="closepdf()"><i class="fa fa-fw" aria-hidden="true" title="return to the well"></i></button>
    </div>
  </div>
  <div class="card" id="modaldata" style="position:absolute;margin-top:40px;margin-left:300px; width:580px; height:570px; z-index:10000;" cdkDrag>
    <div class="card-header pt-0 pl-1 pb-0 pr-0 bg-primary">
      <div class="row p-0 m-0">
        <div class="col-11 m-0 p-0 pl-1 pt-1">
          <h4 class="modal-title text-white" id="title"></h4> 
        </div>
        <div class="col-1 m-0 p-0 pl-3 pt-2">
          <div class="example-handle" cdkDragHandle>
            <svg width="24px" fill="#fff" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
      </div>
    </div>
    </div>
    <div class="card-body">
            <div class="row">
                <div class="col-7 mr-n3">
                  <img id="imageplatform" src="http://rcs.explorermarinesboss.com/api/images/keymanattan.jpg"/>
                </div>
                <div class="col-5 pl-0 pr-1">
                  <div class="alert alert-primary m-1 p-0 pl-2" role="alert">
                    Datum: <code style="color:#000;">WGS84</code>
                  </div>
                  <div class="alert alert-primary m-1 p-0 pl-2" role="alert">
                    Ref. point: <code id="referencepoint" style="color:#000;"></code>
                  </div>
                  <div class="alert alert-primary m-1 p-0 pl-2" role="alert">
                    <span style="padding-right:23px;">Latitude:</span> <code id="latitude" style="color:#000;"></code>
                  </div>
                  <div style="padding-right:2px;" class="alert alert-primary m-1 p-0 pl-2" role="alert">
                    Longitude: <code id="longitude" style="color:#000;"></code>
                  </div>
                  <div class="alert alert-primary m-1 p-0 pl-2" role="alert">
                    Heading: <code id="heading" style="color:#000;"></code>
                  </div>
                </div>
            </div> 
            <div class="row">
              <div class="alert alert-secondary mt-2 mr-0 w-100" style="height:62px;" role="alert">
                <span class="alert-link" id="twentyfouractivity">Next 24 hour activity: </span>. 
              </div>
              <h6 class="modal-title text-primary pt-0 pb-1 pl-2"><strong>Ongoing activity</strong></h6>
              <div class="col-12" style="overflow-y: scroll;overflow-x: hidden;height: 90px;" *ngIf="showmainevent == true">
              <table class="table table-sm table-striped">
                <thead class="table">
                  <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of ListMainEvent;">
                    <th width="120px" scope="row">{{obj.data}}</th>
                    <td>{{obj.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-danger col-12" role="alert" *ngIf="showmainevent == false">
              Information: No main event record present.
            </div>
            </div>
            <div class="row" *ngIf="showattach == true">
              <h4 class="modal-title text-primary pt-1 pb-1 pl-2">Attachments</h4>
              <table class="table">
                <thead class="table">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Final document</td>
                    <td class="text-right"><button type="button" class="btn btn-primary btn-sm float-end">download</button></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Mark</td>
                    <td class="text-right"><button type="button" class="btn btn-primary btn-sm float-end">download</button></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Mark</td>
                    <td class="text-right"><button type="button" class="btn btn-primary btn-sm float-end">download</button></td>
                  </tr>
                </tbody>
              </table>
            </div> 
    </div>
    <div class="card-footer">
      <input type="hidden" id="idpiattaforma"/>
      <input type="hidden" id="idpiattaformautente"/>
      <button type="button" class="btn btn-danger float-right" 
          (click)="closePopup()">
              Close
      </button>
      <button type="button" class="btn btn-primary float-right mb-2 mr-1" 
        (click)="requestRDP()">
        Monitor
      </button>
      <button type="button" class="btn btn-primary float-right mb-2 mr-1" (click)="maplocationrig()">Map location</button>
    </div>
  </div>

  <div class="card" id="modaldata1" style="position:absolute;margin-top:40px;margin-left:300px; width:600px;z-index:10001;" cdkDrag>
    <div class="card-header pt3 bg-primary">
      <div class="row p-0 m-0">
        <div class="col-11 m-0 p-0 pl-1 pt-1">
          <h4 class="modal-title text-white" id="title" style="font-family: 'Calibri Light',merriweather sans,sans-serif; font-size:18pt; font-weight:bold;">{{titleWell}}</h4> 
        </div>
        <div class="col-1 m-0 p-0 pl-3 pt-2">
          <div class="example-handle" cdkDragHandle>
            <svg width="24px" fill="#fff" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
      </div>
    </div>
    </div>
	<div>
	  <div class="alert alert-primary m-1 p-0 pl-2 ps-2" role="alert">
      <span style="font-family: 'Calibri Light',merriweather sans,sans-serif;font-size:14pt;font-weight: bold;">RIG: <code style="color:#000; font-family: 'Calibri Light',merriweather sans,sans-serif; font-size: 12pt;">{{piattaforma}}</code></span>
      <span style="font-family: 'Calibri Light',merriweather sans,sans-serif;font-size:14pt;font-weight: bold;margin-left:20px;">Country: <code id="latitude" style="color:#000; font-family: 'Calibri Light',merriweather sans,sans-serif; font-size: 12pt;">{{nazione}}</code></span>
      <span style="margin-left:20px;font-family: 'Calibri Light',merriweather sans,sans-serif;font-size:14pt;font-weight: bold;">Year: <code id="longitude" style="color:#000;">{{anno}}</code></span>
    </div>
  </div>
    <div class="card-body" style="height: 400px!important;">
      <!-- <div class="row" > -->
        <ul class="tabs" role="tablist" style="width:570px!important;height: 350px!important;">
          <li>
              <input type="radio" name="tabs" id="tab1" checked />
              <label for="tab1" 
                     role="tab" 
                     aria-selected="true" 
                     aria-controls="panel1" 
                     tabindex="0" style="font-weight: bold; width:120px!important; text-align: center;">Reports</label>
              <div id="tab-content1" 
                   class="tab-content tab-content p-3" 
                   role="tabpanel" 
                   aria-labelledby="coordinated" 
                   aria-hidden="false" style="margin-top:100px;">
                   <div style="overflow-y: scroll;overflow-x: hidden;height: 300px;">
                   <table class="table table-sm" *ngIf="showtablereport == true">
                    <thead>
                      <tr>
                        <th scope="col">Report</th>
                        <th scope="col">Lat</th>
                        <th scope="col">Lng</th>
                        <th scope="col">Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let obj of ListCoordinates;">
                        <td>{{obj.descrizione}}</td>
                        <td>{{obj.latitudegrad}}</td>
                        <td>{{obj.longitudegrad}}</td>
                        <td style="text-align:center;" ><span *ngIf="obj.pathfile"><a (click)="openpdf(obj.pathfile)" href="#"><i class="fa fa-fw fa-lg" aria-hidden="true" title="view document"></i></a></span></td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
          </li>
          <li>
            <input type="radio" name="tabs" id="tab2" />
            <label for="tab2"
                   role="tab" 
                   aria-selected="false" 
                   aria-controls="panel2" 
                   tabindex="0"  style="font-weight: bold;width:120px!important; text-align: center;">KML</label>
            <div id="tab-content2" 
                 class="tab-content"
                 role="tabpanel" 
                 aria-labelledby="kml" 
                 aria-hidden="true" style="margin-top:100px;">
                 <div class="row">
                 <div class="col-5 btn-group mb-2" role="group" aria-label="Basic example">
                  <button type="button" [ngClass]="activekmltab == 'general' ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'" (click)="setStatusTabKml()">General</button>
                  <button type="button" [ngClass]="activekmltab == 'upload' ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'" (click)="setStatusTabKml()">Upload</button>
                </div>
                <div class="col-4 btn-group mb-2" role="group" aria-label="Basic example">

                </div>
                <div class="col-3 btn-group mb-2" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-sm btn-outline-success active" (click)="activeAllKml()">Active all</button>
                </div>
                </div>
                 <div style="overflow-y: scroll;overflow-x: hidden;height: 250px;" *ngIf="activekmltab == 'general'">
                  <table class="table table-sm table-hover table-striped" *ngIf="showtablereport == true">
                   <thead>
                     <tr>
                       <th scope="col">Kml</th>
                       <th scope="col" style="text-align: center;width: 40px;">Status</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr *ngFor="let obj of ListKmlWell;">
                       <td>{{obj.descrizione}}</td>
                       <td style="text-align:center; width: 80px;" ><button type="button" [ngClass]="obj.active == 0 ? 'btn btn-danger btn-sm pl-1 pr-1 pt-0 pb-0 mr-1' : 'btn btn-success btn-sm pl-1 pr-1 pt-0 pb-0 mr-1'" (click)="changeStatusKml(obj.idkmz, obj.active)"><i *ngIf="obj.active != 0" class="fa fa-fw" aria-hidden="true" title="kml active"></i><i *ngIf="obj.active == 0" class="fa fa-fw" aria-hidden="true" title="kml inactive"></i></button><button class="btn btn-danger btn-sm pl-1 pr-1 pt-0 pb-0"><i class="fa fa-fw" aria-hidden="true" title="Elimina kml"></i></button></td>
                     </tr>
                   </tbody>
                 </table>
                 </div>
                 <div *ngIf="activekmltab == 'upload'">
                  <div class="input-group input-group-sm pt-1 pb-3 m-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text input-group-text-sm">Seleziona file...</span>
                        <span class="input-group-text input-group-text-sm"  *ngIf="progress > 0">{{progress}}% {{message}}</span>
                    </div>
                    <input type="file" id="fileToUpload" #file class="form-control form-control-sm pt-0" accept=".kml" (change)="uploadFiles(file.files, 'kml', content)" /> <br />
                    <div class="input-group-append">
                        <button id="upload" class="btn btn-outline-primary btn-sm">Upload</button>
                    </div>
                </div>
                <div class="alert alert-success" role="alert" *ngIf="uploadfile_msg == true">
                  <h4 class="alert-heading">Sezione caricamento files</h4>
                  <p>Nessun file presente nella sezione.</p>
                </div>
                <table class="table table-striped" *ngIf="uploadfile_msg == false">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">File</th>
                      <th scope="col" style="text-align: right;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let obj of ListFilesTemp">
                      <th scope="row">{{obj.IdFile}}</th>
                      <td>{{obj.File}}</td>
                      <td style="text-align: right;"><button type="button" class="btn btn-danger btn-sm" (click)="removeFile(obj.IdFile)"><i class="fa fa-fw" aria-hidden="true" title=""></i></button></td>
                    </tr>
                  </tbody>
                </table>
                 </div>
            </div>
          </li>
          <li>
            <input type="radio" name="tabs" id="tab3" />
            <label for="tab3"
                   role="tab" 
                   aria-selected="false" 
                   aria-controls="panel3" 
                   tabindex="0"  style="font-weight: bold;padding-bottom:5px; width:120px!important; text-align: center;">Documents</label>
            <div id="tab-content3" 
                 class="tab-content"
                 role="tabpanel" 
                 aria-labelledby="documents" 
                 aria-hidden="true" style="margin-top:100px;">
                 <div class="row">
                  <div class="col-5 btn-group mb-2" role="group" aria-label="Basic example">
                    <button type="button" [ngClass]="activedoctab == 'general' ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'" (click)="setStatusTabDoc()">General</button>
                    <button type="button" [ngClass]="activedoctab == 'upload' ? 'btn btn-sm btn-outline-primary active' : 'btn btn-sm btn-outline-primary'" (click)="setStatusTabDoc()">Upload</button>
                 </div>
                 <div class="col-4 btn-group mb-2" role="group" aria-label="Basic example">
 
                 </div>
                 <div class="col-3 btn-group mb-2" role="group" aria-label="Basic example">
                   <!-- <button type="button" class="btn btn-sm btn-outline-success active">Show all</button> -->
                 </div>
                 </div>
                 <div style="overflow-y: scroll;overflow-x: hidden;height: 200px;" *ngIf="activedoctab == 'general'">
                  <table class="table table-sm">
                   <thead>
                     <tr>
                       <th scope="col">Description</th>
                       <th scope="col" style="width:70px;padding-left:4px;">Action</th>
                     </tr>
                   </thead>
                   <tbody>
                    <tr *ngFor="let item of ListDocuments; let i = index">
                      <td>{{item.descrizione}}</td>
                      <td>
                        <button type="button" class="btn btn-danger btn-sm p-1 ml-1" (click)="openpdf(item.pathfile)"><i class="fa fa-fw" aria-hidden="true" title="file-pdf-o"></i></button>
                      </td>
                    </tr>
                   </tbody>
                 </table>
                 </div>
                 <div *ngIf="activedoctab == 'upload'">
                  <div class="input-group input-group-sm pt-1 pb-3 m-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text input-group-text-sm">Seleziona file...</span>
                        <span class="input-group-text input-group-text-sm"  *ngIf="progress2 > 0">{{progress2}}% {{message2}}</span>
                    </div>
                    <input type="file" id="fileToUpload2" #file2 class="form-control form-control-sm pt-0" accept=".pdf,.doc,.docx,.dwg" (change)="uploadFiles(file2.files, 'doc', content)" /> <br />
                    <div class="input-group-append">
                        <button id="upload" class="btn btn-outline-primary btn-sm">Upload</button>
                    </div>
                </div>
                <div class="alert alert-success" role="alert" *ngIf="uploadfile_msg == true">
                  <h4 class="alert-heading">Sezione caricamento files</h4>
                  <p>Nessun file presente nella sezione.</p>
                </div>
                <table class="table table-striped" *ngIf="uploadfile_msg == false">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">File</th>
                      <th scope="col" style="text-align: right;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let obj of ListFilesTemp2">
                      <th scope="row">{{obj.IdFile}}</th>
                      <td>{{obj.File}}</td>
                      <td style="text-align: right;"><button type="button" class="btn btn-danger btn-sm" (click)="removeFile(obj.IdFile)"><i class="fa fa-fw" aria-hidden="true" title=""></i></button></td>
                    </tr>
                  </tbody>
                </table>
                 </div>
            </div>
          </li>
          <li>
          <input type="radio" name="tabs" id="tab4" />
          <label for="tab4"
                 role="tab" 
                 aria-selected="false" 
                 aria-controls="panel4" 
                 tabindex="0"  style="font-weight: bold;padding-bottom:5px; width:120px!important; text-align: center;">Main events</label>
          <div id="tab-content4" 
               class="tab-content"
               role="tabpanel" 
               aria-labelledby="mainevents" 
               aria-hidden="true" style="margin-top:100px;">
               <div style="overflow-y: scroll;overflow-x: hidden;height: 300px;">
                <table class="table table-sm table-striped">
                  <thead class="table">
                    <tr>
                      <th scope="col">Data</th>
                      <th scope="col">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let obj of ListMainEvent;">
                      <th width="120px" scope="row">{{obj.data}}</th>
                      <td>{{obj.description}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="col-12 alert alert-danger" role="alert" *ngIf="showmainevent == false">
                Information: No main event record present.
              </div> -->
          </div>
          </li>
          <!-- <li>
            <input type="radio" name="tabs" id="tab5" />
            <label for="tab5"
                   role="tab" 
                   aria-selected="false" 
                   aria-controls="panel5" 
                   tabindex="0"  style="font-weight: bold;padding-bottom:5px;">Upload</label>
            <div id="tab-content5" 
                 class="tab-content"
                 role="tabpanel" 
                 aria-labelledby="upload" 
                 aria-hidden="true" style="margin-top:100px;">
                 <div class="col-md-12 mb-3">
                  <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                      <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                          <g fill="#3B454F" fill-rule="nonzero">
                              <path
                                  d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                              <path
                                  d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                              <path
                                  d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                          </g>
                      </svg>
                  
                      <h3>Drag and drop file here</h3>
                      <h3>or</h3>
                      <label for="fileDropRef" style="margin-left: 130px;">Browse for file</label>
                  </div>
                  <div class="files-list">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                      <div class="file-icon" style="width: 50px">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                          <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                          <g>
                            <path style="fill:#CEC9AE;"
                              d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                            <path style="fill:#CEC9AE;"
                              d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                          </g>
                          <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                        </svg>
                      </div>
                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <app-progress [progress]="file?.progress"></app-progress>
                      </div>
                  
                      <div class="delete" (click)="deleteFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                          <path fill="#B1B1B1" fill-rule="nonzero"
                            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </li> -->
      </ul>
      <!-- </div>  -->
    </div>
    <div class="card-footer">
	  <input type="hidden" id="idpozzo"/>
	  <button type="button" class="btn btn-danger float-right ml-1 mr-1 mb-2" (click)="closePopupWell()">
		Close
	  </button>
    <!-- <button *ngIf="latWGS84" type="button" class="btn btn-primary float-right mb-2" (click)="maplocation(idpos_param, idnazione, titleWell, coordinates)">Map location</button>
	  <button *ngIf="latPHWGS84Grad" type="button" class="btn btn-primary float-right mb-2" (click)="maplocation(idpos_param, idnazione, titleWell, coordinates)">Map location</button> -->
    <button type="button" class="btn btn-primary float-right mb-2" (click)="maplocation(idpos_param, idnazione, titleWell, coordinates)">Map location</button>
    </div>
  </div>
</div>
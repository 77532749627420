import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Clienti } from 'src/app/shared/clienti';
import { Common } from 'src/app/shared/common';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-anagrafica-piattaforme',
  templateUrl: './anagrafica-piattaforme.component.html',
  styleUrls: ['./anagrafica-piattaforme.component.css']
})
export class AnagraficaPiattaformeComponent implements OnInit {
  displayedColumns: string[] = ['denominazione', 'latitudine', 'longitudine', 'heading', 'latitudine_gradi', 'longitudine_gradi', 'tipo_piattaforma', 'lastupdate','stato', 'action'];
  dataSource: MatTableDataSource<Clienti>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  schema:string;
  action:string;
  Piattaforme: any = [];
  PiattaformaSel: any = [];
  Clienti: any = [];
  ListKml: any = [];
  keysearch: string;
  status:string;
  destitle:string;
  statocrud:string;
  isVisibleTableSearch: boolean;
  typeSearch:string;
  idpiattaforma:string;
  denominazione:string;
  latitudine:string;
  longitudine:string;
  headings:string;
  attivo:string;
  idcliente:string;
  latitudine_gradi:string;
  longitudine_gradi:string;
  tipo_piattaforma:string;
  image_rig:string;
  lastupdate:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  showList:boolean;
  token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  selectedItem: any = '';
  inputChanged: any = '';
  common: Common;
  share_message;
  serverrdp:string;
  userrdp:string;
  passwordrdp:string;
  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  progress: number;
  message: string;
  imageSrc:any = '';
  statusfile:boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  public AUTH_API = 'https://rcs.explorermarinesboss.com/api/';
  public TOKEN = 'dkfjsw3xHRWxmfsfe3QDDqZw429013';  
  form: FormGroup;
  uploadResponse;
  showupload:boolean = false;
  showuploadnew:boolean = false;
  interval = null;
  monitorstate:boolean = false;
  monitordesc:string;

  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private sharedService: SharedService
  ) { 

    this.common = new Common(restApi);

  }

  setMessage(href,page) {
    this.share_message.page_href = href;
    this.share_message.page_title = page;
    this.sharedService.sharedMessage = this.share_message;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {

    this.monitorstate = false;
    this.monitordesc = "Monitoring disabled";

    this.form = this.formBuilder.group({
      avatar: ['']
    });
    this.root_href = "/";
    this.root_title = "Dashboard";
    this.share_message = this.sharedService.sharedMessage;
    this.setMessage('piattaforme', 'Gestione Piattaforme');
    //console.log('init');
    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }

    if (sessionStorage.getItem("authorize") === null) {
          //console.log('auth null');
          this.router.navigate(['/login']);
        }else{
          var auth = sessionStorage.getItem("authorize");
          //console.log('auth = ' + auth);
          if(auth == "0000" || auth == "9999"){
            this.router.navigate(['/login']);
          }
        }
        this.showList = true;
        this.status = "master";
        this.destitle = "Nuova piattaforma";
        this.loadPiattaforme();
        //this.startTimer();
        this.keysearch = "";
        this.isVisibleTableSearch = false;
        //tipologia di like per la ricerca citta
        //--> l : like %keyword (left)
        //--> r : like keyword% (right)
        //--> a : like %keyword% (all)
        this.typeSearch = "r"; 
        this.loadClienti();
  }

  loadPiattaforme() {
    this.keysearch = "";
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    return this.restApi.getPiattaformeList(token).subscribe((data: {}) => {
      this.Piattaforme = data;
      var count = this.Piattaforme.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.Piattaforme);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  startTimer() {
    this.stopTimer();
    this.monitorstate = true;
    this.monitordesc = "Monitoring enabled";
    this.interval = setInterval(() => {
      //console.log('repeat interval.');
      this.Piattaforme = [];
      this.loadPiattaforme();
    }, 3000);
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      //console.log('stop repeat interval.');
      this.monitorstate = false;
      this.monitordesc = "Monitoring disabled";
    }
  }

  piattaformaLoad(id){
    this.action = "upd";
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    return this.restApi.getPiattaforma(id, token).subscribe((data: {}) => {
      this.PiattaformaSel = data;
      this.idpiattaforma =  this.PiattaformaSel[0].idpiattaforma;
      this.denominazione = this.PiattaformaSel[0].denominazione;
      this.latitudine = this.PiattaformaSel[0].latitudine;
      this.longitudine = this.PiattaformaSel[0].longitudine;
      this.headings = this.PiattaformaSel[0].heading;
      this.attivo = this.PiattaformaSel[0].attivo;
      this.idcliente = this.PiattaformaSel[0].idcliente;
      this.latitudine_gradi = this.PiattaformaSel[0].latitudine_gradi;
      this.longitudine_gradi = this.PiattaformaSel[0].longitudine_gradi;
      this.tipo_piattaforma = this.PiattaformaSel[0].tipo_piattaforma;
      this.lastupdate = this.PiattaformaSel[0].lastupdate;
      this.serverrdp = this.PiattaformaSel[0].serverrdp;
      this.userrdp = this.PiattaformaSel[0].serverrdpusr;
      this.passwordrdp = this.PiattaformaSel[0].serverrdppwd;
      this.image_rig = this.PiattaformaSel[0].path_image;
      this.status = "detail";
      this.showupload = false;
      this.showuploadnew = true;
    });

  }

  showUploadForm(){
    this.showupload = true;
    this.showuploadnew = true;
  }

  savePiattaforma(){
    var piattaforma: any = [];
    var scadenza: any = [];
 
   if(this.action == "new"){
    piattaforma = {
      "denominazione": this.denominazione,
      "latitudine": this.latitudine,
      "longitudine": this.longitudine,
      "heading": this.headings,
      "attivo": this.attivo,
      "idcliente": this.idcliente,
      "latitudine_gradi": this.latitudine_gradi,
      "longitudine_gradi": this.longitudine_gradi,
      "tipo_piattaforma": this.tipo_piattaforma,
      "lastupdate": this.lastupdate,
      "serverrdp": this.serverrdp,
      "serverrdpusr": this.userrdp,
      "serverrdppwd": this.passwordrdp,
      "path_image": this.image_rig
    };

    this.restApi.createPiattaforma(piattaforma, this.token).subscribe((data: {}) => {});
    var message = "Salvataggio avvenuto con successo.";
    var action = "ok"
    this.openSnackBar(message, action);
  }else{
    piattaforma = {
      "denominazione": this.denominazione,
      "latitudine": this.latitudine,
      "longitudine": this.longitudine,
      "heading": this.headings,
      "attivo": this.attivo,
      "idcliente": this.idcliente,
      "latitudine_gradi": this.latitudine_gradi,
      "longitudine_gradi": this.longitudine_gradi,
      "tipo_piattaforma": this.tipo_piattaforma,
      "lastupdate": this.lastupdate,
      "serverrdp": this.serverrdp,
      "serverrdpusr": this.userrdp,
      "serverrdppwd": this.passwordrdp,
      "idpiattaforma": this.idpiattaforma      
    };

    this.restApi.updatePiattaforma(piattaforma, this.token).subscribe((data: {}) => {});
    var message = "Salvataggio avvenuto con successo.";
    var action = "ok"
    this.openSnackBar(message, action);
  }
  }

  searchItemPiattaforme(){
    if(this.keysearch != ""){
      return this.restApi.getRicercaPiattaforme(this.keysearch, this.token).subscribe((data: {}) => {
        this.Piattaforme = data;
        this.dataSource = new MatTableDataSource(this.Piattaforme);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.changeDetectorRefs.detectChanges();
      });
    }else{
      var message = "Nessuna keyword di ricerca inserita. Riprovare."
      var action = "ok"
      this.openSnackBar(message, action);
    }
  }

  loadMaster(){
    this.reset();
    this.loadPiattaforme();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.reset();
    this.status = "detail";
    this.action = "new";
    console.log("detail - statuscrud = " + this.statocrud);
  }

  loadClienti() {
    return this.restApi.getClienti(this.token).subscribe((data: {}) => {
      this.Clienti = data;
    });
  }

  newPiattaforma(){
    this.status = "detail";
    this.action = "new";
    this.idpiattaforma = "";
    this.denominazione = "";
    this.latitudine = "";
    this.longitudine = "";
    this.headings = "";
    this.attivo = "";
    this.idcliente = "";
    this.latitudine_gradi = "";
    this.longitudine_gradi = "";
    this.tipo_piattaforma = "";
    this.lastupdate = "";
    this.serverrdp = "";
    this.userrdp = "";
    this.passwordrdp = "";
    this.showupload = false;
    this.showuploadnew = false;
  }

  reset(){
    this.idpiattaforma = "";
    this.denominazione = "";
    this.latitudine = "";
    this.longitudine = "";
    this.headings = "";
    this.attivo = "";
    this.idcliente = "";
    this.latitudine_gradi = "";
    this.longitudine_gradi = "";
    this.tipo_piattaforma = "";
    this.lastupdate = "";
    this.serverrdp = "";
    this.userrdp = "";
    this.passwordrdp = "";
  }

  openModalDelete(content, idpiattaforma) {
    this.idpiattaforma = idpiattaforma;
    this.titleModal = "Elimina Piattaforma";
    this.bodyModal = "Vuoi eliminare la piattaforma selezionata?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deletePiattaforma(id){
    var piattaforma = {
      idpiattaforma: id,
      token: this.token
    }; 
    this.restApi.deletePiattaforma(piattaforma).subscribe((data: {}) => {
      this.loadPiattaforme();
      var message = "Piattaforma eliminata con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // uploadFile = (files, content) => {
  //   if (files.length === 0) {
  //     return;
  //   }
  //   let fileToUpload = <File>files[0];
  //   const formData = new FormData();
  //   formData.append('file', fileToUpload, fileToUpload.name);


  //   this.http.post('http://localhost/phpapi/imageupload.php', {'image':this.imageSrc})
  //     .subscribe(res => {
  //       console.log(res);
  //       alert('Uploaded Successfully.');
  //     });

  //   this.http.post(this.AUTH_API + 'piattaforme/imageupload.php', formData, {reportProgress: true, observe: 'events'})
  //     .subscribe({
  //       next: (event) => {
  //       if (event.type === HttpEventType.UploadProgress)
  //         this.progress = Math.round(100 * event.loaded / event.total);
  //       else if (event.type === HttpEventType.Response) {
  //         this.message = 'Upload success.';
  //         this.onUploadFinished.emit(event.body);

  //       }
  //     },
  //     error: (err: HttpErrorResponse) => console.log(err)
  //   });
  // }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);

    this.restApi.uploadFile(this.idpiattaforma, formData).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
          this.piattaformaLoad(this.idpiattaforma);
      },
      (err) => {  
        console.log(err);
        this.piattaformaLoad(this.idpiattaforma);
      }
    );
  }
}

import { ChangeDetectorRef, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEventDescription } from 'src/app/shared/maineventdescriptions';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-main-events',
  templateUrl: './main-events.component.html',
  styleUrls: ['./main-events.component.css']
})
export class MainEventsComponent implements OnInit {
  displayedColumns: string[] = ['data', 'descrizione', 'rig', 'pozzo', 'action'];
  dataSource: MatTableDataSource<MainEventDescription>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  idmainevent:string;
  idmodal:string;
  status:string;
  showList:boolean;
  MainEventList: any = [];
  MainEvent: any = [];
  PiattaformeList: any = [];
  PozziList: any = [];
  MEDList: any = [];
  keysearch: string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  share_message;

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";

  iddescription: string;
  description: string;
  data: string;
  idposizionamento: string;
  idpiattaforma: string;
  approved:boolean;

  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.status = "master";
    this.showList = true;
    this.destitle = "Nuova anagrafica";
    this.loadMainEvents();
    this.getRIG();
    this.loadPozzi();
    this.loadMED();
  }

  loadMainEvents(){
    this.keysearch = "";

    return this.restApi.getMainEventList(this.token).subscribe((data: {}) => {
      this.MainEventList = data;
      var count = this.MainEventList.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.MainEventList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  getRIG(){
    this.restApi.getPiattaformeList(this.token).subscribe((data: {}) => {
      this.PiattaformeList = data;
    });
  }

  loadPozzi(){
    this.keysearch = "";

    return this.restApi.getPosizionamenti(this.token).subscribe((data: {}) => {
      this.PozziList = data;
    })
  }

  loadMED(){
    this.keysearch = "";

    return this.restApi.getMainEventDescriptionList(this.token).subscribe((data: {}) => {
      this.MEDList = data;
    })
  }

  onMEDSelect(event){
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    console.log(selectElementText);  
    this.description = selectElementText;
  }

  loadMaster(){
    this.loadMainEvents();
    this.status = "master";
    this.showList = true;
    console.log("master");
  }

  loadDetail(){
    this.status = "detail";
    console.log("detail");
  }

  loadMainEvent(idmainevent){
    return this.restApi.getMainEventSingle(idmainevent,this.token).subscribe((data: {}) => {
      this.MainEvent = data;
      var count = this.MainEvent.length;
      if(count > 0){
        this.status = "detail";
        this.action = "upd";
        this.showList = false;
        this.idmainevent = this.MainEvent[0].idmainevent;
        this.iddescription = this.MainEvent[0].iddescription;
        this.description = this.MainEvent[0].description;
        this.data = this.MainEvent[0].data;
        this.idposizionamento = this.MainEvent[0].idposizionamento;
        this.idpiattaforma = this.MainEvent[0].idpiattaforma;
        this.approved = this.MainEvent[0].approved;
      }
    });
  }

  newMainEvent(){
    this.status = "detail";
    this.action = "new";
    // this.nome = "";
    // this.cognome = "";
    // this.username = "";
    // this.email = "";
    // this.password = "";
    // this.confirmpassword = "";
    // this.ruolo = "";
    // this.attivo = "";
    // this.idutente = "";
  }

  deleteMainEvent(){
    var mainevent = {
      idmainevent: this.idmainevent,
      token: this.token
    }; 

    this.restApi.deleteMainEvent(mainevent).subscribe((data: {}) => {
      this.loadMainEvents();
      var message = "Main event eliminato con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }


  saveMainEvent(){
    var mainevent: any = [];

      if(this.action == "new"){
        //insert
        mainevent = {
          "iddescription": this.iddescription,
          "description": this.description,
          "data": this.data,
          "idposizionamento": this.idposizionamento, 
          "idpiattaforma": this.idpiattaforma,
          "approved": this.approved,
          "token": this.token
        };
      
        this.restApi.createMainEvent(mainevent).subscribe((data: {}) => {
          var message = "Salvataggio main event avvenuto con successo.";
          var action = "ok"
          this.openSnackBar(message, action);
        });

      }else{
        //update
        mainevent = {
          "iddescription": this.iddescription,
          "description": this.description,
          "data": this.data,
          "idposizionamento": this.idposizionamento, 
          "idpiattaforma": this.idpiattaforma,
          "approved": this.approved,
          "idmainevent": this.idmainevent,
          "token": this.token
        };
      
        this.restApi.updateMainEvent(mainevent).subscribe((data: {}) => {
          var message = "Aggiornamento main event avvenuto con successo.";
          var action = "ok"
          this.openSnackBar(message, action);
        });
      }
  }

  openModalDelete(content, idmainevent) {
    this.idmainevent = idmainevent;
    this.idmodal = "normal";
    this.titleModal = "Elimina MainEvent";
    this.bodyModal = "Vuoi eliminare il main event selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalUpdate(content) {
    this.idmainevent = this.idmainevent;
    this.idmodal = "normal";
    this.titleModal = "Modifica MainEvent";
    this.bodyModal = "Vuoi salvare le modifiche al main event selezionato?";
    this.buttonAction = "Modifica";
    this.actionOp = "2";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  authorize(idmainevent,state){
    this.restApi.getApproveMessage(idmainevent,state).subscribe((data: {}) => {
      this.loadMainEvents();
      var message = "Aggiornamento main event avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
     <div class="form-inline" *ngIf="status=='master'">
        <label class="sr-only" for="inlineFormInputName2">Name</label>
        <input type="text" class="form-control form-control-sm mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ricerca Clienti" [(ngModel)]="keysearch">
      
        <button type="button" class="btn btn-primary btn-sm mb-2 mr-1" (click)="searchItemClienti()">Cerca</button>
        <button type="button" class="btn btn-warning btn-sm mb-2" (click)="loadClienti()">Reset</button>
        <button type="button" class="btn btn-success btn-sm ml-2 mb-2" (click)="loadDetail()"><fa-icon class="mr-2" [icon]="faPlus"></fa-icon> Nuovo Cliente</button>
        <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
      </div>
  
      <div class="row" *ngIf="status=='detail'">
        <div class="col-md-8 mt-2">
          <div class="form-inline">
            <div class="form-group">
              <h5 class="card-title"><strong>{{destitle}}</strong></h5>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
          <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Elenco Clienti</button>
          <button class="btn btn-primary active pull-right ml-2" (click)="saveCliente()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Salva</button>
          <button class="btn btn-primary active pull-right" (click)="newCliente()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo</button>
        </div>
      </div>
  
    </div>
  
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-4 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Ragione sociale</span></div>
            <input type="text" class="form-control" id="ragionesociale" name="ragionesociale" [(ngModel)]="ragionesociale"/>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Indirizzo</span></div>
            <input type="text" class="form-control" id="indirizzo" name="indirizzo" [(ngModel)]="indirizzo"/>
          </div>
        </div>
        <div class="col-md-4 mt-2">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text primary">Città</span></div>
              <input type="text" class="form-control" id="idcitta" name="citta" [(ngModel)]="citta" (window:keydown.enter)="onKeydownEvent($event, content)"/>
            </div>
        </div>
    </div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
    <div class="col-md-4 mt-2">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend primary"><span class="input-group-text primary">Provincia</span></div>
        <input type="text" class="form-control" id="provincia" name="provincia" [(ngModel)]="provincia" />
      </div>
    </div>
    <div class="col-md-4 mt-2">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend warning"><span class="input-group-text primary">CAP</span></div>
        <input type="text" class="form-control" id="cap" name="cap" [(ngModel)]="cap"/>
      </div>
    </div>
    <div class="col-md-4 mt-2">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend warning"><span class="input-group-text primary">Partita Iva</span></div>
        <input type="text" class="form-control" id="partitaiva" name="partitaiva" [(ngModel)]="partitaiva"/>
      </div>
    </div>
    </div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend primary"><span class="input-group-text primary">Telefono</span></div>
          <input type="text" class="form-control" id="telefono" name="telefono" [(ngModel)]="telefono" />
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend warning"><span class="input-group-text primary">Email</span></div>
          <input type="text" class="form-control" id="email" name="email" [(ngModel)]="email"/>
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend warning"><span class="input-group-text primary">Attivo</span></div>
          <select name="attivo" id="attivo" placeholder="cliente" [(ngModel)]="attivo" style="margin:0rem;">
            <option value="">select...</option>
            <option value="1">Attivo</option>
            <option value="0">Non Attivo</option>
          </select>
        </div>
      </div>
    </div>
      <div *ngIf="isVisibleTableSearch === true" style="overflow-y: scroll; overflow-x: hidden; height: 200px; margin-left:18px;padding-left:-5px; padding-right:16px; border:2px solid #999">
        <table class="table table-sm table-bordered table-hover ml-3">
          <thead>
            <tr>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Codice</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;" colspan="3">Città</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Provincia</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">CAP</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Regione</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of Locations; let i = index">
              <th style="font-size:8pt;font-weight:normal;">{{item.idcitta}}</th>
              <td style="font-size:8pt;font-weight:normal;" colspan="3">{{item.comune}}</td>
              <td style="font-size:8pt;font-weight:normal;" >{{item.provincia}}</td>
              <td style="font-size:8pt;font-weight:normal;">{{item.cap}}</td>
              <th style="font-size:8pt;font-weight:normal;">{{item.regione}}</th>
              <td style="font-size:8pt;font-weight:normal;">
                <button class="btn btn-outline-success btn-sm pull-right" style="margin:3px;" (click)="selectLocationItem(item.idcitta)">
                  <i class="fa fa-fw" aria-hidden="true" title="seleziona città"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
        <h4 class="alert-heading">Elenco clienti vuoto</h4>
        <p>Nessun cliente presente. Creare un nuovo cliente e riporvare.</p>
      </div>  
     <div class="mat-elevation-z0" *ngIf="status=='master' && showList == true">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="ragionesociale">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ragione Sociale </th>
          <td mat-cell *matCellDef="let row"> {{row.ragionesociale}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="indirizzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Indirizzo </th>
          <td mat-cell *matCellDef="let row"> {{row.indirizzo}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="citta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Città </th>
          <td mat-cell *matCellDef="let row"> {{row.citta}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="provincia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prov. </th>
            <td mat-cell *matCellDef="let row"> {{row.provincia}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="cap">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CAP </th>
            <td mat-cell *matCellDef="let row"> {{row.cap}} </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="partitaiva">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partita Iva </th>
            <td mat-cell *matCellDef="let row"> {{row.partitaiva}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Telefono </th>
            <td mat-cell *matCellDef="let row"> {{row.telefono}} </td>
        </ng-container>        

        <!-- Name Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let row"> {{row.mail}} </td>
        </ng-container>

        <!-- Name Column -->
        <!-- <ng-container matColumnDef="attivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Attivo </th>
          <td mat-cell *matCellDef="let row"> {{row.statocliente}} </td>
        </ng-container> -->
        <ng-container matColumnDef="attivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Attivo </th>
          <td mat-cell *matCellDef="let row"> 
              <span class="badge bg-danger p-1" *ngIf="row.attivo == '0'" style="color:#fff;">Non attivo</span>
              <span class="badge bg-success p-1" *ngIf="row.attivo == '1'"  style="color:#fff; font-weight:bolder;">Attivo</span>
          </td>
      </ng-container>
        
        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Action </th>
          <td mat-cell *matCellDef="let row" class="text-right"> 
            <div role="group" class="btn-group-sm btn-group">
              <button class="btn-shadow btn btn-success btn-sm mr-1 mt-1 mb-1" (click)="clienteLoad(row.idcliente)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica cliente"></i></button>
              <button class="btn-shadow btn btn-danger btn-sm mt-1 mb-1" (click)="openModalDelete(content, row.idcliente)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella cliente"></i></button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="d-block p-4 text-center card-footer">

    </div>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{bodyModal}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      <button type="button" class="btn btn-primary" (click)="deleteCliente(idcliente); c('Close click')">{{buttonAction}}</button>
    </div>
  </ng-template>
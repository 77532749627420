import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  idutente:string;
  nome:string;
  cognome:string;
  username:string;
  email:string;
  current_password:string;
  password:string;
  confirmpassword:string;
  ruolo:string;
  attivo:string;
  status:string;

  Utenti: any = [];
  UtenteSel: any = [];
  schema:string;
  share_message;

  constructor(public restApi: RestApiService,
    private sharedService: SharedService) { }

  setMessage(href,page) {
    this.share_message.page_href = href;
    this.share_message.page_title = page;
    this.sharedService.sharedMessage = this.share_message;
  }

  ngOnInit(): void {
    this.share_message = this.sharedService.sharedMessage;
    this.setMessage('profilo-utente', 'Profilo Utente');

    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }
    
    var id = sessionStorage.getItem("authorize");
    this.utenteLoad(id);
  }

  utenteLoad(id){
    this.restApi.getUtente(id, this.schema).subscribe((data: {}) => {
      this.UtenteSel = data;
      this.idutente =  this.UtenteSel[0].idutente;
      this.nome = this.UtenteSel[0].nome;
      this.cognome = this.UtenteSel[0].cognome;
      this.username = this.UtenteSel[0].username;
      this.email = this.UtenteSel[0].email;
      this.current_password = this.UtenteSel[0].password;
      //console.log('current_password = ' + this.current_password);
      this.ruolo = this.UtenteSel[0].auth_level;
      this.attivo = this.UtenteSel[0].attivo;
    });
  }

}

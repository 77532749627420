<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
          <div class="d-inline-block" ngbDropdown placement="bottom-right">
            <button type="button" ngbDropdownToggle class="mb-0 mr-2 pt-0 pb-0 pr-4 border-0 btn-transition btn btn-outline-primary">Benvenuto <strong>{{user}}</strong></button>
            <div ngbDropdownMenu>
              <button class="dropdown-item" (click)="getProfile()">Profilo</button>
              <button class="dropdown-item" (click)="logout()">Logout</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="infogeosbossnavyweb()">Info geosbossnavyweb 1.0</button>
              <a href="/geosbossnavyweb/assets/images/guida.pdf" target="_blank" class="dropdown-item">Guida in linea geosbossnavyweb 1.0</a>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>


import { Component, OnInit, ElementRef } from '@angular/core';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
(window as any).html2canvas = html2canvas;
import PDFObject from 'pdfobject';
import { PrintAcquisti } from 'src/app/shared/print_acquisti';
import { PrintPreventivi } from 'src/app/shared/print_preventivi';
import { PrintDDT } from 'src/app/shared/print_ddt';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.css']
})
export class ReportViewerComponent implements OnInit {
  idordine:string;
  tipologia:string;

  //#region variables
  activeDoc: string;
  activeControl:string;
  activeddt:boolean;
  activefda:boolean;
  activeSplit:string;
  statoOrd:string;
  statoDDT:string;
  statoFDA:string;
  descrUpdateForm:string;
  test: 'false';
  idacquisto:string;
  idacquistodet:string;
  idordinedet:string;
  isTableExpanded = false;
  keysearch:string;
  codicearticolo:string;
  descrizione:string;
  descrizione_base:string;
  idum:string;
  quantita:string;
  nrordine:string;
  dataordine:string;
  idfornitore:string;
  showtable:string;
  Fornitori: any = [];
  StatiORD: any = [];
  StatiDDT: any = [];
  StatiFDA: any = [];
  Pagamenti: any = [];
  Causali: any = [];
  Ordine: any = [];
  DettaglioOrdine: any = [];
  DettaglioRowSplit: any = [];
  TotaliOrdine: any = [];
  Preventivo: any = [];
  DettaglioPreventivo: any = [];
  TotaliPreventivo: any = [];
  DDT: any = [];
  DettaglioDDT: any = [];
  TotaliDDT: any = [];
  RicercaArticoli: any = [];
  NewDettaglioOrdine: any = [];
  ValueOrdine: any = [];
  LastOrdine: any = [];
  InfoAzienda: any = [];
  parameter:string;
  progordine:string;
  progddt:string;
  progfda:string;
  idpagamento:string;
  idstatoord:string;
  idstatoddt:string;
  idstatofda:string;
  numeroddt:string;
  dataddt:string;
  idtrasporto:string;
  datainiziotrasporto:string;
  orainiziotrasporto:string;
  aspettoesteriorebeni:string;
  nrcolli:string;
  idcausale:string;
  numerofattura:string;
  datafattura:string;
  ivaordine:string;
  ivaddt:string;
  ivafattura:string;
  isVisibleDettaglio: boolean;
  isVisibleSearch: boolean;
  isVisibleTableSearch: boolean;
  isVisibleTableSplit:boolean;
  isInfoSupplier:boolean;
  isReportView:boolean;
  /* FIELDS SEARCH FORM */
  crud_oper:string;
  acq_checked:boolean;
  acq_keysearch: string;
  acq_idumacq: string;
  acq_idumven: string;
  acq_qtaacq: string;
  acq_qtaven: string;
  acq_base: string;
  acq_altezza: string;
  acq_spessore: string;
  acq_spessore_anima: string;
  acq_spessore_ala:string;
  acq_diametro_pollici: string;
  acq_diametro_mm: string;
  acq_maglia: string;
  acq_filo: string;
  acq_piatto: string;
  acq_pracquisto: string;
  acq_prvendita: string;
  acq_sconto: string;
  acq_imponibile: string;
  acq_imposta: string;
  acq_totale: string;
  acq_iva: string;
  
  ord_checked:boolean;
  ord_insertrow:boolean;
  ord_checked_text:string;
  ord_keysearch: string;
  ord_descrizione: string;
  ord_idum: string;
  ord_qta: string;
  ord_base:string;
  ord_altezza:string;
  ord_spessore:string;
  ord_prezzo: string;
  ord_sconto: string;
  ord_totale: string;
  ord_iva: string;
  ord_idprofilo: string;
  showTableProntuarioModal: boolean;
  schema:string;
  /* END FIELDS SEARCH FORM*/

  //#endregion


  constructor(
    private activatedRoute: ActivatedRoute,
    public restApi: RestApiService,
    private router:Router,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    //console.log('init');
    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }

    if (sessionStorage.getItem("authorize") === null) {
      //console.log('auth null');
      this.router.navigate(['/login']);
    }else{
      var auth = sessionStorage.getItem("authorize");
      //console.log('auth = ' + auth);
      if(auth == "0000" || auth == "9999"){
        this.router.navigate(['/login']);
      }
      var user = sessionStorage.getItem("username");
      if(user == "totem"){
        this.router.navigate(['/totem']);
      }
    }
    
    this.activatedRoute.params.subscribe(params => {
      const id = params['id2'];
      this.idordine = id;
      console.log('id = ' + id);
      const tip = params['id1'];
      this.tipologia = tip;
      console.log('tip = ' + tip);
     
      switch(tip){
        case 'ord':
        case 'ddt':
        case 'fda':
          var pdford = new PrintAcquisti;
          // this.restApi.getAcquisto(id, this.schema).subscribe((data: {}) => {
          //   this.Ordine = data;
          //   this.restApi.getDettaglioAcquisto(id, this.schema).subscribe((data: {}) => {
          //     this.DettaglioOrdine = data;
          //     this.loadInfoAzienda();
          //     return this.restApi.getTotaliAcquisto(id, this.schema).subscribe((data: {}) => {
          //       this.TotaliOrdine = data;
          //       PDFObject.embed(pdford.generate_acquisto(this.Ordine,this.DettaglioOrdine, this.TotaliOrdine, this.InfoAzienda, tip), "#report");
          //     });    
          //   });
          // });

        case 'ven':
          break; 
        case 'pre':
          var pdfpre = new PrintPreventivi;
          // this.restApi.getPreventivo(id, this.schema).subscribe((data: {}) => {
          //   this.Preventivo = data;
          //   this.restApi.getDettaglioPreventivo(id, this.schema).subscribe((data: {}) => {
          //     this.DettaglioPreventivo = data;
          //     this.loadInfoAzienda();
          //     return this.restApi.getTotaliPreventivo(id, this.schema).subscribe((data: {}) => {
          //       this.TotaliPreventivo = data;
          //       PDFObject.embed(pdfpre.generate_acquisto(this.Preventivo,this.DettaglioPreventivo, this.TotaliPreventivo, this.InfoAzienda), "#report");
          //     });    
          //   });
          // });
          break;   
        case 'ddt-document':
          // var pdfddt = new PrintDDT;
          // this.restApi.getDdtSingle(id, this.schema).subscribe((data: {}) => {
          //   this.DDT = data;
          //   this.restApi.getDettaglioDdt(id, this.schema).subscribe((data: {}) => {
          //     this.DettaglioDDT = data;
          //     this.loadInfoAzienda();
          //     return this.restApi.getTotaliDdt(id, this.schema).subscribe((data: {}) => {
          //       this.TotaliDDT = data;
          //       PDFObject.embed(pdfddt.generate_ddt(this.DDT,this.DettaglioDDT, this.TotaliDDT, this.InfoAzienda), "#report");
          //     });    
          //   });
          // });          
          break;    
      }

    });
  }

  loadOrdine(id) {
    // return this.restApi.getAcquisto(id, this.schema).subscribe((data: {}) => {
    //   this.Ordine = data;
    //   this.nrordine = this.Ordine[0].progordine; 
    //   this.dataordine = this.Ordine[0].dataordine;  
    //   this.idfornitore = this.Ordine[0].idfornitore;
    //   this.idpagamento = this.Ordine[0].idpagamento;  
    //   this.idstatoord = this.Ordine[0].idstato;
    //   this.progddt = this.Ordine[0].progddt;
    //   this.numeroddt = this.Ordine[0].numeroddt;
    //   this.dataddt = this.Ordine[0].dataddt;
    //   this.idtrasporto = this.Ordine[0].idtrasporto;
    //   this.datainiziotrasporto = this.Ordine[0].datainiziotrasporto;
    //   this.orainiziotrasporto = this.Ordine[0].orainiziotrasporto;
    //   this.aspettoesteriorebeni = this.Ordine[0].aspettoesteriorebeni;
    //   this.nrcolli = this.Ordine[0].nrcolli;
    //   this.idcausale = this.Ordine[0].idcausaletrasporto;
    //   this.progfda = this.Ordine[0].progfda;
    //   this.numerofattura = this.Ordine[0].numerofattura;
    //   this.datafattura = this.Ordine[0].datafattura;
    //   console.log('vendita = ' +JSON.stringify(this.Ordine));
    // });
  }

  loadDettaglioOrdine(id) {
    // return this.restApi.getDettaglioAcquisto(id, this.schema).subscribe((data: {}) => {
    //   this.DettaglioOrdine = data;
    // });
  }

  loadTotaliOrdine(id) {
    // return this.restApi.getTotaliAcquisto(id, this.schema).subscribe((data: {}) => {
    //   this.TotaliOrdine = data;
    // });
  }

  loadInfoAzienda(){
      //infoazienda
      if (localStorage.getItem("InfoAzienda") === null) {
        this.restApi.getSettingAzienda('1', this.schema).subscribe((data: {}) => {
          localStorage.setItem("InfoAzienda", JSON.stringify(data));
          console.log("InfoAzienda = " + JSON.stringify(data));
          var info = localStorage.getItem("InfoAzienda");
          this.InfoAzienda = JSON.parse(info);
        })
      }else{
        var info = localStorage.getItem("InfoAzienda");
        this.InfoAzienda = JSON.parse(info);
        console.log('InfoAzienda Load = ' + this.InfoAzienda);
      }
  }

}

import {Component, OnInit} from '@angular/core';
import { ThemeOptions } from 'src/assets/theme-options';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styles:[
    `.header-btn-lg .dropdown-toggle::after {
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 8px;
  }`
  ]
})
export class UserBoxComponent implements OnInit {
  user: string = "";
  dbname: string = "";
  
  constructor(
    private router:Router,
    public globals: ThemeOptions) {
  }

  ngOnInit() {
    if (sessionStorage.getItem("user") !== null) {
        console.log('user logged');
        var sessionuser = sessionStorage.getItem("user");
        var dbname = sessionStorage.getItem("dbname");
        this.dbname = dbname;
        this.user = sessionuser;
        console.log('user = ' + this.user);
    }
  }

  logout(){
    sessionStorage.removeItem("authorize");
    this.router.navigate(['/login']);
    console.log("authorize 0");
  }

  getProfile(){
    this.router.navigate(['profilo-utente']);
  }

  infogeosbossnavyweb(){
    this.router.navigate(['info-geosbossnavyweb']);
  }

  helpgeosbossnavyweb(){
    this.router.navigate(['./src/assets/images']);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Utenti } from '../shared/utenti';
import { Citta } from '../shared/citta';
import { Settings } from './settings';
import { SettingsAzienda } from './settingsazienda';
import { Clienti } from './clienti';
import { Labels } from './labels';
import { AppConfig } from './appconfig';
import { FormArray } from '@angular/forms';
import { Album } from '../Pages/Elements/timeline/album.model';
import { Piattaforma } from './piattaforma';
import { PiattaformeUtente } from './piattaformeutente';
import { Kml } from './kml';
import { Posizionamento, Root } from './posizionamenti';
import { Attachments } from './attachments';
import { MainEventDescription } from './maineventdescriptions';
import { MainEvent } from './mainevent';
import { AnagraficaKml } from './anagraficakml';
import { Downloads } from './downloads';
import { Nations } from './nations';
import { PosizionamentiCoordinates } from './posizionamenti_coordinates';
import { PosizionamentiDatum } from './posizionamenti_datum';
import { PosizionamentiTipologie } from './posizionamenti_tipologie';
import { Coordinates } from './coordinates';
import { Engine } from './engine';
import { ShapeFiles } from './shapefiles';
import { Offsets } from './offsets';

const AUTH_API = 'https://rcs.explorermarinesboss.com/api/';
const TOKEN = 'dkfjsw3xHRWxmfsfe3QDDqZw429013';

@Injectable({
  providedIn: 'root'
})

export class RestApiService {
  
  // Define API
  apiURL = 'https://rcs.explorermarinesboss.com/api/';

  constructor(private http: HttpClient) { }

  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }  

//#region Platforms
        // HttpClient API get() method => Fetch magazzino single item
        getPiattaformeList(token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme/read.php?token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)
              }
            )
          )
        }
        // HttpClient API get() method => Fetch magazzino single item
        getPiattaforma(id,token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme/readsingle.php?id='+ id +'&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }
        // HttpClient API get() method => Fetch magazzino single item
        getPiattaforme(idcliente,token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme_utenti/readforuser.php?id=' + idcliente + '&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        } 
        getPiattaformeAttive(idcliente, stato, token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme_utenti/readforuserattiva.php?id=' + idcliente + '&attiva=' + stato + '&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        } 
        getPiattaformaUtente(idpiattaforma, token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme_utenti/readsinglepu.php?id=' + idpiattaforma + '&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        } 
        getPiattaformeSearch(idutente, keyword, token): Observable<Piattaforma> {
          return this.http.get<Piattaforma>(AUTH_API + 'piattaforme/searchplatform.php?idutente=' + idutente + '&keyword=' + keyword + '&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        } 
  // HttpClient API post() method => Create Piattaforma
  
  createPiattaforma(piattaforma,token): Observable<Piattaforma> {
    return this.http.post<Piattaforma>(this.apiURL + 'piattaforme/create.php?token=' + token, piattaforma, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update Piattaforma
  updatePiattaforma(piattaforma, token): Observable<Piattaforma> {
    return this.http.post<Piattaforma>(this.apiURL + 'piattaforme/update.php?token=' + token, piattaforma, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  updateSessionRDP(piattaforma): Observable<PiattaformeUtente> {
    return this.http.post<PiattaformeUtente>(this.apiURL + 'piattaforme_utenti/updatesessionrdpforuser.php', piattaforma, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deletePiattaforma(piattaforma): Observable<Piattaforma> {
    return this.http.post<Piattaforma>(this.apiURL + 'piattaforme/delete.php', piattaforma, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Search Piattaforma items
  getRicercaPiattaforme(keyword, token): Observable<Piattaforma> {
    return this.http.get<Piattaforma>(this.apiURL + 'piattaforme/search.php?keyword=' + keyword + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  
  deleteJoinPiattaforma(piattaforma): Observable<Piattaforma> {
    return this.http.post<Piattaforma>(this.apiURL + 'piattaforme_utenti/delete.php', piattaforma, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

    // HttpClient API post() method => Create Piattaforma
    createPiattaformaJoin(piattaforma): Observable<Piattaforma> {
      return this.http.post<Piattaforma>(this.apiURL + 'piattaforme_utenti/create.php', piattaforma, this.httpOptions)
      .pipe(
        catchError(
          err => {
            //console.log('Handling error locally and rethrowing it...', err);
            return throwError(err)}
        )
      )
    } 

  // HttpClient API post() method => Update Piattaforma
  updateJoinStatus(status, token): Observable<Piattaforma> {
    return this.http.post<Piattaforma>(this.apiURL + 'piattaforme_utenti/updatestateplatform.php?token=' + token, status, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  public uploadFile(idpiattaforma, data) {
    let uploadURL = `${this.apiURL}/piattaforme/imageupload.php?id=`+idpiattaforma;

    return this.http.post<any>(uploadURL, data);
  }
//#endregion

//#region Posizionamenti
getPosizionamentiList(idcliente): Observable<Root> {
  return this.http.get<Root>(AUTH_API + 'posizionamenti/readposizionamenti.php?idcliente=' + idcliente)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

getPosizionamentiSearch(idcliente, search, token): Observable<Root> {
  return this.http.get<Root>(AUTH_API + 'posizionamenti/searchposizionamentidata.php?idcliente=' + idcliente + '&keyword=' + search + '&token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

getPosizionamenti(token): Observable<Posizionamento> {
  return this.http.get<Posizionamento>(AUTH_API + 'posizionamenti/read.php?token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

getPosizionamento(idposizionamento, token): Observable<Posizionamento> {
  return this.http.get<Posizionamento>(AUTH_API + 'posizionamenti/readsingle.php?idposizionamento=' + idposizionamento+ '&token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

getAttachments(idposizionamento, token): Observable<Attachments> {
  return this.http.get<Attachments>(AUTH_API + 'attachments/readforpositioning.php?idposizionamento=' + idposizionamento+ '&token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}
getAttachmentsType(token): Observable<Attachments> {
  return this.http.get<Attachments>(AUTH_API + 'attachments/readallegatitipo.php?token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}
getMainEvent(idposizionamento, token): Observable<MainEvent> {
  return this.http.get<MainEvent>(AUTH_API + 'mainevents/readmaineventforpozzo.php?id=' + idposizionamento + '&token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

getMainEventPlatform(idplatform, token): Observable<MainEvent> {
  return this.http.get<MainEvent>(AUTH_API + 'mainevents/readallmaineventforplatform.php?id=' + idplatform + '&token=' + token)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

  // HttpClient API post() method => Create med
  createPosizionamento(pozzo): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'posizionamenti/create.php', pozzo, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updatePosizionamento(pozzo): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'posizionamenti/update.php', pozzo, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API post() method => Create med
  createAttach(attach): Observable<Attachments> {
    return this.http.post<Attachments>(this.apiURL + 'attachments/create.php', attach, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  updateAttach(attach): Observable<Attachments> {
    return this.http.post<Attachments>(this.apiURL + 'attachments/update.php', attach, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteAttach(attach): Observable<Attachments> {
    return this.http.post<Attachments>(this.apiURL + 'attachments/delete.php', attach, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API post() method => Update med
  updateActiveWell(upd): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'posizionamenti/activewell.php', upd, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  // HttpClient API post() method => Update med
  updateStateWell(upd): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'posizionamenti/statuswell.php', upd, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API post() method => Update med
  resetPlatform(upd): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'mainevents/resetplatform.php', upd, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deletePosizionamento(pozzo): Observable<Posizionamento> {
    return this.http.post<Posizionamento>(this.apiURL + 'posizionamenti/delete.php', pozzo, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  getNationsList(token): Observable<Nations> {
    return this.http.get<Nations>(AUTH_API + 'posizionamenti/readnations.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  public uploadFileWell(year, idpozzo, data) {
    let uploadURL = `${this.apiURL}posizionamenti/uploadfile.php?idpozzo=`+idpozzo+`&year=`+year;

    return this.http.post<any>(uploadURL, data);
  }

  public uploadKmlWell(data) {
    let uploadURL = `${this.apiURL}posizionamenti/uploadkml.php`;

    return this.http.post<any>(uploadURL, data);
  }

  getKmlTrasformationEngine(idpos, anno, codiceprogettoint, filename, token): Observable<Engine> {
    return this.http.get<Engine>(AUTH_API + 'engines/kmlengine.php?idpos='+ idpos + '&anno=' + anno + '&codiceprogettoint=' + codiceprogettoint + '&filename='+ filename +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }


//#endregion

//#region KML
  // HttpClient API get() method => Fetch magazzino single item
  getKml(iduser, ambiente, token): Observable<Kml> {
    return this.http.get<Kml>(AUTH_API + 'kml/readforuser.php?id='+ iduser + "&ambiente=" + ambiente + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API get() method => Fetch magazzino single item
  getSingleKml(id, token): Observable<Kml> {
    return this.http.get<Kml>(AUTH_API + 'kml/readsingle.php?id='+ id + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API get() method => Fetch magazzino single item
  getSingleKmlForUser(id, token): Observable<Kml> {
    return this.http.get<Kml>(AUTH_API + 'kml/readsingleforuser.php?id='+ id + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch magazzino single item
  getKmlForWell(id, token): Observable<Kml> {
    return this.http.get<Kml>(AUTH_API + 'kml/readkmlforwell.php?id='+ id + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API post() method => Update cliente
  updateStatusKmlUtente(utente): Observable<Kml> {
    return this.http.post<Kml>(this.apiURL + 'kml/updatestatekmluser.php', utente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Update cliente
  updateStatusKmlWell(well): Observable<Kml> {
    return this.http.post<Kml>(this.apiURL + 'kml/updatestatekmlwell.php', well, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

//#endregion

//#region Anagrafica KML
  // HttpClient API get() method => Fetch MainEventDescriptions all item
  getKmlList(token): Observable<AnagraficaKml> {
    return this.http.get<AnagraficaKml>(AUTH_API + 'anagrafica_kml/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch MainEventDescriptions single item
  getKmlSingle(id,token): Observable<AnagraficaKml> {
    return this.http.get<AnagraficaKml>(AUTH_API + 'anagrafica_kml/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API post() method => Create med
  createKml(kml,token): Observable<AnagraficaKml> {
    return this.http.post<AnagraficaKml>(this.apiURL + 'anagrafica_kml/create.php?token=' + token, kml, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateKml(kml,token): Observable<AnagraficaKml> {
    return this.http.post<AnagraficaKml>(this.apiURL + 'anagrafica_kml/update.php?token=' + token, kml, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteKml(kml,token): Observable<AnagraficaKml> {
    return this.http.post<AnagraficaKml>(this.apiURL + 'anagrafica_kml/delete.php?token=' + token, kml, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API post() method => Create med
  createKmz(kml): Observable<AnagraficaKml> {
    return this.http.post<AnagraficaKml>(this.apiURL + 'kml/createkmz.php', kml, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateKmz(kml): Observable<AnagraficaKml> {
    return this.http.post<AnagraficaKml>(this.apiURL + 'kml/updatekmz.php', kml, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
//#endregion

//#region ShapeFiles
  // HttpClient API get() method => Fetch MainEventDescriptions all item
  getShapeFiles(token): Observable<ShapeFiles> {
    return this.http.get<ShapeFiles>(AUTH_API + 'shapefiles/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch MainEventDescriptions single item
  getShapeFilesSingle(id,token): Observable<ShapeFiles> {
    return this.http.get<ShapeFiles>(AUTH_API + 'shapefiles/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Create med
  createShapeFile(shp,token): Observable<ShapeFiles> {
    return this.http.post<ShapeFiles>(this.apiURL + 'shapefiles/create.php?token=' + token, shp, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateShapeFile(shp,token): Observable<ShapeFiles> {
    return this.http.post<ShapeFiles>(this.apiURL + 'shapefiles/update.php?token=' + token, shp, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  deleteShapeFile(shp,token): Observable<ShapeFiles> {
    return this.http.post<ShapeFiles>(this.apiURL + 'shapefiles/delete.php?token=' + token, shp, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Fetch MainEventDescriptions single item
  getOffset(id,token): Observable<Offsets> {
    return this.http.get<Offsets>(AUTH_API + 'shapefiles_offsets/readoffsetforship.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

    // HttpClient API get() method => Fetch MainEventDescriptions single item
    getOffsetDefault(id,token): Observable<Offsets> {
      return this.http.get<Offsets>(AUTH_API + 'shapefiles_offsets/readoffsetforshipdefault.php?id='+ id +'&token=' + token)
      .pipe(
        catchError(
          err => {
            //console.log('Handling error locally and rethrowing it...', err);
            return throwError(err)}
        )
      )
    }
  
  //#endregion

//#region Utenti
  // HttpClient API get() method => Fetch magazzino list
  getUtenti(token): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch magazzino list
  getUtentiAttivi(token): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/readactive.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch magazzino single item
  getUtente(id, token): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/readsingle.php?id=' + id + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Fetch magazzino single item
  getUtentLast(token): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/readlast.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, this.httpOptions);
  }

  // HttpClient API get() method => Fetch magazzino single item
  getLogin(username, password): Observable<Utenti> {
    return this.http.get<Utenti>(AUTH_API + 'users/login.php?username=' + username + '&password=' + password + '&token=' + TOKEN)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Search utenti items
  getRicercaUtenti(keyword, token): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/search.php?keyword=' + keyword + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API post() method => Create cliente
  createUtente(utente,token): Observable<Utenti> {
    return this.http.post<Utenti>(this.apiURL + 'users/create.php?token=' + token, utente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update cliente
  updateUtente(utente, token): Observable<Utenti> {
    return this.http.post<Utenti>(this.apiURL + 'users/update.php?token=' + token, utente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }


  // HttpClient API post() method => Update cliente
  changePwd(newpass, token): Observable<Utenti> {
    return this.http.post<Utenti>(this.apiURL + 'users/changepassword.php?token=' + token, newpass, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  deleteUtente(utente, token): Observable<Utenti> {
    return this.http.post<Utenti>(this.apiURL + 'users/deletelogic.php?token=' + token, utente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Fetch magazzino single item
  getUtentiByAuth(id,schema): Observable<Utenti> {
    return this.http.get<Utenti>(this.apiURL + 'users/readuserbyauth.php?id=' + id + '&schema='+schema)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }  
  
//#endregion
 
//#region Clienti
  getClienti(token): Observable<Clienti> {
    return this.http.get<Clienti>(this.apiURL + 'clienti/read.php?token=' + token)
    .pipe(
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch magazzino single item
  getCliente(id,token): Observable<Clienti> {
    return this.http.get<Clienti>(this.apiURL + 'clienti/readsingle.php?id=' + id + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API get() method => Search clienti items
  getRicercaClienti(keyword,token): Observable<Clienti> {
    return this.http.get<Clienti>(this.apiURL + 'clienti/search.php?keyword=' + keyword  + '&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  // HttpClient API post() method => Create cliente
  createCliente(cliente,token): Observable<Clienti> {
    return this.http.post<Clienti>(this.apiURL + 'clienti/create.php?token=' + token, cliente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update cliente
  updateCliente(cliente,token): Observable<Clienti> {
    return this.http.post<Clienti>(this.apiURL + 'clienti/update.php?token=' + token, cliente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteCliente(cliente,token): Observable<Clienti> {
    return this.http.post<Clienti>(this.apiURL + 'clienti/delete.php?token=' + token, cliente, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

//#endregion

//#region Citta
getCities(schema): Observable<Citta> {
  return this.http.get<Citta>(this.apiURL + 'anagrafica_citta/read.php?schema=' + schema)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}

// HttpClient API get() method => Fetch magazzino single item
getCity(id,schema): Observable<Citta> {
  return this.http.get<Citta>(this.apiURL + 'anagrafica_citta/readsingle.php?id=' + id + '&schema=' + schema)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
} 
getSearchCity(keyword,type,schema): Observable<Citta> {
  return this.http.get<Citta>(this.apiURL + 'anagrafica_citta/search.php?keyword=' + keyword + '&type=' + type  + '&schema=' + schema)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
} 
//#endregion

//#region Settings
  // HttpClient API get() method => Fetch magazzino list
  getSettings(): Observable<Settings> {
    return this.http.get<Settings>(this.apiURL + '/settings/read.php')
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  getSetting(id): Observable<Settings> {
    return this.http.get<Settings>(this.apiURL + '/settings/readsingle.php?id=' + id)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API put() method => Update employee
  createSettings(settings): Observable<Settings> {
    return this.http.post<Settings>(this.apiURL + '/settings/create.php', settings, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API put() method => Update employee
  setSettings(settings): Observable<Settings> {
    return this.http.post<Settings>(this.apiURL + '/settings/update.php', settings, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch magazzino list
  setReset(schema): Observable<Settings> {
    return this.http.get<Settings>(this.apiURL + '/settings/reset.php?schema=' + schema)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }  
//#endregion

//#region SettingsAzienda
  // HttpClient API get() method => Fetch magazzino list
  getSettingsAzienda(schema): Observable<Settings> {
    return this.http.get<Settings>(this.apiURL + '/settings/read.php?schema=' + schema)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  getSettingAzienda(id,schema): Observable<SettingsAzienda> {
    return this.http.get<SettingsAzienda>(this.apiURL + '/settings/getsettingsinfo.php?id=' + id  + '&schema=' + schema)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API put() method => Update employee
  setSettingsAzienda(settings,schema): Observable<Settings> {
    return this.http.post<Settings>(this.apiURL + '/settings/setsettingsinfo.php?schema=' + schema, settings, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
//#endregion

//#region MainEventDescription
        // HttpClient API get() method => Fetch MainEventDescriptions all item
        getMainEventDescriptionList(token): Observable<MainEventDescription> {
          return this.http.get<MainEventDescription>(AUTH_API + 'maineventdescriptions/read.php?token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }

        // HttpClient API get() method => Fetch MainEventDescriptions single item
        getMainEventDescription(id,token): Observable<MainEventDescription> {
          return this.http.get<MainEventDescription>(AUTH_API + 'maineventdescriptions/readsingle.php?id='+ id +'&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }

        // HttpClient API get() method => Fetch MainEventDescriptions item for typerig
        getMainEventDescriptionForType(typerig,token): Observable<MainEventDescription> {
          return this.http.get<MainEventDescription>(AUTH_API + 'maineventdescriptions/readsingle.php?typerig='+ typerig +'&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }
  // HttpClient API post() method => Create med
  createMainEventDescription(med,token): Observable<MainEventDescription> {
    return this.http.post<MainEventDescription>(this.apiURL + 'maineventdescriptions/create.php?token=' + token, med, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateMainEventDescription(med,token): Observable<MainEventDescription> {
    return this.http.post<MainEventDescription>(this.apiURL + 'maineventdescriptions/update.php?token=' + token, med, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteMainEventDescription(med,token): Observable<MainEventDescription> {
    return this.http.post<MainEventDescription>(this.apiURL + 'maineventdescriptions/delete.php?token=' + token, med, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
//#endregion

//#region MainEvent
        // HttpClient API get() method => Fetch MainEventDescriptions all item
        getMainEventList(token): Observable<MainEvent> {
          return this.http.get<MainEvent>(AUTH_API + 'mainevents/read.php?token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }

        // HttpClient API get() method => Fetch MainEventDescriptions single item
        getMainEventSingle(id,token): Observable<MainEvent> {
          return this.http.get<MainEvent>(AUTH_API + 'mainevents/readsingle.php?id='+ id +'&token=' + token)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }

        getApproveMessage(idmainevent,approved): Observable<MainEvent> {
          return this.http.get<MainEvent>(AUTH_API + 'mainevents/approvemessage.php?idmainevent='+ idmainevent +'&approved=' + approved)
          .pipe(
            catchError(
              err => {
                //console.log('Handling error locally and rethrowing it...', err);
                return throwError(err)}
            )
          )
        }
  // HttpClient API post() method => Create med
  createMainEvent(mainevent): Observable<MainEvent> {
    return this.http.post<MainEvent>(this.apiURL + 'mainevents/create.php', mainevent, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateMainEvent(mainevent): Observable<MainEvent> {
    return this.http.post<MainEvent>(this.apiURL + 'mainevents/update.php', mainevent, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteMainEvent(mainevent): Observable<MainEvent> {
    return this.http.post<MainEvent>(this.apiURL + 'mainevents/delete.php', mainevent, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
//#endregion

//#region Downloads
  // HttpClient API get() method => Fetch Downloads all item
  getDownloadsList(token): Observable<Downloads> {
    return this.http.get<Downloads>(AUTH_API + 'downloads/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch Downloads single item
  getDownloadsSingle(id,token): Observable<Downloads> {
    return this.http.get<Downloads>(AUTH_API + 'downloads/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Create med
  createDownload(dow,token): Observable<Downloads> {
    return this.http.post<Downloads>(this.apiURL + 'downloads/create.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updateDownload(dow,token): Observable<Downloads> {
    return this.http.post<Downloads>(this.apiURL + 'downloads/update.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deleteDownload(dow,token): Observable<Downloads> {
    return this.http.post<Downloads>(this.apiURL + 'downloads/delete.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
//#endregion

//#region PosizionamentiCoordinate
  // HttpClient API get() method => Fetch Downloads all item
  getPosizionamentiCoordinate(token): Observable<PosizionamentiCoordinates> {
    return this.http.get<PosizionamentiCoordinates>(AUTH_API + 'posizionamenti_coordinate/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch Downloads single item
  getPosizionamentiCoordinateSingle(id,token): Observable<PosizionamentiCoordinates> {
    return this.http.get<PosizionamentiCoordinates>(AUTH_API + 'posizionamenti_coordinate/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
    // HttpClient API get() method => Fetch Downloads single item
  getPosizionamentiCoordinateSingleForPozzo(id,token): Observable<PosizionamentiCoordinates> {
    return this.http.get<PosizionamentiCoordinates>(AUTH_API + 'posizionamenti_coordinate/readsingleforpozzo.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API get() method => Fetch Downloads single item
  getPosizionamentiCoordinateSingleForPozzoWGS84(id,token): Observable<PosizionamentiCoordinates> {
    return this.http.get<PosizionamentiCoordinates>(AUTH_API + 'posizionamenti_coordinate/readsingleforpozzowgs84.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Create med
  createPosizionamentiCoordinate(coord,token): Observable<PosizionamentiCoordinates> {
    return this.http.post<PosizionamentiCoordinates>(this.apiURL + 'posizionamenti_coordinate/create.php?token=' + token, coord, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updatePosizionamentiCoordinate(coord,token): Observable<PosizionamentiCoordinates> {
    return this.http.post<PosizionamentiCoordinates>(this.apiURL + 'posizionamenti_coordinate/update.php?token=' + token, coord, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deletePosizionamentiCoordinate(coord,token): Observable<PosizionamentiCoordinates> {
    return this.http.post<PosizionamentiCoordinates>(this.apiURL + 'posizionamenti_coordinate/delete.php?token=' + token, coord, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
//#endregion

//#region PosizionamentiDatum
  // HttpClient API get() method => Fetch Downloads all item
  getPosizionamentiDatum(token): Observable<PosizionamentiDatum> {
    return this.http.get<PosizionamentiDatum>(AUTH_API + 'posizionamenti_datum/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch Downloads single item
  getPosizionamentiDatumSingle(id,token): Observable<PosizionamentiDatum> {
    return this.http.get<PosizionamentiDatum>(AUTH_API + 'posizionamenti_datum/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Create med
  createPosizionamentiDatum(dow,token): Observable<PosizionamentiDatum> {
    return this.http.post<PosizionamentiDatum>(this.apiURL + 'posizionamenti_datum/create.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updatePosizionamentiDatum(dow,token): Observable<PosizionamentiDatum> {
    return this.http.post<PosizionamentiDatum>(this.apiURL + 'posizionamenti_datum/update.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deletePosizionamentiDatum(dow,token): Observable<PosizionamentiDatum> {
    return this.http.post<PosizionamentiDatum>(this.apiURL + 'posizionamenti_datum/delete.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
//#endregion

//#region PosizionamentiTipologie
  // HttpClient API get() method => Fetch Downloads all item
  gePosizionamentiTipologie(token): Observable<PosizionamentiTipologie> {
    return this.http.get<PosizionamentiTipologie>(AUTH_API + 'posizionamenti_tipologie/read.php?token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }

  // HttpClient API get() method => Fetch Downloads single item
  getPosizionamentiTipologieSingle(id,token): Observable<PosizionamentiTipologie> {
    return this.http.get<PosizionamentiTipologie>(AUTH_API + 'posizionamenti_tipologie/readsingle.php?id='+ id +'&token=' + token)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
  // HttpClient API post() method => Create med
  createPosizionamentiTipologie(dow,token): Observable<PosizionamentiTipologie> {
    return this.http.post<PosizionamentiTipologie>(this.apiURL + 'posizionamenti_tipologie/create.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 
  
  // HttpClient API post() method => Update med
  updatePosizionamentiTipologie(dow,token): Observable<PosizionamentiTipologie> {
    return this.http.post<PosizionamentiTipologie>(this.apiURL + 'posizionamenti_tipologie/update.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  } 

  deletePosizionamentiTipologie(dow,token): Observable<PosizionamentiTipologie> {
    return this.http.post<PosizionamentiTipologie>(this.apiURL + 'posizionamenti_tipologie/delete.php?token=' + token, dow, this.httpOptions)
    .pipe(
      catchError(
        err => {
          //console.log('Handling error locally and rethrowing it...', err);
          return throwError(err)}
      )
    )
  }
//#endregion

//#region Utility
getCalcolateDistance(lat1,lng1,lat2,lng2): Observable<Coordinates> {
  return this.http.get<Coordinates>(AUTH_API + 'utility/calcolatedistance.php?lat1='+ lat1 + '&lng1=' + lng1 + '&lat2='+ lat2 + '&lng2=' + lng2)
  .pipe(
    catchError(
      err => {
        //console.log('Handling error locally and rethrowing it...', err);
        return throwError(err)}
    )
  )
}
//#endregion

// Error handling 
  handleError(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     window.alert(errorMessage);
     return throwError(errorMessage);
  }

}
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptureService } from 'ngx-capture';
import { DataService } from 'src/app/shared/data.service';
import { Posizionamento } from 'src/app/shared/posizionamenti';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-anagrafica-pozzi',
  templateUrl: './anagrafica-pozzi.component.html',
  styleUrls: ['./anagrafica-pozzi.component.css']
})
export class AnagraficaPozziComponent implements OnInit {
  displayedColumns: string[] = ['codiceprogetto', 'codicepozzo', 'nomepozzo','anno', 'nazione','cliente', 'action'];
  dataSource: MatTableDataSource<Posizionamento>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  idmodal:string;
  status:string;
  showList:boolean;
  PiattaformeList: any = [];
  PozziList: any = [];
  PozzoSelect: any = [];
  Customers: any = [];
  Nations: any = [];
  ListAttachments: any = [];
  ListTipFile: any = [];
  ListCoordinates: any = [];
  ListCoordinate: any = [];
  ListDatum: any = [];
  ListDatumSingle: any = [];
  ListTipologie: any = [];
  ListTipologia: any = [];
  keysearch: string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  share_message;
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";

  idposizionamento: string;
  codiceprogetto: string;
  
  codiceprogettoint: string;
  codicepozzo: string;
  codicepozzoint:number;
  nomepozzo: string;
  datainizioposizionamento: string;
  datafineposizionamento: string;
  anno: string;
  idcliente: string;
  idattivita: string;
  idpiattaforma: string;
  idnazione: string;
  tipoSurveyDatum: string;
  latSurveyDatum: string;
  lngSurveyDatum: string;
  latSurveyDatumGrad: string;
  lngSurveyDatumGrad: string;
  latWGS84: string;
  lngWGS84: string;
  latWGS84Grad: string;
  lngWGS84Grad: string;
  latPH: string;
  lngPH: string;
  latPHGrad: string;
  lngPHGrad: string;
  latPHWGS84: string;
  lngPHWGS84: string;
  latPHWGS84Grad: string;
  lngPHWGS84Grad: string;
  latMH: string;
  lngMH: string;
  latMHGrad: string;
  lngMHGrad: string;
  latMHWGS84: string;
  lngMHWGS84: string;
  latMHWGS84Grad: string;
  lngMHWGS84Grad: string;
  wellactive:string;
  config: any = [];
  statuswell:string;
  statuswelldesc:string;
  attachmentdesc:string;
  tipologiafile:string;
  active:string;

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  title:string;
  typeLoading:string;
  files: any[] = [];
  filenameUpload:string;
  apiURL:string;
  message:string;
  progress:number;
  object:string;

  imageSrc:any = '';
  statusfile:boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  public AUTH_API = 'https://rcs.explorermarinesboss.com/api/';

  form: FormGroup;
  uploadResponse;
  showupload:boolean = false;
  showuploadnew:boolean = false;
  interval = null;
  monitorstate:boolean = false;
  monitordesc:string;
  idallegato:string;
  filename:string;
  pathfile:string;
  idcoord:string;
  idtipologiacoord:string;
  idtipologiaedit:string;
  tipologiaedit:string;
  iddatum:string;
  iddatumedit:string;
  datumedit:string;

  showtipologianew:boolean;
  showtipologiaedit:boolean;
  showtipologiadel:boolean;
  showtipologiasave:boolean;
  showtipologiaok:boolean;
  showtipologiacancel:boolean;
  showtipologiacombobox:boolean;
  showtipologiatext:boolean;
  showTipologiaBlock:boolean;
  messagetipologia:string;
  typetipologiaoper:string;

  showdatumnew:boolean;
  showdatumedit:boolean;
  showdatumdel:boolean;
  showdatumsave:boolean;
  showdatumok:boolean;
  showdatumcancel:boolean;
  showdatumcombobox:boolean;
  showdatumtext:boolean;
  showDatumBlock:boolean;
  messagedatum:string;
  typedatumoper:string;

  latcoord:string;
  latgradcoord:string;
  latfirstcoord:string;
  latsecondcoord:string;
  lngcoord:string;
  lnggradcoord:string;
  lngfirstcoord:string;
  lngsecondcoord:string;
  ordine:string;
  latdir:string;
  lngdir:string;
  
  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService,
    private http: HttpClient,
    private data: DataService,
    private formBuilder: FormBuilder,
    private captureService:NgxCaptureService
  ) {

    this.config = {
      firstDayOfWeek: 'su',
      monthFormat: 'MMM, YYYY',
      disableKeypress: false,
      allowMultiSelect: false,
      closeOnSelect: undefined,
      closeOnSelectDelay: 100,
      onOpenDelay: 0,
      weekDayFormat: 'ddd',
      appendTo: document.body,
      drops: 'down',
      opens: 'right',
      showNearMonthDays: true,
      showWeekNumbers: false,
      enableMonthSelector: true,
      format: "YYYY-MM-DD HH:mm:ss",
      yearFormat: 'YYYY',
      showGoToCurrent: true,
      dayBtnFormat: 'DD',
      monthBtnFormat: 'MMM',
      hours12Format: 'hh',
      hours24Format: 'HH',
      meridiemFormat: 'A',
      minutesFormat: 'mm',
      minutesInterval: 1,
      secondsFormat: 'ss',
      secondsInterval: 1,
      showSeconds: true,
      showTwentyFourHours: true,
      timeSeparator: ':',
      multipleYearsNavigateBy: 10,
      showMultipleYearsNavigation: false,
      //locale: 'zh-cn',
      // min:'2017-08-29 15:50',
      // minTime:'2017-08-29 15:50'
    };

   }

   receiveMessage($event) {
     this.message = $event
   }

  ngOnInit(): void {
    this.status = "master";
    this.showList = true;
    this.destitle = "New Positioning";

    this.showtipologianew = true;
    this.showtipologiaedit = true;
    this.showtipologiadel = true;
    this.showtipologiasave = false;
    this.showtipologiaok = false;
    this.showtipologiacancel = false;
    this.showtipologiacombobox = true;
    this.showtipologiatext = false;
    this.showTipologiaBlock = true;

    this.showdatumnew = true;
    this.showdatumedit = true;
    this.showdatumdel = true;
    this.showdatumsave = false;
    this.showdatumok = false;
    this.showdatumcancel = false;    
    this.showdatumcombobox = true;
    this.showdatumtext = false;
    this.showDatumBlock = true;

    this.form = this.formBuilder.group({
      avatar: [''],
      annohidden: ['']
    });
    this.loadPozzi();
    this.getCustomers();
    this.getNations();
    this.getRIG();
    this.getTipologiaFile();
    this.loadDatum();
    this.loadTipologie();
  }
  
  getTipologiaFile(){
    return this.restApi.getAttachmentsType(this.token).subscribe((data: {}) => {
      this.ListTipFile = data;
    });
  }

  loadPozzi(){
    this.keysearch = "";

    return this.restApi.getPosizionamenti(this.token).subscribe((data: {}) => {
      this.PozziList = data;
      var count = this.PozziList.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.PozziList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  pozzoLoad(idpos){
    this.resetData();
    
    this.restApi.getPosizionamento(idpos,this.token).subscribe((data: {}) => {
      this.PozzoSelect = data;
      var count = this.PozzoSelect.length;
      if(count > 0){
        this.idposizionamento = this.PozzoSelect[0].idposizionamento;
        this.loadCoordinates(this.idposizionamento);
        this.codiceprogetto = this.PozzoSelect[0].codiceprogetto;
        this.codiceprogettoint = this.PozzoSelect[0].codiceprogettoint;
        this.codicepozzo = this.PozzoSelect[0].codicepozzo;
        this.nomepozzo = this.PozzoSelect[0].nomepozzo;
        
        var datainizioposizionamento = this.PozzoSelect[0].datainizioposizionamento; 
        if(datainizioposizionamento == "0000-00-00 00:00:00"){
          this.datainizioposizionamento = "";
        }else{
          this.datainizioposizionamento = datainizioposizionamento;
        }
        var datafineposizionamento = this.PozzoSelect[0].datafineposizionamento; 
        if(datafineposizionamento == "0000-00-00 00:00:00"){
          this.datafineposizionamento = "";
        }else{
          this.datafineposizionamento = datafineposizionamento;
        }
        this.anno = this.PozzoSelect[0].anno; 
        this.idcliente = this.PozzoSelect[0].idcliente; 
        this.idattivita = this.PozzoSelect[0].idattivita; 
        this.idpiattaforma = this.PozzoSelect[0].idpiattaforma; 
        this.idnazione = this.PozzoSelect[0].idnazione; 
        this.tipoSurveyDatum = this.PozzoSelect[0].tipoSurveyDatum; 
        this.latSurveyDatum = this.PozzoSelect[0].latSurveyDatum; 
        this.lngSurveyDatum = this.PozzoSelect[0].lngSurveyDatum; 
        if(this.PozzoSelect[0].latSurveyDatumGrad != null){
          this.latSurveyDatumGrad = this.PozzoSelect[0].latSurveyDatumGrad.replace("?","°"); 
        }
        if(this.PozzoSelect[0].lngSurveyDatumGrad != null){
          this.lngSurveyDatumGrad = this.PozzoSelect[0].lngSurveyDatumGrad.replace("?","°");
        }

        this.latWGS84 = this.PozzoSelect[0].latWGS84; 
        this.lngWGS84 = this.PozzoSelect[0].lngWGS84; 
        if(this.PozzoSelect[0].latWGS84Grad != null){
          this.latWGS84Grad = this.PozzoSelect[0].latWGS84Grad.replace("?","°");
        }
        if(this.PozzoSelect[0].lngWGS84Grad != null){
          this.lngWGS84Grad = this.PozzoSelect[0].lngWGS84Grad.replace("?","°");
        }
        this.latPH = this.PozzoSelect[0].latPH; 
        this.lngPH = this.PozzoSelect[0].lngPH; 

        if(this.PozzoSelect[0].latPHGrad != null){
          this.latPHGrad = this.PozzoSelect[0].latPHGrad.replace("?","°");
        }
        if(this.PozzoSelect[0].lngPHGrad != null){
          this.lngPHGrad = this.PozzoSelect[0].lngPHGrad.replace("?","°");
        }
        this.latPHWGS84 = this.PozzoSelect[0].latPHWGS84; 
        this.lngPHWGS84 = this.PozzoSelect[0].lngPHWGS84; 

        if(this.PozzoSelect[0].latPHWGS84Grad != null){
          this.latPHWGS84Grad = this.PozzoSelect[0].latPHWGS84Grad.replace("?","°");
        }
        if(this.PozzoSelect[0].lngPHWGS84Grad != null){
          this.lngPHWGS84Grad = this.PozzoSelect[0].lngPHWGS84Grad.replace("?","°");
        }

        this.latMH = this.PozzoSelect[0].latMH; 
        this.lngMH = this.PozzoSelect[0].lngMH; 
        if(this.PozzoSelect[0].latMHGrad != null){
          this.latMHGrad = this.PozzoSelect[0].latMHGrad.replace("?","°");
        }
        if(this.PozzoSelect[0].lngMHGrad != null){
          this.lngMHGrad = this.PozzoSelect[0].lngMHGrad.replace("?","°");
        }
        this.latMHWGS84 = this.PozzoSelect[0].latMHWGS84; 
        this.lngMHWGS84 = this.PozzoSelect[0].lngMHWGS84; 
        if(this.PozzoSelect[0].latMHWGS84Grad != null){
          this.latMHWGS84Grad = this.PozzoSelect[0].latMHWGS84Grad.replace("?","°");
        }
        if(this.PozzoSelect[0].lngMHWGS84Grad != null){
          this.lngMHWGS84Grad = this.PozzoSelect[0].lngMHWGS84Grad.replace("?","°");
        }
        this.wellactive = this.PozzoSelect[0].active; 
        this.statuswell = this.PozzoSelect[0].status; 
        var status = "";

        switch(this.statuswell){
          case "0":
            this.statuswelldesc = "Not active";
            break;
          case "1":
            this.statuswelldesc = "Active";
            break;
          case "2":
            this.statuswelldesc = "Closed";
            break;
         }

        this.destitle = "Edit Positioning: " + this.codiceprogetto + " - " +  this.PozzoSelect[0].nomepozzo + " - " + this.codicepozzo + " - Status: ";

        this.restApi.getAttachments(this.idposizionamento, this.token).subscribe((data: {}) => {
          this.ListAttachments = data;
        });

        this.loadDetail();
      }

    });
  }

  getCustomers(){
    this.restApi.getClienti(this.token).subscribe((data: {}) => {
      this.Customers = data;
    });
  }

  getNations(){
    this.restApi.getNationsList(this.token).subscribe((data: {}) => {
      this.Nations = data;
    });
  }

  getRIG(){
    this.restApi.getPiattaformeList(this.token).subscribe((data: {}) => {
      this.PiattaformeList = data;
    });
  }

  openModalNew(content) {
    this.idmodal = "normal";
    this.titleModal = "Nuovo Pozzo";
    this.bodyModal = "Vuoi aggiungere un nuovo pozzo?";
    this.buttonAction = "Aggiungi";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalSave(content) {
    this.idmodal = "normal";
    this.titleModal = "Salvataggio Pozzo";
    this.bodyModal = "Vuoi salvare i cambiamenti pozzo selezionato?";
    this.buttonAction = "Save";
    this.actionOp = "2";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalSaveAttachment(content) {
    this.idmodal = "normal";
    this.titleModal = "Salvataggio Documento Pozzo";
    this.bodyModal = "Vuoi salvare il documento relativo al pozzo selezionato?";
    this.buttonAction = "Save";
    this.actionOp = "2";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalClose(content) {
    this.idmodal = "normal";
    this.titleModal = "Well closure";
    this.bodyModal = "Do you want to proceed with the closure of the selected well?";
    this.buttonAction = "Proceed to close";
    this.actionOp = "4";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalDelete(content, idposizionamento) {
    this.idposizionamento = idposizionamento;
    this.idmodal = "normal";
    this.titleModal = "Elimina Pozzo";
    this.bodyModal = "Vuoi eliminare il pozzo selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "3";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  loadMaster(){
    this.loadPozzi();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.action = "upd";
    this.status = "detail";
    console.log("detail");
    
  }

  loadDetailTab(id){
    if(id=="0"){
      this.action = "upd";
      this.status = "detail";
      console.log("detail");
    }else{
      this.action = "upd";
      this.status = "detailfile";
      console.log("detailfile");
    }
  }

  newWell(){
    this.status = "detail";
    this.action = "new";
    this.idposizionamento = "";
    this.codiceprogetto =  "";
    this.codicepozzo =  "";
    this.nomepozzo = "";
    this.datainizioposizionamento = "";
    this.datafineposizionamento = "";
    this.anno = "";
    this.idcliente = "";
    this.idattivita = "";
    this.idpiattaforma = "";
    this.idnazione = "";
    this.tipoSurveyDatum = "";
    this.latSurveyDatum = "";
    this.lngSurveyDatum = "";
    this.latSurveyDatumGrad = "";
    this.lngSurveyDatumGrad = "";
    this.latWGS84 = "";
    this.lngWGS84 = "";
    this.latWGS84Grad = "";
    this.lngWGS84Grad = "";
    this.latPH = "";
    this.lngPH = "";
    this.latPHGrad = "";
    this.lngPHGrad = "";
    this.latPHWGS84 = "";
    this.lngPHWGS84 = "";
    this.latPHWGS84Grad = "";
    this.lngPHWGS84Grad = "";
    this.latMH = "";
    this.lngMH = "";
    this.latMHGrad = "";
    this.lngMHGrad = "";
    this.latMHWGS84 = "";
    this.lngMHWGS84 = "";
    this.latMHWGS84Grad = "";
    this.lngMHWGS84Grad = "";
    this.wellactive = "";
  }

  resetData(){
    this.idposizionamento = "";
    this.codiceprogetto = "";
    this.codiceprogettoint = "";
    this.codicepozzo = "";
    this.codicepozzoint = 0;
    this.nomepozzo = "";
    this.datainizioposizionamento = "";
    this.datafineposizionamento  = "";
    this.anno = "";
    this.idcliente = "";
    this.idattivita = "";
    this.idpiattaforma = "";
    this.idnazione = "";
    this.tipoSurveyDatum = "";
    this.latSurveyDatum = "";
    this.lngSurveyDatum = "";
    this.latSurveyDatumGrad = "";
    this.lngSurveyDatumGrad = "";
    this.latWGS84 = "";
    this.lngWGS84 = "";
    this.latWGS84Grad = "";
    this.lngWGS84Grad = "";
    this.latPH = "";
    this.lngPH = "";
    this.latPHGrad = "";
    this.lngPHGrad = "";
    this.latPHWGS84 = "";
    this.lngPHWGS84  = "";
    this.latPHWGS84Grad = "";
    this.lngPHWGS84Grad = "";
    this.latMH = "";
    this.lngMH = "";
    this.latMHGrad = "";
    this.lngMHGrad = "";
    this.latMHWGS84 = "";
    this.lngMHWGS84 = "";
    this.latMHWGS84Grad = "";
    this.lngMHWGS84Grad = "";
    this.active = "";
    this.status = "";
  }

  saveWell(){
    var pozzo: any = [];

      if(this.action == "new"){
        //insert
        pozzo = {
          "codiceprogetto": this.codiceprogetto,
          "codicepozzo": this.codicepozzo,
          "nomepozzo": this.nomepozzo,
          "datainizioposizionamento": this.datainizioposizionamento, 
          "datafineposizionamento": this.datafineposizionamento, 
          "anno": this.anno, 
          "idcliente": this.idcliente, 
          "idattivita": this.idattivita, 
          "idpiattaforma": this.idpiattaforma, 
          "idnazione": this.idnazione, 
          "tipoSurveyDatum": this.tipoSurveyDatum, 
          "latSurveyDatum": this.latSurveyDatum, 
          "lngSurveyDatum": this.lngSurveyDatum, 
          "latSurveyDatumGrad": this.latSurveyDatumGrad, 
          "lngSurveyDatumGrad": this.lngSurveyDatumGrad, 
          "latWGS84": this.latWGS84, 
          "lngWGS84": this.lngWGS84, 
          "latWGS84Grad": this.latWGS84Grad, 
          "lngWGS84Grad": this.lngWGS84Grad, 
          "latPH": this.latPH, 
          "lngPH": this.lngPH, 
          "latPHGrad": this.latPHGrad, 
          "lngPHGrad": this.lngPHGrad, 
          "latPHWGS84": this.latPHWGS84, 
          "lngPHWGS84": this.lngPHWGS84, 
          "latPHWGS84Grad": this.latPHWGS84Grad, 
          "lngPHWGS84Grad": this.lngPHWGS84Grad, 
          "latMH": this.latMH, 
          "lngMH": this.lngMH, 
          "latMHGrad": this.latMHGrad, 
          "lngMHGrad": this.lngMHGrad, 
          "latMHWGS84": this.latMHWGS84, 
          "lngMHWGS84": this.lngMHWGS84, 
          "latMHWGS84Grad": this.latMHWGS84Grad,
          "lngMHWGS84Grad": this.lngMHWGS84Grad,
          "token": this.token
        };
      
        this.restApi.createPosizionamento(pozzo).subscribe((data: {}) => {
          var message = "Salvataggio pozzo avvenuto con successo.";
          var action = "ok"
          this.openSnackBar(message, action);
        });

      }else{
        //update
        pozzo = {
          "codiceprogetto": this.codiceprogetto,
          "codicepozzo": this.codicepozzo,
          "nomepozzo": this.nomepozzo,
          "datainizioposizionamento": this.datainizioposizionamento, 
          "datafineposizionamento": this.datafineposizionamento, 
          "anno": this.anno, 
          "idcliente": this.idcliente, 
          "idattivita": this.idattivita, 
          "idpiattaforma": this.idpiattaforma, 
          "idnazione": this.idnazione, 
          "tipoSurveyDatum": this.tipoSurveyDatum, 
          "latSurveyDatum": this.latSurveyDatum, 
          "lngSurveyDatum": this.lngSurveyDatum, 
          "latSurveyDatumGrad": this.latSurveyDatumGrad, 
          "lngSurveyDatumGrad": this.lngSurveyDatumGrad, 
          "latWGS84": this.latWGS84, 
          "lngWGS84": this.lngWGS84, 
          "latWGS84Grad": this.latWGS84Grad, 
          "lngWGS84Grad": this.lngWGS84Grad, 
          "latPH": this.latPH, 
          "lngPH": this.lngPH, 
          "latPHGrad": this.latPHGrad, 
          "lngPHGrad": this.lngPHGrad, 
          "latPHWGS84": this.latPHWGS84, 
          "lngPHWGS84": this.lngPHWGS84, 
          "latPHWGS84Grad": this.latPHWGS84Grad, 
          "lngPHWGS84Grad": this.lngPHWGS84Grad, 
          "latMH": this.latMH, 
          "lngMH": this.lngMH, 
          "latMHGrad": this.latMHGrad, 
          "lngMHGrad": this.lngMHGrad, 
          "latMHWGS84": this.latMHWGS84, 
          "lngMHWGS84": this.lngMHWGS84, 
          "latMHWGS84Grad": this.latMHWGS84Grad,
          "lngMHWGS84Grad": this.lngMHWGS84Grad,
          "idposizionamento": this.idposizionamento,
          "token": this.token
        };
      
        this.restApi.updatePosizionamento(pozzo).subscribe((data: {}) => {
          var message = "Aggiornamento pozzo avvenuto con successo.";
          var action = "ok"
          this.openSnackBar(message, action);
        });
      }
  }

  saveAttachment(){
    var attach: any = [];
    console.log('saveAttachment');
        
    var pathfile = "Positioning/" + this.anno + "/" +  this.codiceprogettoint + "/" + this.filename;

    if(this.action == "new"){
      console.log('insert');
      //insert
      attach = {
        "descrizione": this.attachmentdesc,
        "pathfile": pathfile,
        "idtipo": this.tipologiafile,
        "idposizionamento": this.idposizionamento, 
        "token": this.token
      };
      console.log('attach = ' + JSON.stringify(attach));
    
      this.restApi.createAttach(attach).subscribe((data: {}) => {
        this.pozzoLoad(this.idposizionamento);
        var message = "Salvataggio documento pozzo avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      });

    }else{
      //update
      console.log('update');
      attach = {
        "descrizione": this.attachmentdesc,
        "pathfile": pathfile,
        "idtipo": this.tipologiafile,
        "idposizionamento": this.idposizionamento, 
        "idallegato":this.idallegato,
        "token": this.token
      };
    
      this.restApi.updateAttach(attach).subscribe((data: {}) => {
        var message = "Aggiornamento documento pozzo avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      });
    }
  }

  deleteWell(){
    var posizionamento = {
      idposizionamento: this.idposizionamento,
      token: this.token
    }; 

    this.restApi.deletePosizionamento(posizionamento).subscribe((data: {}) => {
      this.loadPozzi();
      var message = "Pozzo eliminato con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  activeWell(status){
    var upd: any = [];
    upd = {
      "active": status,
      "idposizionamento": this.idposizionamento,
      "token": this.token
    };
  
    this.restApi.updateActiveWell(upd).subscribe((data: {}) => {
      if(status == '1'){
        this.wellactive = '0';
      }else{
        this.wellactive = '1';
      }
      var message = "Aggiornamento pozzo avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  closedWell(){
    //update status well = 2
    var upd: any = [];
    upd = {
      "status": "2",
      "idposizionamento": this.idposizionamento,
      "token": this.token
    };
  
    this.restApi.updateStateWell(upd).subscribe((data: {}) => {
      
      var upd: any = [];
      upd = {
        "idpiattaforma": 0,
        "idposizionamento": this.idposizionamento,
        "token": this.token
      };
      
      this.restApi.resetPlatform(upd).subscribe((data: {}) => {
        var message = "Well closed successfully.";
        var action = "ok"
        this.openSnackBar(message, action);
      });
    });
  }

  calculate(input){

    var value = "";

    switch(input){
      case "latsd":
        var value = this.latSurveyDatumGrad;
        break;
      case "lngsd":
        var value = this.lngSurveyDatumGrad;
        break;
      case "latwgs84":
        var value = this.latWGS84Grad;
        break;
      case "lngwgs84":
        var value = this.lngWGS84Grad;
        break;
      case "latPH":
        var value = this.latPHWGS84Grad;
        break;
      case "lngPH":
        var value = this.lngPHWGS84Grad;
        break;
      case "latMH":
        var value = this.latMHGrad;
        break;
      case "lngMH":
        var value = this.lngMHGrad;
        break;
          
    }

    if(value != ""){
      var first = value.split('°')[0].toString();
      var prime = value.split(' \'')[0].toString();
      var prime1 = prime.split('°')[1].toString();
      var prime2 = prime1.split('\'')[0].toString();
      var second = value.split('\'')[1].toString();
      console.log('value' + value);
      console.log('first' + first);
      console.log('prime' + prime2);
      console.log('second' + second);
      var calc1 = parseFloat(second) / 60;
      var calc2 = parseFloat(prime2) + calc1;
      var calc3 = calc2/60;
      var calc4 = parseFloat(first) + calc3;
      
      switch(input){
        case "latsd":
          this.latSurveyDatum = calc4.toString().replace(".",",");
          break;
        case "lngsd":
          this.lngSurveyDatum = calc4.toString().replace(".",",");
          break;
        case "latwgs84":
          this.latWGS84 = calc4.toString().replace(".",",");
          break;
        case "lngwgs84":
          this.lngWGS84 = calc4.toString().replace(".",",");
          break;
        case "latPH":
          this.latPH = calc4.toString().replace(".",",");
          break;
        case "lngPH":
          this.lngPH = calc4.toString().replace(".",",");
          break;
        case "latMH":
          this.latMH = calc4.toString().replace(".",",");
          break;
        case "lngMH":
          this.lngMH = calc4.toString().replace(".",",");
          break;
            
      }
     
    }
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = event.target.files[0].name;
      console.log('filename = ' + this.filename);
      this.form.get('avatar').setValue(file);
    }
  }

  showUploadForm(){
    this.showupload = true;
    this.showuploadnew = true;
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);
    var anno = this.anno;
    console.log('anno submit' + this.anno);
    this.action = "new";
    this.restApi.uploadFileWell(this.anno, this.codiceprogettoint, formData).subscribe((data: {}) => {
          this.saveAttachment();
    });
  }

  openModalDeleteAttach(content, idposizionamento, idallegato, pathfile) {
    this.idallegato = idallegato;
    this.pathfile = pathfile;
    this.idposizionamento = idposizionamento;
    this.idmodal = "normal";
    this.titleModal = "Elimina Documento";
    this.bodyModal = "Vuoi eliminare il documento selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "6";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteFile(){
    //console.log('idallegato = ' + idallegato);
    //console.log('pathfile = ' + pathfile);

    var attach = {
      "idallegato":this.idallegato
    };

    this.restApi.deleteAttach(attach).subscribe((data: {}) => {
      this.pozzoLoad(this.idposizionamento);
      var message = "Allegato eliminato con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }

  loadCoordinates(idpozzo){
    this.restApi.getPosizionamentiCoordinateSingleForPozzo(idpozzo, this.token).subscribe((data: {}) => {
      this.ListCoordinates = data;
    });
  }

  loadCoordinate(idcoord){
    this.restApi.getPosizionamentiCoordinateSingle(idcoord, this.token).subscribe((data: {}) => {
      this.ListCoordinate = data;

      this.idcoord = this.ListCoordinate[0].idcoord;
      this.idtipologiacoord = this.ListCoordinate[0].idtipologia;
      this.iddatum = this.ListCoordinate[0].iddatum;
      this.latcoord = this.ListCoordinate[0].latitude;
      this.latgradcoord = this.ListCoordinate[0].latitudegrad;
      this.lngcoord = this.ListCoordinate[0].longitude;
      this.lnggradcoord = this.ListCoordinate[0].longitudegrad;
      this.ordine = this.ListCoordinate[0].ordine;
    });
  }

  loadDatum(){
    this.restApi.getPosizionamentiDatum(this.token).subscribe((data: {}) => {
      this.ListDatum = data;
    });
  }

  loadTipologie(){
    this.restApi.gePosizionamentiTipologie(this.token).subscribe((data: {}) => {
      this.ListTipologie = data;
    });
  }

  openModalNewCoord(content, idcoord) {
    this.idcoord = idcoord;
    this.idmodal = "newcoord";
    this.titleModal = "Nuova Coordinata";
    this.bodyModal = "";
    this.buttonAction = "Salva";
    this.actionOp = "8";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalLoadCoord(content, idcoord){
    this.idcoord = idcoord;
    this.loadCoordinate(idcoord);
    this.idmodal = "newcoord";
    
      this.titleModal = "Modifica coordinate";
    
    this.bodyModal = "";
    this.buttonAction = "Salva";
    this.actionOp = "9";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openModalDeleteCoord(content, idcoord) {
    this.idcoord = idcoord;
    this.idmodal = "normal";
    this.titleModal = "Elimina Coordinata";
    this.bodyModal = "Vuoi eliminare la coordinata selezionata?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveCoord(){
    var newcoord = {
      "idposizionamento": this.idposizionamento,
      "idtipologia": this.idtipologiacoord,
      "latitude": this.latcoord,
      "latitudegrad": this.latgradcoord, 
      "longitude": this.lngcoord, 
      "longitudegrad": this.lnggradcoord, 
      "iddatum": this.iddatum, 
      "ordine": this.ordine, 
      "token": this.token
    };
  
    this.restApi.createPosizionamentiCoordinate(newcoord, this.token).subscribe((data: {}) => {
      this.loadCoordinates(this.idposizionamento);
      var message = "Salvataggio coordinata avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  updateCoord(){
    var updcoord = {
      "idcoord": this.idcoord,
      "idposizionamento": this.idposizionamento,
      "idtipologia": this.idtipologiacoord,
      "latitude": this.latcoord,
      "latitudegrad": this.latgradcoord, 
      "longitude": this.lngcoord, 
      "longitudegrad": this.lnggradcoord, 
      "iddatum": this.iddatum, 
      "ordine": this.ordine, 
      "token": this.token
    };
  
    this.restApi.updatePosizionamentiCoordinate(updcoord, this.token).subscribe((data: {}) => {
      this.loadCoordinates(this.idposizionamento);
      var message = "Aggiornamento coordinata avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  deleteCoord(){
    var delcoord = {
      "idcoord": this.idcoord,
      "token": this.token
    };
   
    this.restApi.deletePosizionamentiCoordinate(delcoord, this.token).subscribe((data: {}) => {
      this.loadCoordinates(this.idposizionamento);
      var message = "Eliminazione coordinata avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });
  }

  /*########## TIPOLOGIA ##########*/


  newTipologia(){
    this.showtipologianew = false;
    this.showtipologiaedit = false;
    this.showtipologiadel = false;
    this.showtipologiasave = true;
    this.showtipologiaok = false;
    this.showtipologiacancel = false;
    this.showtipologiacombobox = false;
    this.showtipologiatext = true;
    this.typetipologiaoper = "new";
    this.idtipologiaedit = "";
    this.tipologiaedit = "";
  };

  editTipologia(){
    this.restApi.getPosizionamentiTipologieSingle(this.idtipologiacoord,this.token).subscribe((data: {}) => {
      this.ListTipologia = data;
      this.idtipologiaedit = this.ListTipologia[0].idtipologia;
      this.tipologiaedit = this.ListTipologia[0].descrizione;
      this.showtipologianew = false;
      this.showtipologiaedit = false;
      this.showtipologiadel = false;
      this.showtipologiasave = true;
      this.showtipologiaok = false;
      this.showtipologiacancel = false;
      this.showtipologiacombobox = false;
      this.showtipologiatext = true;
      this.typetipologiaoper = "upd";
    });
  }

  deleteTipologia(){
    this.typetipologiaoper = "del";
  }

  saveTipologia(){
    this.showtipologianew = false;
    this.showtipologiaedit = false;
    this.showtipologiadel = false;
    this.showtipologiasave = false;
    this.showtipologiaok = true;
    this.showtipologiacancel = true;
    this.showtipologiacombobox = false;
    this.showtipologiatext = true;
  }

  cancelTipologia(){
    this.showtipologianew = true;
    this.showtipologiaedit = true;
    this.showtipologiadel = true;
    this.showtipologiasave = false;
    this.showtipologiaok = false;
    this.showtipologiacancel = false;
    this.showtipologiacombobox = true;
    this.showtipologiatext = false;
    this.showTipologiaBlock = true;
  }

  showConfirmTipologia(){
    this.showTipologiaBlock = false;
    if(this.typetipologiaoper == "new"){
      this.messagetipologia = "Salvare la nuova tipologia?";
    }else{
      this.messagetipologia = "Aggiornare la tipologia selezionata?";
    }
  }

  saveDataTipologia(){
    if(this.idtipologiaedit == ""){
      //INSERT
      var newtipologia = {
        "descrizione": this.tipologiaedit,
        "token": this.token
      };
    
      this.restApi.createPosizionamentiTipologie(newtipologia, this.token).subscribe((data: {}) => {
        this.ListTipologie = [];
        this.loadTipologie();
        var message = "Salvataggio tipologia avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
        this.cancelTipologia();
      });
    }else{
      //UPDATE
      var updtipologia = {
        "idtipologia": this.idtipologiaedit,
        "descrizione": this.tipologiaedit,
        "token": this.token
      };
    
      this.restApi.updatePosizionamentiTipologie(updtipologia, this.token).subscribe((data: {}) => {
        this.ListTipologie = [];
        this.loadTipologie();
        var message = "Aggiornamento tipologia avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
        this.cancelTipologia();
      });
    }
  }

  /*########## DATUM ##########*/

  newDatum(){
    this.showdatumnew = false;
    this.showdatumedit = false;
    this.showdatumdel = false;
    this.showdatumsave = true;
    this.showdatumok = false;
    this.showdatumcancel = false;  
    this.showdatumcombobox = false;
    this.showdatumtext = true;
    this.iddatumedit = "";
    this.datumedit = "";
  };

  editDatum(){
    this.restApi.getPosizionamentiDatumSingle(this.iddatum,this.token).subscribe((data: {}) => {
      this.ListDatumSingle = data;
      this.iddatumedit = this.ListDatumSingle[0].iddatum;
      this.datumedit = this.ListDatumSingle[0].datum;
      this.showdatumnew = false;
      this.showdatumedit = false;
      this.showdatumdel = false;
      this.showdatumsave = true;
      this.showdatumok = false;
      this.showdatumcancel = false;    
      this.showdatumcombobox = false;
      this.showdatumtext = true;
    });
  }

  deleteDatum(){

  }

  saveDatum(){
    this.showdatumnew = false;
    this.showdatumedit = false;
    this.showdatumdel = false;
    this.showdatumsave = false;
    this.showdatumok = true;
    this.showdatumcancel = true;  
    this.showdatumcombobox = false;
    this.showdatumtext = true;

  }

  cancelDatum(){
    this.showdatumnew = true;
    this.showdatumedit = true;
    this.showdatumdel = true;
    this.showdatumsave = false;
    this.showdatumok = false;
    this.showdatumcancel = false;    
    this.showdatumcombobox = true;
    this.showdatumtext = false;
    this.showDatumBlock = true;
  }

  showConfirmDatum(){
    this.showDatumBlock = false;
    if(this.typedatumoper == "new"){
      this.messagedatum = "Salvare il nuovo datum item?";
    }else{
      this.messagedatum = "Aggiornare il datum item selezionato?";
    }
  }

  saveDataDatum(){
    if(this.iddatumedit == ""){
      //INSERT
    }else{
      //UPDATE
    }
    if(this.iddatumedit == ""){
      //INSERT
      var newdatum = {
        "descrizione": this.tipologiaedit,
        "token": this.token
      };
    
      this.restApi.createPosizionamentiDatum(newdatum, this.token).subscribe((data: {}) => {
        this.ListDatum = [];
        this.loadDatum();
        var message = "Salvataggio datum item avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      });
    }else{
      //UPDATE
      var upddatum = {
        "idtipologia": this.idtipologiaedit,
        "descrizione": this.tipologiaedit,
        "token": this.token
      };
    
      this.restApi.updatePosizionamentiDatum(upddatum, this.token).subscribe((data: {}) => {
        this.ListDatum = [];
        this.loadDatum();
        var message = "Aggiornamento datum item avvenuto con successo.";
        var action = "ok"
        this.openSnackBar(message, action);
      });
    }
  }

  calcolateCoord(type){
    if(type == 'lat'){
      //latitude
      if(this.latgradcoord != undefined && this.latfirstcoord != undefined && this.latsecondcoord != undefined){
        console.log("calculate lat");

        var latgrad = Number(this.latgradcoord);
        var latfrst = Number(this.latfirstcoord);
        var latsecn = Number(this.latsecondcoord);

        var lat = (((latsecn/60 )+ latfrst)/60 )+latgrad;

        if(this.latdir == "2"){
          lat = lat * (-1);
        }

        this.latcoord = lat.toString();
      }
    }else{
      //longitude
      if(this.lnggradcoord != undefined && this.lngfirstcoord != undefined && this.lngsecondcoord != undefined){
        console.log("calculate lng");

        var lnggrad = Number(this.lnggradcoord);
        var lngfrst = Number(this.lngfirstcoord);
        var lngsecn = Number(this.lngsecondcoord);
        
        var lng = (((lngsecn/60 )+ lngfrst)/60 )+lnggrad;

        if(this.lngdir == "2"){
          lng = lng * (-1);
        }

        this.lngcoord = lng.toString();

      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

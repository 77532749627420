import { AfterViewInit, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { Clienti } from 'src/app/shared/clienti';
import { Common } from 'src/app/shared/common';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ChangeDetectorRef } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.css']
})

export class ClientiComponent implements OnInit {
  displayedColumns: string[] = ['ragionesociale', 'indirizzo', 'citta', 'provincia', 'cap', 'partitaiva', 'telefono', 'email', 'attivo', 'action'];
  dataSource: MatTableDataSource<Clienti>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  schema:string;
  action:string;
  Clienti: any = [];
  ClienteSel: any = [];
  Locations: any = [];
  Locationsfilter: any = [];
  keysearch: string;
  status:string;
  destitle:string;
  statocrud:string;
  isVisibleTableSearch: boolean;
  typeSearch:string;
  idcliente:string;
  codicecliente:string;
  ragionesociale:string;
  indirizzo:string;
  idcitta:string;
  citta:string;
  provincia:string;
  cap:string;
  partitaiva:string;
  codicefiscale:string;
  telefono:string;
  cellulare:string;
  fax:string;
  email:string;
  attivo:string;
  statocliente:string;

  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;

  showList:boolean;
  
  selectedItem: any = '';
  inputChanged: any = '';
  common: Common;
  share_message;

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { 

    this.common = new Common(restApi);

  }

  setMessage(href,page) {
    this.share_message.page_href = href;
    this.share_message.page_title = page;
    this.sharedService.sharedMessage = this.share_message;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.root_href = "/";
    this.root_title = "Dashboard";
    this.share_message = this.sharedService.sharedMessage;
    this.setMessage('clienti', 'Gestione Clienti');
    //console.log('init');
    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }

    if (sessionStorage.getItem("authorize") === null) {
          //console.log('auth null');
          this.router.navigate(['/login']);
        }else{
          var auth = sessionStorage.getItem("authorize");
          //console.log('auth = ' + auth);
          if(auth == "0000" || auth == "9999"){
            this.router.navigate(['/login']);
          }

          var user = sessionStorage.getItem("username");
          if(user == "totem"){
            this.router.navigate(['/totem']);
          }
        }
        this.showList = true;
        this.status = "master";
        this.destitle = "Nuovo cliente";
        this.loadClienti();
        this.keysearch = "";
        this.isVisibleTableSearch = false;
        //tipologia di like per la ricerca citta
        //--> l : like %keyword (left)
        //--> r : like keyword% (right)
        //--> a : like %keyword% (all)
        this.typeSearch = "r"; 
        
  }

  loadClienti() {
    this.keysearch = "";
    return this.restApi.getClienti(this.token).subscribe((data: {}) => {
      this.Clienti = data;
      var count = this.Clienti.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.Clienti);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  onKeydownEvent(event: KeyboardEvent, content): void {
        this.loadFilterLocation(this.citta);
  }

  loadFilterLocation(keyword){
    return this.restApi.getSearchCity(keyword,this.typeSearch, this.schema).subscribe((data: {}) => {
      this.Locations = data;
      this.isVisibleTableSearch = true;
    })
  }

  selectLocationItem(idcitta){
    return this.restApi.getCity(idcitta, this.schema).subscribe((data: {}) => {
      var infocity = data;
      this.idcitta = infocity[0].idcitta;
      this.citta = infocity[0].comune;
      this.provincia = infocity[0].provincia;
      this.cap = infocity[0].cap;
      this.isVisibleTableSearch = false;
    })
  }


  clienteLoad(id){
    this.action = "upd";
    return this.restApi.getCliente(id, this.token).subscribe((data: {}) => {
      this.ClienteSel = data;
      this.idcliente =  this.ClienteSel[0].idcliente;
      this.ragionesociale = this.ClienteSel[0].ragionesociale;
      this.indirizzo = this.ClienteSel[0].indirizzo;
      this.idcitta = this.ClienteSel[0].idcitta;
      this.citta = this.ClienteSel[0].citta;
      this.provincia = this.ClienteSel[0].provincia;
      this.cap = this.ClienteSel[0].cap;
      this.partitaiva = this.ClienteSel[0].partitaiva;
      this.telefono = this.ClienteSel[0].telefono;
      this.email = this.ClienteSel[0].mail;
      this.attivo = this.ClienteSel[0].attivo;
      this.statocliente = this.ClienteSel[0].statocliente;
      this.status = "detail";
      this.destitle = "Modifica Cliente";
      this.statocrud = "upd";
      console.log("detail - statuscrud = " + this.statocrud);
    });
  }

  saveCliente(){
    var cliente: any = [];

   if(this.action == "new"){
    cliente = {
      "ragionesociale": this.ragionesociale,
      "partitaiva": this.partitaiva,
      "indirizzo": this.indirizzo,
      "citta": this.citta,
      "cap": this.cap,
      "provincia": this.provincia,
      "telefono": this.telefono,
      "mail": this.email,
      "note": "",
      "attivo": this.attivo
    };

    this.restApi.createCliente(cliente, this.token).subscribe((data: {}) => {
      var message = "Salvataggio avvenuto con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }else{
    cliente = {
      "ragionesociale": this.ragionesociale,
      "partitaiva": this.partitaiva,
      "indirizzo": this.indirizzo,
      "citta": this.citta,
      "cap": this.cap,
      "provincia": this.provincia,
      "telefono": this.telefono,
      "mail": this.email,
      "note": "",
      "attivo": this.attivo,
      "idcliente": this.idcliente      
    };

    this.restApi.updateCliente(cliente, this.token).subscribe((data: {}) => {});
    var message = "Salvataggio avvenuto con successo.";
    var action = "ok"
    this.openSnackBar(message, action);
  }
  }

  searchItemClienti(){
    if(this.keysearch != ""){
      return this.restApi.getRicercaClienti(this.keysearch, this.token).subscribe((data: {}) => {
        this.Clienti = data;
        this.dataSource = new MatTableDataSource(this.Clienti);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.changeDetectorRefs.detectChanges();
      });
    }else{
      var message = "Nessuna keyword di ricerca inserita. Riprovare."
      var action = "ok"
      this.openSnackBar(message, action);
    }
  }

  loadMaster(){
    this.reset();
    this.loadClienti();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.reset();
    this.status = "detail";
    this.action = "new";
    console.log("detail - statuscrud = " + this.statocrud);
  }

  newCliente(){
    this.status = "detail";
    this.action = "new";
    this.codicecliente = "";
    this.ragionesociale = "";
    this.indirizzo = "";
    this.idcitta = "";
    this.partitaiva = "";
    this.codicefiscale = "";
    this.telefono = "";
    this.cellulare = "";
    this.fax = "";
    this.email = "";
    this.attivo = "";
  }

  reset(){
    this.codicecliente = "";
    this.ragionesociale = "";
    this.indirizzo = "";
    this.idcitta = "";
    this.partitaiva = "";
    this.codicefiscale = "";
    this.telefono = "";
    this.cellulare = "";
    this.fax = "";
    this.email = "";
    this.attivo = "";
    this.idcliente = "";
  }

  openModalDelete(content, idcliente) {
    this.idcliente = idcliente;
    this.titleModal = "Elimina Cliente";
    this.bodyModal = "Vuoi eliminare il cliente selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteCliente(id){
    var cliente = {
      idcliente: id
    }; 
    console.log('cliente = ' + JSON.stringify(cliente));
    this.restApi.deleteCliente(cliente, this.token).subscribe((data: {}) => {
      this.loadClienti();
      var message = "Cliente eliminato con successo.";
      var action = "ok"
      this.openSnackBar(message, action);
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { AutocompleteModule } from 'ng2-input-autocomplete';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//MATERIAL COMPONENT
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MatTreeModule} from '@angular/material/tree';

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';

// BOOTSTRAP COMPONENTS

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';

// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';

// DEMO PAGES

// Dashboards

import {AnalyticsComponent} from './Pages/Dashboards/analytics/analytics.component';

// Pages

import {ForgotPasswordBoxedComponent} from './Pages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './Pages/UserPages/login-boxed/login-boxed.component';
import {RegisterBoxedComponent} from './Pages/UserPages/register-boxed/register-boxed.component';

// Elements

import {StandardComponent} from './Pages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './Pages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './Pages/Elements/cards/cards.component';
import {ListGroupsComponent} from './Pages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './Pages/Elements/timeline/timeline.component';
import {IconsComponent} from './Pages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './Pages/Components/accordions/accordions.component';
import {TabsComponent} from './Pages/Components/tabs/tabs.component';
import {CarouselComponent} from './Pages/Components/carousel/carousel.component';
import {ModalsComponent} from './Pages/Components/modals/modals.component';
import {ProgressBarComponent} from './Pages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './Pages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './Pages/Components/tooltips-popovers/tooltips-popovers.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// Tables

import {RegularComponent} from './Pages/Tables/regular/regular.component';
import {TablesMainComponent} from './Pages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './Pages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './Pages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './Pages/Forms/Elements/layout/layout.component';

// Charts

import {ChartjsComponent} from './Pages/Charts/chartjs/chartjs.component';

// Chart.js Examples

import {LineChartComponent} from './Pages/Charts/chartjs/examples/line-chart/line-chart.component';
import {BarChartComponent} from './Pages/Charts/chartjs/examples/bar-chart/bar-chart.component';
import {ScatterChartComponent} from './Pages/Charts/chartjs/examples/scatter-chart/scatter-chart.component';
import {RadarChartComponent} from './Pages/Charts/chartjs/examples/radar-chart/radar-chart.component';
import {PolarAreaChartComponent} from './Pages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component';
import {BubbleChartComponent} from './Pages/Charts/chartjs/examples/bubble-chart/bubble-chart.component';
import {DynamicChartComponent} from './Pages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component';
import {DoughnutChartComponent} from './Pages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component';
import {PieChartComponent} from './Pages/Charts/chartjs/examples/pie-chart/pie-chart.component';
import { StatisticheComponent } from './Pages/statistiche/statistiche.component';
import { UtentiComponent } from './Pages/utenti/utenti.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { TableExpanderComponent } from './Pages/table-expander/table-expander.component';
import {MatSortModule} from '@angular/material/sort';
import { ReportViewerComponent } from './Pages/report-viewer/report-viewer.component';
import { ClientiComponent } from './Pages/clienti/clienti.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import { UserProfileComponent } from './Pages/user-profile/user-profile.component';
import { KeysPipe } from './Pages/pipes/keys.pipe';
import { ToastrModule } from 'ngx-toastr';
import { LinkedMatsnackbarComponent } from './Pages/linked-matsnackbar/linked-matsnackbar.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SharedService } from './shared/shared-service';
import { AnagraficaPiattaformeComponent } from './pages/anagrafica-piattaforme/anagrafica-piattaforme.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PdfViewerModule, PdfViewerComponent } from 'ng2-pdf-viewer';
import { AnagraficaPozziComponent } from './pages/anagrafica-pozzi/anagrafica-pozzi.component';
import { AnagraficaMaineventComponent } from './pages/anagrafica-mainevent/anagrafica-mainevent.component';
import { DownloadPageComponent } from './pages/download-page/download-page.component';
import { AnagraficaKmlComponent } from './pages/anagrafica-kml/anagrafica-kml.component';
import { MainEventsComponent } from './pages/main-events/main-events.component';
import { DataService } from './shared/data.service';
import { NgxCaptureModule } from 'ngx-capture';
import { SearchComponentComponent } from './pages/search-component/search-component.component';
import { CommonFunctionalityComponent } from './pages/common-functionality/common-functionality.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,
    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES 

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,
    
    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DynamicChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,
    StatisticheComponent,
    UtentiComponent,
    TableExpanderComponent,
    ReportViewerComponent,
    ClientiComponent,
    UserProfileComponent,
    KeysPipe,
    LinkedMatsnackbarComponent,
    AnagraficaPiattaformeComponent,
    AnagraficaPozziComponent,
    AnagraficaMaineventComponent,
    DownloadPageComponent,
    AnagraficaKmlComponent,
    MainEventsComponent,
    SearchComponentComponent,
    CommonFunctionalityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    NgxBarcode6Module,

    //Material Component
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatMomentDateModule,
    MatListModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatSnackBarModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    AutocompleteLibModule,
    MatTreeModule,
    MatExpansionModule,
    DragDropModule,
    // Angular Bootstrap Components
    
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2CompleterModule,
    AutocompleteModule,
    DpDatePickerModule,
    NgxSliderModule,
    
    // Charts

    ChartsModule,
    PdfViewerModule 
  ],
  providers: [
    SharedService,
    DataService,
    NgxCaptureModule,
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ConfigActions,
    PdfViewerComponent 
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-linked-matsnackbar',
  templateUrl: './linked-matsnackbar.component.html',
  styleUrls: ['./linked-matsnackbar.component.css']
})
export class LinkedMatsnackbarComponent implements OnInit {

  constructor(private snackBarRef: MatSnackBarRef<LinkedMatsnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any){}
  close(){
    this.snackBarRef.dismiss();
  }

  ngOnInit(): void {

  }

}

import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { RestApiService } from 'src/app/shared/rest-api.service';

@Injectable({
    providedIn: 'root'
  })


export class Common {
    schema:string;
    
    constructor(public restApi: RestApiService) { }
        
    //  getEan(idsettings) {
    //     this.restApi.getSetting(idsettings, this.schema).subscribe((data: {}) => {
    //         var values : any = [];
    //         values = data;
    //         var parameter = values[0].parameters;
    //         console.log('parameter = ' + parameter);      
            
    //         var newProg = parseInt(parameter) + 1;
    //         var strProg = newProg.toString();
            
    //         console.log('parameter = ' + newProg);  
      
    //         var updsetting = {
    //           "idsettings": idsettings,
    //           "property": "progord",
    //           "parameters": newProg.toString()
    //         };
      
    //         //update setting
    //         this.restApi.setSettings(updsetting, this.schema).subscribe((data: {}) => {});

    //         //generate ean13
    //         var par_barcode = strProg;
    //         var par_barcode_new = par_barcode + 1;
            
    //         var code:string = '200' + par_barcode_new.padStart(9, '0');
    //             var weightflag = true;
    //         var sum = 0;
            
    //             // Weight for a digit in the checksum is 3, 1, 3.. starting from the last digit. 
    //             // loop backwards to make the loop length-agnostic. The same basic functionality 
    //         // will work for codes of different lengths.
            
    //         var num:number = 5; 
    //         var i:number; 
                
    //         for (i = code.length - 1; i >= 0; i--)
    //         {
    //             sum += parseInt(code[i]) * (weightflag?3:1);
    //             weightflag = !weightflag;
    //         }

    //         code += (10 - (sum % 10)) % 10;
    //         console.log("ean = " + code);
    //         return code;
    //     });


    // }
    
      
}
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Subscription, Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { ThemeOptions } from 'src/assets/theme-options';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from "jquery";
import { DataService } from 'src/app/shared/data.service';


declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  ListPlatform: any = [];
  ListPosizionamenti: any = [];
  ListKml: any = [];
  sharedatalst:string;
  subscription: Subscription;
  public extraParameter: any;
  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  SERVER:string;
  schema:string;
  dbname:string;
  auth_level:string;
  countdata:string;
  ambiente:string;
  item_visible:boolean;
  id_param:string;
  idpos_param:string;
  yearsel:string;
  nationsel:string;
  wellsel:string;
  iscollapseAnno:boolean;
  iscollapsePozzi:boolean;
  search:string;
  activealltext:string;
  activeall:boolean;
  activepos:string;

  constructor(public globals: ThemeOptions,
     private activatedRoute: ActivatedRoute, 
     private router:Router,
     public restApi: RestApiService,
     private _snackBar: MatSnackBar,
     private data: DataService) {

  }


  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    console.log('check click burger!');
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    console.log('this.globals.toggleSidebar = ' + this.globals.toggleSidebar);
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
    this.item_visible = true;
  }

  sidebarOut(){
    if(this.globals.toggleSidebar == true){
      this.item_visible = false;
    }
  }

  getPosizionamentiList(idcliente, search){
    var idutente = sessionStorage.getItem("idutente");

    //console.log('idutente = ' + idcliente);
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var stato= "1";
    //console.log('=> getPiattaformeUtente');
    if(search == null || search == ""){
      this.restApi.getPosizionamentiList(idcliente).subscribe((data: {}) => {
        this.ListPosizionamenti = [];
        this.ListPosizionamenti = data;
        //sessionStorage.setItem('ListPosizionamenti', JSON.stringify(this.ListPosizionamenti));
        //console.log('platform: ' + JSON.stringify(this.ListPosizionamenti));
      });    
    }else{
      var len=search.length;
      if(len >= 3){
        this.restApi.getPosizionamentiSearch(idcliente, search,token).subscribe((data: {}) => {
          this.ListPosizionamenti = [];
          this.ListPosizionamenti = data;
          //sessionStorage.setItem('ListPosizionamenti', JSON.stringify(this.ListPosizionamenti));
          //console.log('platform: ' + JSON.stringify(this.ListPosizionamenti));
        });   
      }
    }
  }

  getPiattaformeUtente(){
    var idutente = sessionStorage.getItem("idutente");
    //sessionStorage.setItem("statusapp", "calltoaction");
    console.log('idutente = ' + idutente);
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var stato= "1";
    console.log('=> getPiattaformeUtente');
    return this.restApi.getPiattaformeAttive(idutente, stato, token).subscribe((data: {}) => {
      this.ListPlatform = data;
      sessionStorage.setItem('platforms', JSON.stringify(this.ListPlatform));
      //console.log('platform: ' + JSON.stringify(this.ListPlatform));
    });
  }


  ngOnInit() {

    this.globals.toggleSidebar = false;
    this.item_visible = true;
    var idcliente = sessionStorage.getItem("idcliente");
    this.getPosizionamentiList(idcliente, "");
    this.subscription = this.data.currentMessage.subscribe(sharedatalst => this.sharedatalst = sharedatalst);
    this.iscollapseAnno = false;
    this.iscollapsePozzi = false;
    this.activealltext = "view all";
    this.activeall = false;
    this.activepos = "false";
    this.subscription = this.data.currentMessage.subscribe(sharedatalst => this.sharedatalst = sharedatalst);
    $('#search').prop('disabled', false);
    var arr: any = [];
    arr = this.sharedatalst.split('|');
    var len = arr.length;
    var type = arr[0].toString();

    // switch(type){
    //   case "POS":
    //     break;
    //   case "RIG":
    //     break;
    // case "POS":
    //   break;
    // }


    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    if (sessionStorage.getItem("schema") === null) {
      this.schema = "geosbossnavyweb";
    }else{
      this.schema = sessionStorage.getItem("schema");
    }

    if (sessionStorage.getItem("user") !== null) {
      console.log('user logged');
      var sessionuser = sessionStorage.getItem("user");
      var dbname = sessionStorage.getItem("schema");
      this.dbname = dbname;
    }

    if (sessionStorage.getItem("ipaddress") !== null) {
      var ipaddress = sessionStorage.getItem("ipaddress");
      var server = "http://" + ipaddress;
      this.SERVER = server;
    }

    if (sessionStorage.getItem("auth_level") !== null) {
      var auth_level = sessionStorage.getItem("auth_level");
      this.auth_level = auth_level;
      this.SERVER = server;
    }
    
    this.getPiattaformeUtente();

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
    
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var idutente = sessionStorage.getItem("idutente");

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    console.log('baseurl = ' + baseUrl);

    if(baseUrl.includes("beta")){
      this.ambiente = "2";
    }else{
      this.ambiente = "1";
    }
    
    this.restApi.getKml(idutente, this.ambiente, token).subscribe((data: {}) => {
      this.ListKml = data;
      this.countdata = this.ListKml.length;
      console.log('countdata = ' + this.countdata);
    });

  }

  navigateToMap(id,lat,lng){
    //sessionStorage.setItem("statusapp", "calltoaction");
    //this.router.navigate(['platform']);
    var latlng = "RIG|" + id + "|" + lat + "|" + lng; 
    this.data.changeMessage(latlng);

    // this.router.navigate( [''], { queryParams: { id: latlng}})
    // .then(() => {
    //   window.location.reload();
    // });
  }

  changeKmlStatus(id,status){
    //this.router.navigate(['platform']);
    sessionStorage.setItem("statusapp", "calltoaction");
    if(status == "1"){
      status = "0";
    }else{
      status = "1";
    }

    
    var datastore = {
      "idkmz_user": id,
      "attivo": status
    };

    this.restApi.updateStatusKmlUtente(datastore).subscribe((data: {}) => {
      var idkml = "KML|" + id; 
      this.data.changeMessage(idkml);
      var idutente = sessionStorage.getItem("idutente");
      var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
      
      this.restApi.getKml(idutente, this.ambiente, token).subscribe((data: {}) => {
        this.ListKml = data;
        this.countdata = this.ListKml.length;
        console.log('countdata = ' + this.countdata);
      });
      //this.router.navigate( [''], { queryParams: { checkkml: id}})
      //.then(() => {
      //  window.location.reload();
      //});
    });

  }  

  openlargemodal(idposizionamento, nation, anno){
    //var year = this.yearsel;
    ///var nation = this.nationsel;
    //if(this.activeall == false){
      sessionStorage.setItem("statusapp", "calltoaction");
    if(this.activepos != null || this.activepos != undefined || this.activepos != ""){
      
      this.data.changeMessage("POS|" + anno + "|" + nation + "|" + idposizionamento + "|" + this.activepos);
      
      // this.router.navigate( [''], { queryParams: {year:anno, nation:nation, idpos: idposizionamento, activepos: this.activepos}})
      // .then(() => {
      //   window.location.reload();
      // });
    }else{
      this.data.changeMessage("POS|" + anno + "|" + nation + "|" + idposizionamento);

      // this.router.navigate( [''], { queryParams: {year:anno, nation:nation, idpos: idposizionamento}})
      // .then(() => {
      //   window.location.reload();
      // });
    }
    //}else{
      //this.router.navigate( [''], { queryParams: {year:year, nation:nation, idpos: idpozizionamento, activepos:true}})
      //.then(() => {
      //  window.location.reload();
      //});
    //}
  }

  yearclick(id){
    console.log('yearsel = ' + id);
    this.yearsel = id;
    //sessionStorage.setItem("statusapp", "calltoaction");
    // this.ListPosizionamenti.forEach((currentValue, index) => {
    //   currentValue.anni.forEach((currentValue1, index) => {
    //       var anno = currentValue1.anno;
    //       if(anno == id){
    //         console.log('anno sel = ' + anno + ' index = ' + index);
    //         $("#collapseOne"+index).removeClass( "card-body p-0 m-0 collapse" ).addClass( "card-body p-0 m-0 collapse show" );
    //       }
    //   });
    // });

  }

  nationclick(id){
    console.log('nationsel = ' + id);
    this.nationsel = id;
  }

  onSearchChange(searchValue: string): void {  
    console.log(searchValue);
    //sessionStorage.setItem("statusapp", "calltoaction");
    var idcliente = sessionStorage.getItem("idcliente");
    this.getPosizionamentiList(idcliente, searchValue)
  }

  showAllPosiz(){
    this.activealltext = "hide all";
    this.activeall = true;
    this.activepos = "true";
    //sessionStorage.setItem("statusapp", "calltoaction");
    this.data.changeMessage("ACTIVEPOS|" + this.activeall);

    // this.router.navigate( [''], { queryParams: { activepos: par}})
    // .then(() => {
    //   window.location.reload();
    // });
  }

  hideAllPosiz(){
    this.activealltext = "view all";
    this.activeall = false;
    this.activepos = "false";
    sessionStorage.setItem("statusapp", "calltoaction");
    this.data.changeMessage("ACTIVEPOS|" + this.activepos);
    this.router.navigate( [''], {})
    .then(() => {
      window.location.reload();
    });
  }


  test(){
    $('#modalsearch').css("display","block");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  newMessage() {
    this.data.changeMessage("Hello from Sibling")
  }
  
}

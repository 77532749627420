<nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark fixed-top p-0">
  <a href="#" class="navbar-brand ml-2" style="font-family: 'Calibri Light',merriweather sans,sans-serif; font-weight: bold;"><img src="../../../../assets/images/logo_sboss_45.png"  />  Remote Control System</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    </ul>
    <ul class="navbar-nav">
      <!-- <li class="nav-item active">
        <a class="nav-link" routerLink="#">
          <i class="fa fa-home"></i>
          Dashboard
          <span class="sr-only">(current)</span>
          </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/ricerca-titoli">
          <i class="fa fa-line-chart"></i>
          Platforms
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="#">
          <i class="fa fa-file-code-o"></i>
          Gestioni
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="#">
          <i class="fa fa-file-powerpoint-o"></i>
          PPA
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="#">
          <i class="fa fa-check-circle-o"></i>
          Validazioni
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="#">
          <i class="fa fa-print"></i>
          Report
        </a>
      </li> -->
      <div class="nav-item dropdown" style="width:250px;">
        <a data-toggle="dropdown" class="nav-item nav-link dropdown-toggle user-action pull-right" style="color:#fff;"><img src="{{logo}}" class="avatar" alt="Avatar"> {{user}} <b class="caret"></b></a>
        <div class="dropdown-menu">
            <!-- <a href="#" class="dropdown-item"><i class="fa fa-user-o"></i> Profile</a>
            <a href="#" class="dropdown-item"><i class="fa fa-calendar-o"></i> Calendar</a>
            <a href="#" class="dropdown-item"><i class="fa fa-sliders"></i> Settings</a> -->
            <div class="divider dropdown-divider"></div>
            <a href="#" class="dropdown-item" (click)="logout()"><i class="material-icons">&#xE8AC;</i> Logout</a>
        </div>
    </div>
    </ul>
  </div>
</nav>
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';

// Dashboards

import {AnalyticsComponent} from './Pages/Dashboards/analytics/analytics.component';

//Pagine Gestionale

import {ClientiComponent} from './Pages/clienti/clienti.component';
import {StatisticheComponent} from './Pages/statistiche/statistiche.component';


// Pages

import {ForgotPasswordBoxedComponent} from './Pages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import {LoginBoxedComponent} from './Pages/UserPages/login-boxed/login-boxed.component';
import {RegisterBoxedComponent} from './Pages/UserPages/register-boxed/register-boxed.component';

// Elements

import {StandardComponent} from './Pages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './Pages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './Pages/Elements/cards/cards.component';
import {ListGroupsComponent} from './Pages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './Pages/Elements/timeline/timeline.component';
import {IconsComponent} from './Pages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './Pages/Components/accordions/accordions.component';
import {TabsComponent} from './Pages/Components/tabs/tabs.component';
import {CarouselComponent} from './Pages/Components/carousel/carousel.component';
import {ModalsComponent} from './Pages/Components/modals/modals.component';
import {ProgressBarComponent} from './Pages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './Pages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './Pages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {TablesMainComponent} from './Pages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './Pages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './Pages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './Pages/Forms/Elements/layout/layout.component';

// Charts

import {ChartjsComponent} from './Pages/Charts/chartjs/chartjs.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { TableExpanderComponent } from './Pages/table-expander/table-expander.component';
import { ReportViewerComponent } from './Pages/report-viewer/report-viewer.component';
import { UtentiComponent } from './Pages/utenti/utenti.component';
import { UserProfileComponent } from './Pages/user-profile/user-profile.component';
import { AnagraficaPiattaformeComponent } from './pages/anagrafica-piattaforme/anagrafica-piattaforme.component';
import { AnagraficaPozziComponent } from './pages/anagrafica-pozzi/anagrafica-pozzi.component';
import { MainEvent } from './shared/mainevent';
import { AnagraficaMaineventComponent } from './pages/anagrafica-mainevent/anagrafica-mainevent.component';
import { DownloadPageComponent } from './pages/download-page/download-page.component';
import { AnagraficaKmlComponent } from './pages/anagrafica-kml/anagrafica-kml.component';
import { MainEventsComponent } from './pages/main-events/main-events.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { SearchComponentComponent } from './pages/search-component/search-component.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [

      // Dashboads
      {path: '', component: AnalyticsComponent, data: {extraParameter: 'dashboardsMenu'}},
      // {path: 'platform:id', component: AnalyticsComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Preventivi
      {path: 'tablexpander', component: TableExpanderComponent, data: {extraParameter: 'dashboardsMenu'}},
      
      //Clienti
      {path: 'clienti', component: ClientiComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Utenti
      {path: 'utenti', component: UtentiComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Profilo utente
      {path: 'profilo-utente', component: UserProfileComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Piattaforme 
      {path: 'piattaforme', component: AnagraficaPiattaformeComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Pozzi 
      {path: 'pozzi', component: AnagraficaPozziComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Gestione Main Event
      {path: 'mainevents', component: MainEventsComponent, data: {extraParameter: 'dashboardsMenu'}},

      //Anagrafica Main Event
      {path: 'anagrafica-mainevents', component: AnagraficaMaineventComponent, data: {extraParameter: 'dashboardsMenu'}},

      //download
      {path: 'downloads', component: DownloadPageComponent, data: {extraParameter: 'dashboardsMenu'}},

      //download
      {path: 'anagraficakml', component: AnagraficaKmlComponent, data: {extraParameter: 'dashboardsMenu'}},
      //download
      {path: 'search', component: SearchComponentComponent, data: {extraParameter: 'dashboardsMenu'}},
      

      // Elements
      {path: 'elements/buttons-standard', component: StandardComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/dropdowns', component: DropdownsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/icons', component: IconsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/cards', component: CardsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/list-group', component: ListGroupsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/timeline', component: TimelineComponent, data: {extraParameter: 'elementsMenu'}},

      // Components

      {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/accordions', component: AccordionsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/modals', component: ModalsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/progress-bar', component: ProgressBarComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/carousel', component: CarouselComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/pagination', component: PaginationComponent, data: {extraParameter: 'componentsMenu'}},

      // Tables

      {path: 'tables/bootstrap', component: TablesMainComponent, data: {extraParameter: 'tablesMenu'}},

      // Widgets

      {path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: {extraParameter: 'pagesMenu3'}},

      // Forms Elements

      {path: 'forms/controls', component: ControlsComponent, data: {extraParameter: 'formElementsMenu'}},
      {path: 'forms/layouts', component: LayoutComponent, data: {extraParameter: 'formElementsMenu'}},

      // Charts

      {path: 'charts/chartjs', component: ChartjsComponent, data: {extraParameter: ''}},

    ]

  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages

      {path: 'login', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'pages/register-boxed', component: RegisterBoxedComponent, data: {extraParameter: ''}},
      {path: 'pages/forgot-password-boxed', component: ForgotPasswordBoxedComponent, data: {extraParameter: ''}},

    ]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

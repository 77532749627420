import { ChangeDetectorRef, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Downloads } from 'src/app/shared/downloads';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.css']
})
export class DownloadPageComponent implements OnInit {

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  showList:boolean = true;
  status: string = 'master';
  displayedColumns: string[] = ['description', 'link'];
  dataSource: MatTableDataSource<Downloads>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  DownloadsList: any = [];
  DownloadSingle: any = [];
  keysearch: string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  share_message;

  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";

  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {

    this.root_href = "/";
    this.root_title = "Dashboard";

    this.loadDownloadList();
  }

  loadDownloadList(){
    
    return this.restApi.getDownloadsList(this.token).subscribe((data: {}) => {
      this.DownloadsList = data;
      var count = this.DownloadsList.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.DownloadsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

}

import {Component, HostBinding, ChangeDetectionStrategy, OnInit} from '@angular/core';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import { ThemeOptions } from 'src/assets/theme-options';
import { Router } from '@angular/router';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ChangeDetectorRef } from '@angular/core';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent  implements OnInit {
  public user: string = "";
  public logo: string = "";
  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  share_message;
  faEllipsisV = faEllipsisV;

  constructor(public globals: ThemeOptions, 
              private router: Router, 
              private changeDetectorRefs: ChangeDetectorRef,
              private sharedService: SharedService) {
  }
  
  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select('config') public config$: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  setMessage(href,page) {
    this.share_message.page_href = href;
    this.share_message.page_title = page;
    this.sharedService.sharedMessage = this.share_message;
  }

  ngOnInit() {
    this.user = sessionStorage.getItem('user');
    var logo = sessionStorage.getItem('logo');

    if(logo != ""){
      this.logo = "https://rcs.explorermarinesboss.com/assets/images/" + logo; 
    }else{
      this.logo = "https://rcs.explorermarinesboss.com/assets/images/user.png"; 
    }
    
    this.share_message = this.sharedService.sharedMessage;
    this.page_href = this.router.url;
    this.root_href = "/";
    this.root_title = "Dashboard";
    var page = this.page_href.split('/').pop();
    var dettaglio = 0;
    if(!isNaN(Number(page))){
      //console.log(page + " is a number <br/>");
      var splitPage = this.page_href.split('/');
      page = splitPage[1];
      dettaglio = 1;
    }

    this.page_href = this.share_message.href;
    this.page_title = this.share_message.title;

    // page = this.message.page;//this.page_href.split('/').pop();
    // console.log('page = ' + page);
    // switch (page) {
    //   case 'magazzino':
    //     this.page_href ="magazzino";
    //     this.page_title = 'Gestione Magazzino';
    //     if(dettaglio == 1){

    //     }
    //     break;
    //   case 'vendite':
    //   case 'vendita':        
    //     this.page_href ="vendite";
    //     this.page_title = 'Gestione Vendite';
    //     break;
    //   case 'acquisti':
    //   case 'acquisto':        
    //     this.page_href ="acquisti";
    //     this.page_title = 'Gestione Acquisti';  
    //     break;
    //   case 'preventivi':
    //   case 'preventivo':
    //     this.page_href ="preventivi";
    //     this.page_title = 'Gestione Preventivi';  
    //     break;
    //   case 'utenti':
    //     this.page_href ="utenti";
    //     this.page_title = 'Gestione Utenti';  
    //     break;        
    //   case 'clienti':
    //     this.page_href ="clienti";
    //     this.page_title = 'Gestione Clienti';  
    //     break;
    //   case 'fornitori':
    //     this.page_href ="fornitori";
    //     this.page_title = 'Gestione Fornitori';  
    //     break;
    //   case 'prontuario':
    //     this.page_href ="prontuario";
    //     this.page_title = 'Gestione Prontuario';  
    //     break; 
    //   case 'anagrafiche':
    //     this.page_href ="anagrafiche";
    //     this.page_title = 'Gestione Anagrafiche';  
    //     break;      
    //   case 'listino':
    //     this.page_href ="listino";
    //     this.page_title = 'Gestione Listino';  
    //     break;
    //   case 'ddt-acquisto':
    //     this.page_title = 'DDT Acquisto';
    //     this.page_href ="ddt-acquisto";
    //     break; 
    //   case 'ddt-vendita':
    //     this.page_title = 'DDT Vendita';
    //     this.page_href ="ddt-vendita";
    //     break;
    //   case 'lavorazioni':
    //     this.page_title = 'Lavorazioni';
    //     this.page_href ="lavorazioni";
    //     break;         
    //   case 'info-geosbossnavyweb':
    //     this.page_title = 'Info geosbossnavyweb';
    //     this.page_href ="info-geosbossnavyweb";
    //     break;     
    //   case 'profilo-utente':
    //     this.page_title = 'Profilo Utente';
    //     this.page_href ="profilo-utente";
    //     break;      
    //   case 'dashboard':
    //     this.page_title = 'Dashboard';
    //     this.page_href ="/";
    //     break;
    //   default:
    //     this.page_title = 'Dashboard';
    //     this.page_href ="/";
    //     break;
    // }
  }

  logout(){
    sessionStorage.setItem("authorize",  "0000");
    this.router.navigate(['/login']);
  }

  ngOnChanges(){
    this.share_message = this.sharedService.sharedMessage;
    this.page_href = this.router.url;
    this.root_href = "/";
    this.root_title = "Dashboard";
    var page = this.page_href.split('/').pop();
    page = this.page_href.split('/').pop();

    this.page_href = this.share_message.href;
    this.page_title = this.share_message.title;

    // console.log('page = ' + page);
    // switch (page) {
    //   case 'magazzino':
    //     this.page_href ="magazzino";
    //     this.page_title = 'Gestione Magazzino';
    //     break;
    //   case 'vendite':
    //   case 'vendita':
    //     this.page_href ="vendite";
    //     this.page_title = 'Gestione Vendite';
    //     break;
    //   case 'acquisti':
    //   case 'acquisto':
    //     this.page_href ="acquisti";
    //     this.page_title = 'Gestione Acquisti';  
    //     break;
    //   case 'preventivi':
    //   case 'preventivo':
    //     this.page_href ="preventivi";
    //     this.page_title = 'Gestione Preventivi';  
    //     break;
    //   case 'utenti':
    //       this.page_href ="utenti";
    //       this.page_title = 'Gestione Utenti';  
    //       break;
    //   case 'clienti':
    //     this.page_href ="clienti";
    //     this.page_title = 'Gestione Clienti';  
    //     break;
    //   case 'fornitori':
    //     this.page_href ="fornitori";
    //     this.page_title = 'Gestione Fornitori';  
    //     break;
    //   case 'prontuario':
    //     this.page_href ="prontuario";
    //     this.page_title = 'Gestione Prontuario';  
    //     break;        
    //   case 'anagrafiche':
    //     this.page_href ="anagrafiche";
    //     this.page_title = 'Gestione Anagrafiche';  
    //     break;     
    //   case 'listino':
    //     this.page_href ="listino";
    //     this.page_title = 'Gestione Listino';  
    //     break; 
    //   case 'ddt-acquisto':
    //     this.page_title = 'DDT Acquisto';
    //     this.page_href ="ddt-acquisto";
    //     break; 
    //   case 'ddt-vendita':
    //     this.page_title = 'DDT Vendita';
    //     this.page_href ="ddt-vendita";
    //     break;   
    //   case 'lavorazioni':
    //     this.page_title = 'Lavorazioni';
    //     this.page_href ="lavorazioni";
    //     break;
    //   case 'info-geosbossnavyweb':
    //     this.page_title = 'Info geosbossnavyweb';
    //     this.page_href ="info-geosbossnavyweb";
    //     break;     
    //   case 'profilo-utente':
    //     this.page_title = 'Profilo Utente';
    //     this.page_href ="profilo-utente";
    //     break;  
    //   case 'dashboard':
    //     this.page_title = 'Dashboard';
    //     this.page_href ="/";
    //     break;
    //   default:
    //     this.page_title = 'Dashboard';
    //     this.page_href ="/";
    //   break;
    // }
  }

}

<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
     <div class="form-inline" *ngIf="status=='master'">
        <label class="sr-only" for="inlineFormInputName2">Name</label>
        <input type="text" class="form-control form-control-sm mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ricerca Anagrafica" [(ngModel)]="keysearch">
      
        <label class="sr-only" for="inlineFormInputGroupUsername2">Tipologia</label>
        <select class="custom-select custom-select-sm mb-2 mr-sm-2" id="inlineFormCustomSelect">
          <option selected>Seleziona tipologia...</option>
          <option value="1">Rig Positioning</option>
          <option value="2">Supervisor</option>
        </select>
      
        <button type="button" class="btn btn-primary btn-sm mb-2 mr-1" (click)="searchMED()">Cerca</button>
        <button type="button" class="btn btn-warning btn-sm mb-2" (click)="loadMED()">Reset</button>
        <button type="button" class="btn btn-success btn-sm ml-2 mb-2" (click)="newWell()"><fa-icon class="mr-2" [icon]="faPlus"></fa-icon> Nuovo Posizionamento</button>
        <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
      </div>
  
      <div class="row" *ngIf="status=='detail' || status=='detailfile'">
        <div class="col-md-6 mt-2">
          <div class="form-inline">
            <div class="form-group">
              <h5 class="card-title"><strong>{{destitle}}<span class="badge badge-secondary" *ngIf="statuswell == '0'">{{statuswelldesc}}</span><span class="badge badge-danger" *ngIf="statuswell == '2'">{{statuswelldesc}}</span><span class="badge badge-success" *ngIf="statuswell == '1'">{{statuswelldesc}}</span></strong></h5>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-1 mb-2">
          <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Wells list</button>
          <button class="btn btn-success active pull-right ml-2" (click)="activeWell('0')" *ngIf="wellactive == '1'"><i class="fa fa-fw" aria-hidden="true" title="chevron-circle-down"></i> Well Show</button>
          <button class="btn btn-danger active pull-right ml-2" (click)="activeWell('1')" *ngIf="wellactive == '0'"><i class="fa fa-fw" aria-hidden="true" title="times-circle"></i> Well Hide</button>
          <button class="btn btn-danger active pull-right ml-2" (click)="openModalClose(content)" *ngIf="statuswell != '2'"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Well closure</button>
          <button class="btn btn-primary active pull-right ml-2" (click)="openModalSave(content)"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Save</button>
          <button class="btn btn-primary active pull-right" (click)="openModalNew(content)"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> New</button>
        </div>
      </div>
  
    </div>
      <div class="form-row ml-4 mr-4 mb-2" *ngIf="status=='detail' || status=='detailfile'">
        <div class="col-md-12 mt-2 mb-0 pb-0">
          <button class="btn btn-primary active ml-2" (click)="loadDetailTab('0')" style="border-bottom-left-radius:0px 0px; border-bottom-right-radius:0px 0px;"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i>General</button>
          <button class="btn btn-primary active ml-2" (click)="loadDetailTab('1')" style="border-bottom-left-radius:0px 0px; border-bottom-right-radius:0px 0px;"><i class="fa fa-fw" aria-hidden="true" title="chevron-circle-down"></i>Documents</button>
          <hr class="m-0"/>
        </div>
      </div>
      <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Project Code</span></div>
            <input type="text" class="form-control" id="codiceprogetto" name="codiceprogetto" [(ngModel)]="codiceprogetto"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Well Code</span></div>
            <input type="text" class="form-control" id="codicepozzo" name="codicepozzo" [(ngModel)]="codicepozzo"/>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Well Name</span></div>
            <input type="text" class="form-control" id="nomepozzo" name="nomepozzo" [(ngModel)]="nomepozzo"/>
          </div>
        </div>
      </div>
      <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text warning p-1">Start Date</span></div>
            <!-- <input type="text" class="form-control" id="datainizioposizionamento" name="datainizioposizionamento" [(ngModel)]="datainizioposizionamento"/> -->
            <dp-date-picker theme="dp-material" id="datainizioposizionamento" name="datainizioposizionamento"
            [config]="config" [(ngModel)]="datainizioposizionamento"
            required="true" style="margin:0rem;" placeholder="Start date">
          </dp-date-picker>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text warning">End Date</span></div>
            <!-- <input type="text" class="form-control" id="datafineposizionamento" name="datafineposizionamento" [(ngModel)]="datafineposizionamento"/> -->
            <dp-date-picker theme="dp-material" id="datafineposizionamento" name="datafineposizionamento"
            [config]="config" [(ngModel)]="datafineposizionamento"
            required="true" style="margin:0rem;" placeholder="End date">
          </dp-date-picker>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text warning">Year</span></div>
            <input type="text" class="form-control" id="anno" name="anno" [(ngModel)]="anno"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Nation</span></div>
            <select class="custom-select custom-select-sm" id="idnazione" name="idnazione" [(ngModel)]="idnazione" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of Nations" [value]="obj.id">{{obj.naz_eng}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Activity</span></div>
            <select class="custom-select custom-select-sm" id="idattivita" name="idattivita" [(ngModel)]="idattivita" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">select option...</option>
              <option value="1">Rig Positioning</option>
              <option value="2">Supervisor</option>
            </select>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">RIG</span></div>
            <select class="custom-select custom-select-sm" id="idcliente" name="idcliente" [(ngModel)]="idpiattaforma" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of PiattaformeList" [value]="obj.idpiattaforma">{{obj.denominazione}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Customer</span></div>
            <select class="custom-select custom-select-sm" id="idcliente" name="idcliente" [(ngModel)]="idcliente" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of Customers" [value]="obj.idcliente">{{obj.ragionesociale}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card  ml-4 mr-4 mb-2" *ngIf="status=='detail'" style="height: calc(100vh - 60px);">
        <div class="card-header pl-2 pt-1 pb-1">
          <span style="position:absolute; font-size: 12pt; font-weight: bold; margin-top: 5px;">Coordinates</span>
          <button class="btn btn-primary active pull-right btn-sm" (click)="openModalNewCoord(content)"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> New</button>
        </div>
        <div class="card-body p-0">
          <div class="row col-12 pl-2 pt-1 pb-1">
          <div class="card col-12 ml-3 mr-1 mt-3 mb-2">
            <div class="card-header pl-2 pt-1 pb-1">
              List Coordinates
            </div>
            <div class="card-body p-0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tipologia</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Lat</th>
                    <th scope="col">Lat Gradi</th>
                    <th scope="col">Lng</th>
                    <th scope="col">Lng Gradi</th>
                    <th scope="col">Ordine</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let obj of ListCoordinates;">
                    <th scope="row">{{obj.ordine}}</th>
                    <td>{{obj.tipologia}}</td>
                    <td>{{obj.datum}}</td>
                    <td>{{obj.latitude}}</td>
                    <td>{{obj.latitudegrad}}</td>
                    <td>{{obj.longitude}}</td>
                    <td>{{obj.longitudegrad}}</td>
                    <td>{{obj.ordine}}</td>
                    <td class="p-0 pt-1 pl-1">            
                      <div role="group" class="btn-group-sm btn-group">
                      <button class="btn-shadow btn btn-success btn-sm p-0 m-0" (click)="openModalLoadCoord(content, obj.idcoord)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica piattaforma"></i></button>
                      <button class="btn-shadow btn btn-danger btn-sm p-0 m-0" (click)="openModalDeleteCoord(content, obj.idcoord)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella piattaforma"></i></button>
                    </div>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="card  ml-4 mr-4" *ngIf="status=='detailfile'" style="height: calc(100vh - 60px);">
        <div class="card-header">
          <div class="row mb-2">
            <div class="col-md-7">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend"><span class="input-group-text primary">Descrizione</span></div>
                <input type="text" class="form-control" id="attachmentdesc" name="attachmentdesc" [(ngModel)]="attachmentdesc" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">Tipologia file</label>
                </div>
                <select class="form-control" name="tipologiafile" id="tipologiafile" placeholder="Tipologia file" [(ngModel)]="tipologiafile">
                    <option value="">select...</option>
                    <option *ngFor="let obj of ListTipFile" [value]="obj.idallegato_tipo">{{obj.descrizione}}</option>
                    </select>
                </div>  
            </div> 
          </div>
           <div class="row">
            <div class="col-12">
              <!-- <div class="input-group pt-1 pb-0 m-0" *ngIf="showupload == false">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-primary text-white">Document</span>
                </div>
                <input type="text" class="form-control" id="image_rig" name="image_rig" [(ngModel)]="image_rig"/>          
                <div class="input-group-append">
                    <button id="upload" class="btn btn-outline-primary" [disabled]="!status" (click)="showUploadForm()">Upload image</button>
                </div>
              </div>  -->
              <div>
                <div *ngIf="uploadResponse && uploadResponse.status === 'error'">
                  {{ uploadResponse.message }}
                </div>
                <div *ngIf="uploadResponse && uploadResponse.status === 'success'">
                  <img [src]='uploadResponse.url' />
                </div>
              
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
              
                  <!-- <input type="file" name="avatar" (change)="onFileSelect($event)" /> -->
                  <!-- <button type="submit">Upload</button> -->
                  <div class="input-group pt-1 pb-0 m-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-primary text-white">Upload Document</span>
                        <!-- <span class="input-group-text"  *ngIf="progress > 0">{{progress}}% {{message}}</span> -->
                    </div>
                    <input type="file" id="avatar" formControlName="avatar" name="avatar" #file class="form-control" (change)="onFileSelect($event)" accept="image/*,application/pdf"/> <br /> 
                    <div class="input-group-append">
                        <button id="upload" class="btn btn-outline-primary" [disabled]="!status"  type="submit">Upload</button>
                        <input type="hidden" id="annohidden" formControlName="annohidden" name="annohidden" [(ngModel)]="anno"/>
                    </div>
                  </div>  
                </form>
              </div>
            <!-- </div> -->
            </div>
      
          </div>
        </div> 
        <div class="card-body">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Descrizione</th>
              <th scope="col">Tipologia</th>
              <th scope="col" style="text-align:center;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of ListAttachments;">
              <td>{{obj.descrizione}}</td>
              <td>{{obj.tipologia}}</td>
              <td style="text-align:center;" ><span *ngIf="obj.pathfile"><button class="btn btn-danger btn-sm active" (click)="openModalDeleteAttach(content, obj.idposizionamento, obj.idallegato, obj.pathfile)"><i class="fa fa-fw fa-lg" aria-hidden="true" title="delete document"></i></button></span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      <div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
        <h4 class="alert-heading">Elenco posizionamenti vuoto</h4>
        <p>Nessun utenti presente. Creare un nuovo posizionamento e riporvare.</p>
      </div> 
     <div class="mat-elevation-z0" *ngIf="status=='master' && showList == true">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
    
        <!-- ID Column -->
        <ng-container matColumnDef="codiceprogetto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Progetto </th>
          <td mat-cell *matCellDef="let row"> {{row.codiceprogetto}} </td>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="codicepozzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice Pozzo </th>
          <td mat-cell *matCellDef="let row"> {{row.codicepozzo}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nomepozzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Denominazione </th>
          <td mat-cell *matCellDef="let row"> {{row.nomepozzo}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="anno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Anno </th>
            <td mat-cell *matCellDef="let row"> {{row.anno}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="nazione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazione </th>
            <td mat-cell *matCellDef="let row"> {{row.nazione}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row"> {{row.cliente}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Action </th>
          <td mat-cell *matCellDef="let row" class="text-right"> 
            <div role="group" class="btn-group-sm btn-group">
              <h5 class="m-2"><span class="badge badge-success" *ngIf="row.active == 1">Active</span></h5>
              <h5 class="m-2"><span class="badge badge-danger" *ngIf="row.active == 0">Inactive</span></h5>
              <button class="btn-shadow btn btn-success btn-sm mr-1 mt-1 mb-1" (click)="pozzoLoad(row.idposizionamento)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica pozzo"></i></button>
              <button class="btn-shadow btn btn-danger btn-sm mt-1 mb-1" (click)="openModalDelete(content, row.idposizionamento)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella pozzo"></i></button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="d-block p-4 text-center card-footer">
    </div>
  </div>
  
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="this.idmodal == 'normal'">{{bodyModal}}</p>
      <table class="table table-striped table-hover" *ngIf="this.idmodal == 'selplatform'">
        <thead>
          <tr>
            <th scope="col">Platform</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Heading</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Piattaforme; let i = index">
            <td class="pt-3">{{item.denominazione}}</td>
            <td class="pt-3">{{item.latitudine}}</td>
            <td class="pt-3">{{item.longitudine}}</td>
            <td class="pt-3">{{item.heading}}</td>
            <td>
              <button class="btn-shadow btn btn-success btn-sm" (click)="selPlatformJoin(content, item.idpiattaforma)"><i aria-hidden="true" title="check" class="fa fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Seleziona piattaforma"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row" *ngIf="this.idmodal == 'newcoord'">
        <div class="col-12">
          <div class="input-group input-group-sm mb-3" *ngIf="showTipologiaBlock == true">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Tipologia</span>
            </div>
            <select class="custom-select" id="idtipologiacoord" name="idtipologiacoord" [(ngModel)]="idtipologiacoord" *ngIf="showtipologiacombobox == true">
              <option selected>Choose...</option>
              <option *ngFor="let obj of ListTipologie" [value]="obj.idtipologia">{{obj.descrizione}}</option>
            </select>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="idtipologiaedit" name="idtipologiaedit" *ngIf="showtipologiatext == true" [(ngModel)]="tipologiaedit">
            <div class="input-group-append" id="button-addon4">
              <button class="btn btn-outline-primary active" type="button" *ngIf="showtipologianew" (click)="newTipologia()"><i class="fa fa-fw" aria-hidden="true" title="new"></i></button>
              <button class="btn btn-outline-success active" type="button" *ngIf="showtipologiaedit" (click)="editTipologia()"><i class="fa fa-fw" aria-hidden="true" title="edit"></i></button>
              <button class="btn btn-outline-danger active" type="button"  *ngIf="showtipologiadel" (click)="deleteTipologia()"><i class="fa fa-fw" aria-hidden="true" title="delete"></i></button>
              <button class="btn btn-outline-warning active" type="button" *ngIf="showtipologiasave" (click)="saveTipologia()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i></button>
              <button class="btn btn-outline-success active" type="button" *ngIf="showtipologiaok" (click)="showConfirmTipologia()"><i class="fa fa-fw" aria-hidden="true" title="ok"></i></button>
              <button class="btn btn-outline-warning active" type="button"  *ngIf="showtipologiacancel" (click)="cancelTipologia()"><i class="fa fa-fw" aria-hidden="true" title="close"></i></button>
            </div>
          </div>
          <div class="alert alert-primary p-1" role="alert" *ngIf="showTipologiaBlock == false">
            <div class="row">
              <div class="col-9 pt-1 pl-4">{{messagetipologia}}</div>
              <div class="input-group-append col-3 pull-right" id="button-addon4">
                <button class="btn btn-outline-success active btn-sm mr-1 pull-right" type="button" (click)="saveDataTipologia()"><i class="fa fa-fw" aria-hidden="true" title="ok"></i></button>
                <button class="btn btn-outline-warning active btn-sm pull-right" type="button" (click)="cancelTipologia()"><i class="fa fa-fw" aria-hidden="true" title="close"></i></button>
              </div>
            </div>
          </div>
          <div class="input-group input-group-sm mb-3" *ngIf="showDatumBlock == true">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Datum</span>
            </div>
            <select class="custom-select" id="iddatum" name="iddatum" [(ngModel)]="iddatum" *ngIf="showdatumcombobox == true">
              <option selected>Choose...</option>
              <option *ngFor="let obj of ListDatum" [value]="obj.iddatum">{{obj.datum}}</option>
            </select>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="idtipologiaedit" name="idtipologiaedit" *ngIf="showdatumtext == true" [(ngModel)]="datumedit">
            <div class="input-group-append" id="button-addon4">
              <button class="btn btn-outline-primary active" type="button" (click)="newDatum()" *ngIf="showdatumnew"><i class="fa fa-fw" aria-hidden="true" title="new"></i></button>
              <button class="btn btn-outline-success active" type="button" (click)="editDatum()" *ngIf="showdatumedit"><i class="fa fa-fw" aria-hidden="true" title="edit"></i></button>
              <button class="btn btn-outline-danger active" type="button" (click)="deleteDatum()" *ngIf="showdatumdel"><i class="fa fa-fw" aria-hidden="true" title="delete"></i></button>
              <button class="btn btn-outline-warning active" type="button" *ngIf="showdatumsave" (click)="saveDatum()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i></button>
              <button class="btn btn-outline-success active" type="button" *ngIf="showdatumok" (click)="showConfirmDatum()"><i class="fa fa-fw" aria-hidden="true" title="ok"></i></button>
              <button class="btn btn-outline-warning active" type="button" *ngIf="showdatumcancel" (click)="cancelDatum()"><i class="fa fa-fw" aria-hidden="true" title="close"></i></button>
            </div>
          </div>
          <div class="alert alert-primary p-1" role="alert" *ngIf="showDatumBlock == false">
            <div class="row">
              <div class="col-9 pt-1 pl-4">{{messagedatum}}</div>
              <div class="input-group-append col-3 pull-right" id="button-addon4">
                <button class="btn btn-outline-success active btn-sm mr-1 pull-right" type="button" (click)="saveDataDatum()"><i class="fa fa-fw" aria-hidden="true" title="ok"></i></button>
                <button class="btn btn-outline-warning active btn-sm pull-right" type="button" (click)="cancelDatum()"><i class="fa fa-fw" aria-hidden="true" title="close"></i></button>
              </div>
            </div>
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Latitudine gradi</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="latgradcoord" name="latgradcoord" placeholder="Gradi" [(ngModel)]="latgradcoord" (change)="calcolateCoord('lat')">
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="latfirstcoord" name="latfirstcoord" placeholder="Primi" [(ngModel)]="latfirstcoord" (change)="calcolateCoord('lat')">
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="latsecondcoord" name="latsecondcoord" placeholder="Secondi" [(ngModel)]="latsecondcoord" (change)="calcolateCoord('lat')">
            <select class="custom-select custom-select-sm" id="idnazione" name="idnazione" [(ngModel)]="latdir" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">select</option>
              <option value="1">North</option>
              <option value="2">South</option>
            </select>
            <button class="btn btn-outline-primary active btn-sm pull-right" type="button" (click)="calcolateCoord('lat')"><i class="fa fa-fw" aria-hidden="true" title="refresh"></i></button>
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Latitudine</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="latcoord" name="latcoord" [(ngModel)]="latcoord" >
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Longitudine Gradi</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="lnggradcoord" name="lnggradcoord" placeholder="Gradi" [(ngModel)]="lnggradcoord" (change)="calcolateCoord('lng')">
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="lngfirstcoord" name="lngfirstcoord" placeholder="Primi" [(ngModel)]="lngfirstcoord" (change)="calcolateCoord('lng')">
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="lngsecondcoord" name="lngsecondcoord" placeholder="Secondi" [(ngModel)]="lngsecondcoord" (change)="calcolateCoord('lng')">
            <select class="custom-select custom-select-sm" id="idnazione" name="idnazione" [(ngModel)]="lngdir" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">select</option>
              <option value="1">Est</option>
              <option value="2">West</option>
            </select>
            <button class="btn btn-outline-primary active btn-sm pull-right" type="button" (click)="calcolateCoord('lng')"><i class="fa fa-fw" aria-hidden="true" title="refresh"></i></button>
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Longitudine</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="lngcoord" name="lngcoord" [(ngModel)]="lngcoord">
          </div>
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Ordine</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" id="lnggradcoord" name="lnggradcoord" [(ngModel)]="ordine">
          </div>
        </div>      
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      <button *ngIf="this.actionOp == '1'" type="button" class="btn btn-primary" (click)="newWell(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '2'" type="button" class="btn btn-primary" (click)="saveWell(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '3'" type="button" class="btn btn-primary" (click)="deleteWell(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '4'" type="button" class="btn btn-primary" (click)="closedWell(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '5'" type="button" class="btn btn-primary" (click)="saveAttachment(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '6'" type="button" class="btn btn-primary" (click)="deleteFile(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '7'" type="button" class="btn btn-primary" (click)="deleteCoord(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '8'" type="button" class="btn btn-primary" (click)="saveCoord(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '9'" type="button" class="btn btn-primary" (click)="updateCoord(); c('Close click')">{{buttonAction}}</button>
    </div>
  </ng-template>
<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Tabset
      </div>
      <div class="card-body">
        <ngb-tabset>
          <ngb-tab title="Simple">
            <ng-template ngbTabContent>
              <p class="mt-3">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                retro synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher
                retro keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin
              coffee squid.
              <p class="mt-3">Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                loko farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
                helvetica VHS salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown,
                tumblr butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
              <p class="mt-3">Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam.
                Nulla facilisi.
                Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu,
                congue et odio.
                Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et.
                Sed diam urna,
                egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus
                nisl enim, nec
                tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque
                penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                ultricies in eget neque.
                Phasellus nec tortor vel tellus pulvinar feugiat.</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Pills
      </div>
      <div class="card-body">
        <ngb-tabset type="pills">
          <ngb-tab title="Simple">
            <ng-template ngbTabContent>
              <p class="mt-3">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                retro synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher
                retro keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin
              coffee squid.
              <p class="mt-3">Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                loko farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
                helvetica VHS salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown,
                tumblr butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
              <p class="mt-3">Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam.
                Nulla facilisi.
                Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu,
                congue et odio.
                Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et.
                Sed diam urna,
                egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus
                nisl enim, nec
                tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque
                penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                ultricies in eget neque.
                Phasellus nec tortor vel tellus pulvinar feugiat.
              </p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Justified
      </div>
      <div class="card-body">
        <ngb-tabset [justify]="currentJustify">
          <ngb-tab title="Simple">
            <ng-template ngbTabContent>
              <p class="mt-3">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                retro synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher
                retro keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin
              coffee squid.
              <p class="mt-3">Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                loko farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
                helvetica VHS salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown,
                tumblr butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="A very long nav title">
            <ng-template ngbTabContent>
              <p class="mt-3">Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam.
                Nulla facilisi. Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget
                tincidunt eu, congue et odio. Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et
                molestie lectus ultrices et. Sed diam urna, egestas ut ipsum vel, volutpat volutpat neque.
                Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec tristique ipsum euismod facilisis.
                Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget neque.
                Phasellus nec tortor vel tellus pulvinar feugiat.</p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

        <div class="divider"></div>

        <div class="text-center">
          <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="currentJustify">
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="start">Start
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="center">Center
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="end">End
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="fill">Fill
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="justified">Justified
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Orientation
      </div>
      <div class="card-body">
        <ngb-tabset type="pills" [orientation]="currentOrientation">
          <ngb-tab title="Simple">
            <ng-template ngbTabContent>
              <p class="mt-3">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua,
                retro synth
                master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher
                retro keffiyeh
                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat
                salvia cillum
                iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Fancy</b> title</ng-template>
            <ng-template ngbTabContent>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin
              coffee squid.
              <p class="mt-3">Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four
                loko farm-to-table
                craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
                helvetica VHS salvia
                yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                sustainable jean
                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown,
                tumblr butcher vero
                sint qui sapiente accusamus tattooed echo park.</p>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Disabled" [disabled]="true">
            <ng-template ngbTabContent>
              <p class="mt-3">Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam.
                Nulla facilisi.
                Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu,
                congue et odio.
                Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et.
                Sed diam urna,
                egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus
                nisl enim, nec
                tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque
                penatibus et
                magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet
                ultricies in eget neque.
                Phasellus nec tortor vel tellus pulvinar feugiat.
              </p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

        <div class="divider"></div>

        <div class="text-center">
          <div class="btn-group btn-group-toggle" ngbRadioGroup [(ngModel)]="currentOrientation">
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="horizontal">Horizontal
            </label>
            <label ngbButtonLabel class="btn-outline-primary btn-sm">
              <input ngbButton type="radio" value="vertical">Vertical
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/shared/data.service';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { ThemeOptions } from 'src/assets/theme-options';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.css']
})
export class SearchComponentComponent implements OnInit {
  search:string;
  @ViewChild('yourInput', {static: false}) yourInput: ElementRef;
  
  constructor(public globals: ThemeOptions,
     private activatedRoute: ActivatedRoute, 
     private router:Router,
     public restApi: RestApiService,
     private _snackBar: MatSnackBar,
     private data: DataService) 
     {

    }

     ListPlatform: any = [];
     ListPosizionamenti: any = [];
     ListKml: any = [];
     listposshow:boolean;
     listrigshow:boolean;
     showresultpos:boolean;
     showresultrig:boolean;


     ngOnInit(): void {
      this.listposshow = false;
      this.listrigshow = false;
      this.showresultpos = false;
      this.showresultrig = false;
    }

  onSearchChange(searchValue: string): void {  
    console.log(searchValue);
    var idcliente = sessionStorage.getItem("idcliente");
    this.getPosizionamentiList(idcliente, searchValue)
  }

  getPosizionamentiList(idcliente, search){
    var idutente = sessionStorage.getItem("idutente");
    console.log('idutente = ' + idcliente);
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var stato= "1";
    console.log('=> getPiattaformeUtente');

    var len=search.length;

    if(len >= 3){
      this.restApi.getPosizionamentiSearch(idcliente, search, token).subscribe((data: {}) => {
        this.ListPosizionamenti = [];
        this.ListPosizionamenti = data;
        console.log('posizionamenti = ' + JSON.stringify(this.ListPosizionamenti));
        var countItem = this.ListPosizionamenti.length;
        
        if(countItem > 0){
          this.listposshow = true;
          this.showresultpos = true;
        }else{
          this.showresultpos = false;
          this.listposshow = false;
        }

      }); 

      this.restApi.getPiattaformeSearch(idutente, search, token).subscribe((data: {}) => {
        this.ListPlatform = [];
        this.ListPlatform = data;
        console.log('platform = ' + JSON.stringify(this.ListPlatform));
        var countItem = this.ListPlatform.length;
        
        if(countItem > 0){
          this.listrigshow = true;
        }

        if(countItem > 0){
          this.listrigshow = true;
          this.showresultrig = true;
        }else{
          this.showresultrig = false;
          this.listrigshow = false;
        }


      }); 

    }else{
      this.ListPosizionamenti = [];
      this.ListPlatform = [];
      this.showresultpos = false;
      this.showresultrig = false;
    }
    
  }

  gotoDash(){
    this.router.navigate(['']);
  }

  gotoRig(id, lat, lng){
    //"RIG|" + id + "|" + lat + "|" + lng; 
    console.log('gotoRig data = ' + "RIG|" + id + "|" + lat + "|" + lng);
    var latlng = "RIG|" + id + "|" + lat + "|" + lng; 
    this.data.changeMessage(latlng);
    this.router.navigate(['']);
  }

  gotoPlatform(year, nation, idposizionamento){
    console.log('gotoPlatform data = ' + "POS|" + year + "|" + nation + "|" + idposizionamento + "|false");
    this.data.changeMessage("POS|" + year + "|" + nation + "|" + idposizionamento + "|false");
    this.router.navigate(['']);
  }

  onBlur(event) {
    this.yourInput.nativeElement.focus()
  }
}

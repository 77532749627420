<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
        <div class="form-inline">
            <h5 class="mt-2">Anagrafica KML</h5>
            <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
         </div>
</div>

<div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
    <h4 class="alert-heading">Elenco kml vuoto</h4>
    <p>Nessun kml presente. Creare un nuovo kml e riporvare.</p>
  </div>  
 <div class="mat-elevation-z0" *ngIf="status=='master' && showList == true">
  <table class="w-100" mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="descrizione">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size:13pt;"> Descrizione file </th>
      <td mat-cell *matCellDef="let row"> {{row.description}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
      <td mat-cell *matCellDef="let row"> <a class="float-right" href="{{row.link}}" target="_blank">Download</a> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
<div class="d-block p-4 text-center card-footer">

</div>

</div>
import { ChangeDetectorRef, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEventDescription } from 'src/app/shared/maineventdescriptions';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-anagrafica-mainevent',
  templateUrl: './anagrafica-mainevent.component.html',
  styleUrls: ['./anagrafica-mainevent.component.css']
})
export class AnagraficaMaineventComponent implements OnInit {
  displayedColumns: string[] = ['id', 'descrizione', 'attivita', 'action'];
  dataSource: MatTableDataSource<MainEventDescription>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  iddescription:string;
  idmodal:string;
  status:string;
  showList:boolean;
  MainEventDescriptionList: any = [];
  MainEventDescription: any = [];
  keysearch: string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  share_message;

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";

  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.status = "master";
    this.showList = true;
    this.destitle = "Nuova anagrafica";
    this.loadMED();
  }

  medLoad(iddescription){

  }

  openModalDelete(content, iddescription) {
    this.iddescription = iddescription;
    this.idmodal = "normal";
    this.titleModal = "Elimina Anagrafica Main";
    this.bodyModal = "Vuoi eliminare il record anagrafica main event selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  loadMaster(){
    this.loadMED();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.status = "detail";
    console.log("detail");
  }

  loadMED(){
    this.keysearch = "";

    return this.restApi.getMainEventDescriptionList(this.token).subscribe((data: {}) => {
      this.MainEventDescriptionList = data;
      var count = this.MainEventDescriptionList.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.MainEventDescriptionList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  saveMED(){

  }

  newMED(){
    this.status = "detail";
    this.action = "new";
    // this.nome = "";
    // this.cognome = "";
    // this.username = "";
    // this.email = "";
    // this.password = "";
    // this.confirmpassword = "";
    // this.ruolo = "";
    // this.attivo = "";
    // this.idutente = "";
  }

  searchMED(){
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}

import { Component, OnInit, AfterViewInit, HostListener, ElementRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RestApiService } from "src/app/shared/rest-api.service";
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LinkedMatsnackbarComponent } from 'src/app/Pages/linked-matsnackbar/linked-matsnackbar.component';
import { from, Observable } from 'rxjs';
import * as L from 'leaflet';
import 'leaflet.boatmarker';
import 'leaflet-rotatedmarker';
import 'leaflet.polylineMeasure';
import 'leaflet-kmz';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
(window as any).html2canvas = html2canvas;
import PDFObject from 'pdfobject';
import leafletimage from 'leaflet-image';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

declare var $: any;
import { Subscription,interval } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { SidebarComponent } from 'src/app/Layout/Components/sidebar/sidebar.component';
import { DataService } from 'src/app/shared/data.service';
import { NgxCaptureService } from 'ngx-capture';
import leafletgraticule from 'leaflet-graticule';
import "leaflet-windy";
import "leaflet-textpath";
import * as shp from "shpjs";
import "leaflet-geometryutil";
import GeometryUtil from 'leaflet-geometryutil';
import { ShapeFiles } from 'src/app/shared/shapefiles';
import 'reflect-metadata';
import { plainToClass, deserialize } from 'class-transformer';
import { stringify } from 'querystring';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  // styles:[
  //   `app-main__inner {
  //     padding: 10px 20px 0;
  //     flex: 1;
  // }`
  // ]
})

export class AnalyticsComponent implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild(SidebarComponent) child;
  @ViewChild('screen', { static: true }) screen: any;
  @Output() public onUploadFinished = new EventEmitter();
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @ViewChild('file')

//#region variables
  displayStyle = "none";
  heading = 'Analytics Dashboard';
  subheading = 'This is an example dashboard created using build-in elements and components.';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';
  schema:string;
  currentzoom:string;
  centermap:string;
  filenameUpload:string;
  filenameUploadMod:string;
  ListPlatform: any = [];
  ListPlatformSel: any = [];
  Posizionamento: any = [];
  ListKml: any = [];
  ListKmlWell: any = [];
  ListSingleKml: any = [];
  ListAttachments: any = [];
  ListDocuments: any = [];
  ListCoordinates: any = [];
  ListCoordinate: any = [];
  ListCoordinatesTemp: any = [];
  ListCoordWGS84Temp: any = [];
  ListMeasures: any = [];
  ListMeasuresTemp: any = [];
  ListShapeFiles: any = [];
  ListDataOffset: any = [];
  
  files2: any[] = [];files: any[] = [];
  snackBarShown = false;
  message:string;
  message2:string;
  action:string;
  markers: any = [];
  markersOffset = {};
  idpiattaforma:string;
  interval = null;
  interval1 = null;

  id_param:string;
  idpos_param:string;
  showattach:boolean;
  idplatform_user:string;
  loadkml:string;
  path:string;
  ambiente:string;
  yearsel:string;
  nationsel:string;
  wellsel:string;
  titleWell:string;
  calculator:string;
  tipologiafile:string;
  idposizionamento: string;
  codiceprogetto: string;
  codicepozzo: string;
  nomepozzo: string;
  datainizioposizionamento: string;
  datafineposizionamento: string;
  tipoSurveyDatum: string;
  latSurveyDatum: string;
  lngSurveyDatum: string;
  latSurveyDatumGrad: string;
  lngSurveyDatumGrad: string;
  latWGS84: string;
  lngWGS84: string;
  latWGS84Grad: string;
  lngWGS84Grad: string;
  latPH: string;
  lngPH: string;
  latPHGrad: string;
  lngPHGrad: string;
  latPHWGS84: string;
  lngPHWGS84: string;
  latPHWGS84Grad: string;
  lngPHWGS84Grad: string;
  latMH: string;
  lngMH: string;
  latMHGrad: string;
  lngMHGrad: string;
  latMHWGS84: string;
  lngMHWGS84: string;
  latMHWGS84Grad: string;
  lngMHWGS84Grad: string;
  latrig:string;
  lngrig:string;
  lattemp:string;
  lngtemp:string;
  coordinates:string;
  piattaforma: string;
  anno: string;
  idnazione: string;
  nazione: string;
  idcliente: string;
  cliente: string;

  finalreport_desc:string;
  finalreport_path:string;
  vdp_desc:string;
  vdp_path:string;
  vdpmh_desc:string;
  vdpmh_path:string;
  vdpph_desc:string;
  vdpph_path:string;
  pdfSrc:string;
  isdoc:boolean;
  isActivated:boolean;
  ListPosizMonitor: any = [];
  idpozzosel:string;
  ListMainEvent: any = [];
  ListMainEventTemp: any = [];
  ListTipFile: any = [];
  showmainevent:boolean;
  subscription: Subscription;
  sharedatalst:string;
  sharedatabkp:string;
  imgBase64:any='';
  check:number = 0;
  countOffset:number = 0;
  apiURL:string;
  progress:number;
  progress2:number;
  object:string;
  showtablereport:boolean;
  activemeasurement:boolean;
  counter: number;
  timerRef;
  running: boolean = false;
  startText = 'Start';
  test;
  units:string;
  measurement:boolean;
  messageMeasurement:string;
  polygon:L.polyline;
  counterpol:number;
  codiceprogettoint:string;
  idallegato:string;
  activekmltab:string;
  activedoctab:string;

  ListFilesTemp: any = [];
  ListFilesTemp2: any = [];
  timestamp:string;
  showDebug:boolean;
  uploadfile_block:boolean;
  uploadfile_msg:boolean;
  countfiles:number;
  uploadfile_block2:boolean;
  uploadfile_msg2:boolean;
  countfiles2:number;
  point0:string;
  markerdata: any = [];
  myInputVariable: ElementRef;
  countcycle:number = 0;
  private token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  private map: any;
  private layer;
  private markerList = {};
  private polyline1;
  private polyline2;
  private fg;
  countshape:number;
  countpoly:number = 0;
  someSubscription: any;
  mapselected:string;
  latinit:number;
  lnginit:number;
  southwest:string;
  southeast:string;
  northwest:string;
  northeast:string;
  countiter:number = 0;
  refresh:number;
  refresh_active:string;
  //#endregion

  constructor(
    private router:Router,
    public restApi: RestApiService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private http: HttpClient,
    private data: DataService,
    private captureService:NgxCaptureService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
   }

  ngOnInit() {
    if (sessionStorage.getItem("authorize") === null) {
      //console.log('auth null');
      this.router.navigate(['/login']);
    }else{
      var auth = sessionStorage.getItem("authorize");
      //console.log('auth = ' + auth);
      if(auth == "0000" || auth == "9999"){
        this.router.navigate(['/login']);
      }
    }

    this.refresh = Number(sessionStorage.getItem("refresh"));
    this.refresh_active = sessionStorage.getItem("refresh_active");
    this.initMap();     
    this.startTimer();
    this.startListener();   
  }  

  ngAfterViewInit(): void { 

  }

//#region events
  private initMap(): void {
    this.showattach = false;
    this.showmainevent = false;
    this.showtablereport = true;
    this.activemeasurement = false;
    this.units = "m";
    this.measurement = false;
    this.messageMeasurement = "Turn on measurement";
    this.ListMeasures = [];
    this.ListMeasuresTemp = [];
    this.counterpol = 0;
    this.activekmltab = "general";
    this.activedoctab = "general";
    this.ListShapeFiles = JSON.parse(sessionStorage.getItem("ListShapeFiles"));
    var stato = 1;
    this.countshape = 0;
    this.polyline1 = L.layerGroup();
    this.polyline2 = L.layerGroup();
    $('#mydiv').hide();
    $('#print').hide();
    $('#modaldata').css("display","none");
    $('#modaldata1').css("display","none");
    this.idpozzosel = "-1";
    this.pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf"; 
    var mbAttr = 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>, <a href="https://www.linkedin.com/in/giuseppe-scordo-83951413/">Giscotech ©</a>';
    var mseaAttr = 'Map data: © <a href="http://www.openseamap.org">OpenSeaMap</a>';
    var mbUrl = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZXhwbG9yZXJtYXJpbmVzYm9zcyIsImEiOiJjbDA2cmFldncwMGFkM2RxcHpzNHNjZzUwIn0.3BUz1KB47Km6N2HJGMKedg';
    
    if(localStorage.getItem("mapselected") === null){
      var mapselect = "streets";
      localStorage.setItem("mapselected", mapselect);
    }else{
      var mapselect = localStorage.getItem("mapselected");
    }

    var mapsel;
    switch(mapselect){
      case 'grayscale':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/light-v9', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'streets':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/streets-v11', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'satellite':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/satellite-v9', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'satellite2':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/satellite-streets-v11', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'navigation_day':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/navigation-day-v1', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'navigation_night':
        mapsel = L.tileLayer(mbUrl, {id: 'mapbox/navigation-night-v1', tileSize: 512, zoomOffset: -1, attribution: mbAttr, maxZoom:30, minZoom:3});
        break;
      case 'openseamap':
        mapsel = L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', { attribution: mseaAttr, maxZoom:30, minZoom:3 });
        break;
    }

    this.mapselected = mapselect;

    this.isdoc = false;
    var lat = 0;
    var lng = 0;

    if (sessionStorage.getItem("latsession") !== null && sessionStorage.getItem("lngsession") !== null) {
      var latvar = sessionStorage.getItem("latsession");
      if(latvar !== "undefined"){
        lat = parseFloat(sessionStorage.getItem("latsession"));
      }else{
        lat = 41.8900539456375;
      }

      var lngvar = sessionStorage.getItem("lngsession");
      if(lngvar !== "undefined"){
        lng = parseFloat(sessionStorage.getItem("lngsession"));
      }else{
        lng = 12.498911865142507;
      }
    }else{
      lat = 41.8900539456375;
      lng = 12.498911865142507;
    }

    var zoombase = 6;
    $('#idpiattaforma').val(-1);

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    if(baseUrl.includes("beta")){
      this.ambiente = "2";
    }else{
      this.ambiente = "1";
    }

    //console.log('ambiente = ' + this.ambiente);
    if(!this.map){
    this.map = L.map('map', {
      center: [ lat, lng ],
      zoom: zoombase,
      zoomDelta: 0.1,
      zoomSnap: 0,
      zoomControl: false,
      //scrollWheelZoom: false,
      layers: [mapsel]
    });
    
    this.fg = L.featureGroup().addTo(this.map);

    //this.setCurrentZoom();
    this.getPerimeterMap();

    const Coordinates = L.Control.extend({
      onAdd: map => {
        const container = L.DomUtil.create("div", "leaflet-control-command");
        map.addEventListener("mousemove", e => {
          container.innerHTML = `
            <span class="badge bg-info" style="margin-left:100px;font-size:12px; color:#fff;">Latitude: ${e.latlng.lat.toFixed(4)} - Longitude: ${e.latlng.lng.toFixed(4)}</span>
          `;
        });
        return container;
      }
    });
    this.map.addControl(new Coordinates({ position: "bottomright" }));

    // var baseLayers = {
    //   'Grayscale': grayscale,
    //   'Streets': streets,
    //   'Satellite 2': satellite2,
    //   'Day': navigation_day,
    //   'Night': navigation_night,
    //   'Sea Map': openseamap
    // };

    //var layerControl = L.control.layers(baseLayers,"", {position: 'bottomright'}).addTo(this.map);

    // const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   maxZoom: 25,
    //   minZoom: 3,
    //   attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // });

    // tiles.addTo(this.map);

    L.control.scale ({maxWidth:240, metric:true, imperial:false, position: 'bottomright'}).addTo (this.map);
    //L.control.scale({metric:true, imperial:false, position: 'bottomright'}).addTo(this.map);
    let polylineMeasure = L.control.polylineMeasure ({position:'bottomright', unit:'kilometres', showBearings:true, clearMeasurementsOnStop: false, showClearControl: true, showUnitControl: true, useSubunits: true})
    polylineMeasure.addTo (this.map);

    var kmz = L.kmzLayer().addTo(this.map);

    //LOADING DATA FROM KML
      var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
      var idutente = sessionStorage.getItem("idutente");
      this.getPiattaformeUtente(idutente, stato, token);

      this.restApi.getKml(idutente, this.ambiente, token).subscribe((data: {}) => {
        this.ListKml = data;
        sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
        //console.log('platform: ' + JSON.stringify(this.ListPlatform));

        this.ListKml.forEach((currentValue, index) => {
          var path_kml = currentValue.kmlpath;
          var kmlactive = currentValue.kmlactive;
          if(kmlactive == "1"){
            kmz.load(path_kml);    
          }
        });


      });

    var control = L.control.layers(null, null, { collapsed:false }).addTo(this.map);
    }
    this.getTipologiaFile();
    this.setMarkers();

  }

  mapRefreshDataApp() {
    //reset map
    //this.map.off();
    //this.map.remove();
    //initialize map
    //this.initMap();
    //set saved zoom
    this.getCurrentZoom();
    this.getCoordinatesCenterMap();
    this.getPerimeterMap();
  }

  getPerimeterMap(){
    this.southwest = this.map.getBounds().getSouthWest();
    this.southeast = this.map.getBounds().getSouthEast();
    this.northwest = this.map.getBounds().getNorthWest();
    this.northeast = this.map.getBounds().getNorthEast(); 
    // console.log('southwest = ' + this.southwest);
    // console.log('southeast = ' + this.southeast);
    // console.log('northwest = ' + this.northwest);
    // console.log('northeast = ' + this.northeast);
  }

  checkPointToPerimeter(lat,lng){
    var check = 0;
    var latnw = this.map.getBounds().getNorthWest().lat;
    var lngnw = this.map.getBounds().getNorthWest().lng;
    var latse = this.map.getBounds().getSouthEast().lat;
    var lngse = this.map.getBounds().getSouthEast().lng;
    // console.log('latnw = ' + latnw);
    // console.log('lngnw = ' + lngnw);
    // console.log('latse = ' + latse);
    // console.log('lngse = ' + lngse);

    if(latnw > lat && lngnw < lng && latse < lat && lngse > lng){
      check = 1;
    }

    return check;
  }

  startTimer() {
    this.stopTimer();
    this.interval = setInterval(() => {
      this.setCurrentZoom();
      this.setCoordinatesCenterMap();
      this.getPerimeterMap();
      //this.clearMap();
      ++this.countcycle;
      //console.log('countcycle = ' + this.countcycle);
      if(this.countcycle == this.refresh){
        if(this.refresh_active == "true"){
          this.mapRefreshDataApp();
        }
        this.countcycle = 0; 
      }
      
      this.switchActionOperation();

    }, 3000);
  }

  startListener() {
    this.running = !this.running;
    if (this.running) {
      this.startText = 'Stop';
      //console.log('start');
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.actionOpenData();
      },1000);
    } else {
      //console.log('stop');
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      //console.log('stop repeat interval.');
    }
  }

  stopTimerListener() {
    if (this.interval1) {
      clearInterval(this.interval1);
      //console.log('stop repeat interval.');
    }
  }

  clearMap() {
    console.log('map layers = ' + this.map._layers);
    for(let i in this.map._layers) {
        if(this.map._layers[i]._path != undefined) {
            try {
              this.map.removeLayer(this.map._layers[i]);
            }
            catch(e) {
                console.log("problem with " + e + this.map._layers[i]);
            }
        }
    }
  }

  changeMap(map){
    //console.log('map = ' + map);
    this.mapselected = map;
    localStorage.setItem("mapselected", map);
    window.location.reload();
  }

  clearTimerListener() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    clearInterval(this.timerRef);
  }

  setCurrentZoom(){
    var zoom = this.map.getZoom().toFixed(2);
    localStorage.setItem("currentZoom", zoom);
    this.currentzoom = zoom; 
  }

  getCurrentZoom(){
    var zoom = localStorage.getItem("currentZoom");
    //this.map.zoom = Number(zoom);
    this.currentzoom = zoom;
  }

  setCoordinatesCenterMap(){
    var center = this.map.getCenter();
    var lat = center.lat;
    var lng = center.lng;
    var centerdata = lat + "|" + lng;
    localStorage.setItem("centerMap", centerdata);
  }

  getCoordinatesCenterMap(){
    if(localStorage.getItem("centerMap") === null){
      var latcenter1 = 41.8900539456375;
      var lngcenter1 = 12.498911865142507;
      this.latinit = latcenter1;
      this.lnginit = lngcenter1;
      var zoom1 = 6;
      //this.map.setView([latcenter1,lngcenter1], zoom1);
    }else{
      var center = localStorage.getItem("centerMap");
      var centerarr = [];
      centerarr = center.split('|');
      var latcenter2 = centerarr[0];
      var lngcenter2 = centerarr[1];
      this.latinit = latcenter2;
      this.lnginit = lngcenter2;
      var zoom2= localStorage.getItem("currentZoom");
      this.centermap = center;
      this.map.setView([latcenter2,lngcenter2], zoom2);
    }

  }

  activeMeasurement(){
    if(this.measurement == false){
      this.measurement = true;
      this.messageMeasurement = "Turn off measurement";
      var units = this.units;
      this.map.on('click', function(e) {        
       
        var latitude = e.latlng.lat;
        var longitude = e.latlng.lng;
  
        if(this.ListMeasures == undefined){
          this.ListMeasures = [];
        } 
  
        this.ListMeasures.push({latitude: latitude, longitude: longitude});
        //console.log("ListMeasures = " + JSON.stringify(this.ListMeasures));
        sessionStorage.setItem("ListMeasures", JSON.stringify(this.ListMeasures));
        
        var countMeasures = this.ListMeasures.length;
        this.ListMeasuresTemp = [];
        
        for (let i = 0; i <= countMeasures; i++) {
          //console.log('i = ' + i);
          //L.marker(this.ListMeasures[i].latitude, this.ListMeasures[i].longitude).addTo(this);
  
          if(i >= 1){
            
            var y = i;
            var x = i-1;
            //console.log('x = ' + x);
            //console.log('y = ' + y);
  
            var latx = this.ListMeasures[x].latitude;
            var lngx = this.ListMeasures[x].longitude;
            this.ListMeasuresTemp.push([latx, lngx]);
            var laty = this.ListMeasures[y].latitude;
            var lngy = this.ListMeasures[y].longitude;
            this.ListMeasuresTemp.push([laty, lngy]);
  
            // latx=45.4230181;
            // lngx=9.2784486;
            // laty=45.4230192;
            // lngy=9.2784497;
  
            var theta = lngx - lngy;
            //console.log('theta = ' + theta);
                      //(Math.sin(this.deg2rad(latx)) * Math.sin(this.deg2rad(laty))) + (Math.cos(this.deg2rad(laty)) * Math.cos(this.deg2rad(laty)) * Math.cos(this.deg2rad(theta)));
            
            var pi = Math.PI;
            var latxd2r = latx * (pi/180);
            var latyd2r = laty * (pi/180);
            var thetad2r = theta * (pi/180);
  
            //var miles = (Math.sin(this.deg2rad(latx)) * Math.sin(this.deg2rad(laty))) + (Math.cos(this.deg2rad(latx)) * Math.cos(this.deg2rad(laty)) * Math.cos(this.deg2rad(theta)));
            var miles = (Math.sin(latxd2r) * Math.sin(latyd2r)) + (Math.cos(latxd2r) * Math.cos(latyd2r) * Math.cos(thetad2r));
            miles = Math.acos(miles);
            miles = miles * (180/pi);
            //miles = this.rad2deg(miles);
            miles = miles * 60 * 1.1515;
            var feet = miles * 5280;
            //console.log('feet = ' + feet);
            var yards = feet / 3;
            //console.log('yards = ' + yards);
            var kilometers = miles * 1.609344;
            //console.log('kilometers = ' + kilometers);
            var meters = kilometers * 1000;
            //console.log('meters = ' + meters);
            var decimeters = meters * 10;
            var centimeters = decimeters * 10;
  
  
            var fontAwesomeIconPoint = L.divIcon({
              html: '<i class="fa fa-dot-circle-o fa-lg" aria-hidden="true"></i>',
              iconSize: [20, 20],
              className: 'myDivIcon'
            });
  
            L.marker([latx, lngx],{
              //rotationAngle: headingvalue,
              //rotationOrigin: 'center',
              icon: fontAwesomeIconPoint,
              // icon: new L.Icon({
              //     iconSize: [22, 22],
              //     iconAnchor: [11, 22],
              //     iconUrl: marker_image,
              //   }),
                title: ""}).addTo(this);
  
            //this.polygon = L.polyline(lngLatArrayToLatLng(this.ListMeasuresTemp)).addTo(this);
            this.polygon = L.polyline(this.ListMeasuresTemp, {color: '#2182FF', weight: 2});
            
            var misure = "";
            switch(units){
              case 'm':
                misure = meters.toFixed(2) + " m";
                break;
              case 'km':
                misure = kilometers.toFixed(2) + " km";
                break;
              case 'NM':
                misure = miles.toFixed(2) + " NM";
                break;
            }
            
            this.polygon.setText(misure, { center: true, attributes: { fill: '#022046' } });
            this.polygon.addTo(this);
  
            const fontAwesomeIcon = L.divIcon({
              html: '<i class="fa fa-plus-square-o fa-2x" aria-hidden="true style="color:#ab0b4b;"></i>',
              iconSize: [20, 20],
              className: 'myDivIcon'
            });
            
  
            
  
              // this.counterpol = this.counterpol + 1;
              // this.markers[this.counterpol] = new L.Marker([latx, lngx], {
              //   icon: fontAwesomeIcon,
              //   title: ""
              // }).addTo(this);
  
              // this.counterpol = this.counterpol + 1;
              // this.markers[this.counterpol] = new L.Marker([laty, lngy], {
              //   icon: fontAwesomeIcon,
              //   title: ""
              // }).addTo(this);
  
            this.ListMeasuresTemp = [];
  
            
          }
  
        }
  
        
    });
    }else{
      this.measurement = false;
      this.messageMeasurement = "Turn on measurement";
      this.map.off('click');
    }
  }
  
  clearMeasurement(){
    this.measurement = false;
    this.messageMeasurement = "Turn on measurement";
    this.units = "m";
    this.ListMeasures = [];
    this.ListMeasuresTemp = [];
  
    for(let i in this.map._layers) {
      if(this.map._layers[i]._path != undefined) {
          try {
            this.map.removeLayer(this.map._layers[i]);
          }
          catch(e) {
              console.log("problem with " + e + this.map._layers[i]);
          }
      }
    }
    window.location.reload();
  
  }

  loadKml(){
    var kmz = L.kmzLayer().addTo(this.map);
  
    //LOADING DATA FROM KML
      var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
      var idutente = sessionStorage.getItem("idutente");
      this.restApi.getKml(idutente, this.ambiente, token).subscribe((data: {}) => {
        this.ListKml = data;
        sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
        //console.log('platform: ' + JSON.stringify(this.ListPlatform));
  
        this.ListKml.forEach( (currentValue, index) => {
          var path_kml = currentValue.kmlpath;
          var kmlactive = currentValue.kmlactive;
          if(kmlactive == "1"){
            kmz.load(path_kml);    
          }
        });
  
  
      });
  }

  openPopup() {
    this.stopTimer();
    this.displayStyle = "block";
    sessionStorage.setItem('openpopup', 'true');
  }
  
  closePopup() {
    sessionStorage.removeItem('idplatform');
    sessionStorage.removeItem('latsession');
    sessionStorage.removeItem('lngsession');
    sessionStorage.removeItem("lenME|" + this.idpos_param);
    this.startTimer();
    $('#modaldata').css("display","none");
    $('#idpiattaforma').val(-1);
    sessionStorage.setItem('openpopup', 'false');
    //this.idplatform_user = "";
    this.running = false;
  }
  
  closePopupWell(){
    sessionStorage.removeItem("lenME|" + this.idpos_param);
    this.sharedatalst = "";
    this.data.changeMessage("");
    this.running = false;
    this.Posizionamento = [];
    $('#modaldata1').css("display","none");
  }

  requestRDP(){
    //console.log('requestrdp idplatform = ' + $('#idpiattaforma').val());
    var idplatform = $('#idpiattaformautente').val();
    var idutente = sessionStorage.getItem("idutente");

    var session = {
      "sessionrdp": "1",
      "sessiontw": "1",
      "idutente": idutente,
      "idpiattaforma": this.idpiattaforma
    }
  
    this.restApi.updateSessionRDP(session).subscribe((data: {}) => {});
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  closeSnackBar() {
    this.snackBarShown = false;
    this._snackBar.dismiss();
  }
  
  zoomin(){
    this.map.setZoom(this.map.getZoom() + 1);
    this.currentzoom = this.map.getZoom().toFixed(2);
    this.setCurrentZoom();
    this.setCoordinatesCenterMap();
    this.getPerimeterMap();
    this.setMarkers();
  }
  
  zoomout(){
      this.map.setZoom(this.map.getZoom() - 1);
      this.currentzoom = this.map.getZoom().toFixed(2);
      this.setCurrentZoom();
      this.setCoordinatesCenterMap();
      this.getPerimeterMap();
      this.setMarkers();
  }

  //create a dynimic link to the individual items page specified in the parameter itemsID
  markerURL(itemsID){
    //console.log(itemsID + ' ' + this.markerList[itemsID][3]);
    var url = this.markerList[itemsID][3];
    window.open(url, '_blank');
  }

  setMarkerView(lat, lng){
    sessionStorage.setItem('latsession', lat);
    sessionStorage.setItem('lngsession', lng);
    this.map.flyTo([lat, lng], 8)
  }

  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
      $event.preventDefault();
      if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 107)
      //plus   
      this.zoomin(); 
      //console.log('CTRL + C');
      if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 109)
      //less
      this.zoomout();
      //console.log('CTRL +  V');
  }

  maplocationrig(){
    this.map.flyTo([this.latrig, this.lngrig], 18);
  }

  openlargemodalextend(){
    //this.capture();
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var count = 0;  
    var tipoSurveyDatum = "";
    var latSurveyDatum = "";
    var lngSurveyDatum = "";
    var latSurveyDatumGrad = "";
    var lngSurveyDatumGrad = "";
    var latWGS84 = ""; 
    var lngWGS84 = ""; 
    var latWGS84Grad = ""; 
    var lngWGS84Grad = ""; 
    var latPH = ""; 
    var lngPH = ""; 
    var latPHGrad = ""; 
    var lngPHGrad = ""; 
    var latPHWGS84 = ""; 
    var lngPHWGS84 = ""; 
    var latPHWGS84Grad = ""; 
    var lngPHWGS84Grad = ""; 
    var latMH = ""; 
    var lngMH = ""; 
    var latMHGrad = ""; 
    var lngMHGrad = ""; 
    var latMHWGS84 = ""; 
    var lngMHWGS84 = ""; 
    var latMHWGS84Grad = ""; 
    var lngMHWGS84Grad = ""; 
  
    /*GET MAIN EVENTS FOR POSITIONING*/
    this.restApi.getMainEvent(this.idpos_param, token).subscribe((data: {}) => {
      this.ListMainEventTemp = data;
      this.getWellWGS84Coord(this.idpos_param);
      var len = this.ListMainEventTemp.length;
      //console.log('len openlargemodal mainevent = ' + len );
      if(sessionStorage.getItem("lenME|" + this.idpos_param)){
          var lenSS = sessionStorage.getItem("lenME|" + this.idpos_param);
          //console.log('len openlargemodal lenSS = ' + lenSS );
          if(len > lenSS){
            this.ListMainEvent = data;
            if(len > 0){
              this.showmainevent = true;
            }else{
              this.showmainevent = false;
            }
          }
      }else{
        sessionStorage.setItem("lenME|" + this.idpos_param, len);
        this.ListMainEvent = this.ListMainEventTemp;
      }
  
      //console.log('ListMainEvent = ' + JSON.stringify(this.ListMainEvent));
    });
  
    if(this.Posizionamento.length == 0){
      this.ListCoordinates = [];
      this.ListCoordinatesTemp = [];
  
      this.getListKmlWell(this.idpos_param);
  
      this.restApi.getPosizionamento(this.idpos_param, token).subscribe((data: {}) => {
        this.Posizionamento = data;
        
        this.Posizionamento.forEach((currentValue, index) => {
          this.ListDocuments = [];
          var codiceprogetto = currentValue.codiceprogetto;
          var codiceprogettoint = currentValue.codiceprogettoint;
          this.codiceprogettoint = codiceprogettoint;
          var codicepozzo = currentValue.codicepozzo;
          var nomepozzo = currentValue.nomepozzo;
          var idpiattaforma = currentValue.idpiattaforma;
          var piattaforma = currentValue.piattaforma;
          var anno = currentValue.anno;
          var idnazione = currentValue.idnazione;
          this.idnazione = idnazione;
          var nazione = currentValue.nazione;
          var idcliente = currentValue.idcliente;
          var cliente = currentValue.cliente;
  
          this.restApi.getPosizionamentiCoordinateSingleForPozzo(this.idpos_param, token).subscribe((data: {}) => {
              this.ListCoordinatesTemp = data;
              var count = 0;
    
              this.ListCoordinatesTemp.forEach( (currentValue, index) => {
                var idcoord = currentValue.idcoord;
                var idposizionamento = currentValue.idposizionamento;
                var idtipologia = currentValue.idtipologia;
                var tipologia = currentValue.tipologia;
                var latitude = currentValue.latitude;
                var latitudegrad = currentValue.latitudegrad;
                var ordine = currentValue.ordine;

                if(latitudegrad){
                  latitudegrad = latitudegrad.replace('? ', '°').replace('?', '°');
                }
                var longitude = currentValue.longitude;
                var longitudegrad = currentValue.longitudegrad;
                if(longitudegrad){
                  longitudegrad = longitudegrad.replace('? ', '°').replace('?', '°');
                }

                // if(ordine == "1"){
                //   this.coordinates = latitudegrad.replace('? ', '°').replace('?', '°') + "%" + latitude + "|" + longitudegrad.replace('? ', '°').replace('?', '°') + "%" + longitude;
                // }

                var iddatum = currentValue.iddatum;
                var datum = currentValue.datum;
                var ordine = currentValue.ordine;
                ++count;
                this.ListCoordinates.push({ idcoord: idcoord, idposizionamento: idposizionamento, descrizione: tipologia + " - " + datum, idtipologia: idtipologia, tipologia: tipologia, iddatum: iddatum, datum:datum, latitude: latitude, latitudegrad: latitudegrad, longitude: longitude, longitudegrad: longitudegrad, ordine: ordine, pathfile: "", idtipo: "COORD" });
  
              });
  
              this.restApi.getAttachments(this.idpos_param, token).subscribe((data: {}) => {
                this.ListAttachments = data;
                //sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
                    
                this.ListAttachments.forEach( (currentValue, index) => {
                  var idallegato = currentValue.idallegato;
                  var descrizione = currentValue.descrizione;
                  var pathfile = currentValue.pathfile;
                  var idtipo = currentValue.idtipo;
    
                  ++count;
                  this.ListCoordinates.push({idcoord: count, idposizionamento: "", idallegato: count, descrizione: descrizione, idtipologia: "", tipologia: "", iddatum: "", datum:"", latitude: "", latitudegrad: "", longitude: "", longitudegrad: "", ordine: "", pathfile: pathfile, idtipo: idtipo } );
          
                });
    
            });
  
            this.piattaforma = piattaforma;
            this.titleWell = codicepozzo + " " + nomepozzo + " " + codiceprogetto;
            this.nazione = nazione;
            this.anno = anno;
            this.loadKmlForWell();
            $('#modaldata1').css("display","block");
  
          });
  
        });
      });
    }
  
  }

  openlargemodal(){
    //this.capture();
    var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    var count = 0;  
  
    var tipoSurveyDatum = "";
    var latSurveyDatum = "";
    var lngSurveyDatum = "";
    var latSurveyDatumGrad = "";
    var lngSurveyDatumGrad = "";
    var latWGS84 = ""; 
    var lngWGS84 = ""; 
    var latWGS84Grad = ""; 
    var lngWGS84Grad = ""; 
    var latPH = ""; 
    var lngPH = ""; 
    var latPHGrad = ""; 
    var lngPHGrad = ""; 
    var latPHWGS84 = ""; 
    var lngPHWGS84 = ""; 
    var latPHWGS84Grad = ""; 
    var lngPHWGS84Grad = ""; 
    var latMH = ""; 
    var lngMH = ""; 
    var latMHGrad = ""; 
    var lngMHGrad = ""; 
    var latMHWGS84 = ""; 
    var lngMHWGS84 = ""; 
    var latMHWGS84Grad = ""; 
    var lngMHWGS84Grad = ""; 
  
      this.restApi.getMainEvent(this.idpos_param, token).subscribe((data: {}) => {
        this.ListMainEventTemp = data;
        var len = this.ListMainEventTemp.length;
        this.getWellWGS84Coord(this.idpos_param);
        //console.log('len openlargemodal mainevent = ' + len );
        if(sessionStorage.getItem("lenME|" + this.idpos_param)){
            var lenSS = sessionStorage.getItem("lenME|" + this.idpos_param);
            //console.log('len openlargemodal lenSS = ' + lenSS );
            if(len > lenSS){
              this.ListMainEvent = data;
              if(len > 0){
                this.showmainevent = true;
              }else{
                this.showmainevent = false;
              }
            }
        }else{
          sessionStorage.setItem("lenME|" + this.idpos_param, len);
          this.ListMainEvent = this.ListMainEventTemp;
        }
  
        //console.log('ListMainEvent = ' + JSON.stringify(this.ListMainEvent));
      });
    
      if(this.Posizionamento.length == 0){
        this.restApi.getPosizionamento(this.idpos_param, token).subscribe((data: {}) => {
          this.Posizionamento = data;
          
          this.Posizionamento.forEach((currentValue, index) => {
            this.ListDocuments = [];
            var codiceprogetto = currentValue.codiceprogetto;
            var codicepozzo = currentValue.codicepozzo;
            var nomepozzo = currentValue.nomepozzo;
            var idpiattaforma = currentValue.idpiattaforma;
            var piattaforma = currentValue.piattaforma;
            var anno = currentValue.anno;
            var idnazione = currentValue.idnazione;
            this.idnazione = idnazione;
            var nazione = currentValue.nazione;
            var idcliente = currentValue.idcliente;
            var cliente = currentValue.cliente;
  
            if(codicepozzo == null || codicepozzo == ""){
              this.titleWell = codiceprogetto + " " + nomepozzo;
            }else{
              this.titleWell = codicepozzo + " " + nomepozzo + " " + codiceprogetto;
            }
            //console.log('titlewell = ' + this.titleWell);
            
            if(currentValue.tipoSurveyDatum != " " && currentValue.tipoSurveyDatum != null && currentValue.tipoSurveyDatum != undefined){
              tipoSurveyDatum = currentValue.tipoSurveyDatum;
            }else{
              tipoSurveyDatum = "";
            } 
  
            if(currentValue.latSurveyDatum != " " && currentValue.latSurveyDatum != null && currentValue.latSurveyDatum != undefined){
              latSurveyDatum = currentValue.latSurveyDatum;
            }else{
              latSurveyDatum = "";
            }
  
            if(currentValue.lngSurveyDatum != " " && currentValue.lngSurveyDatum != null && currentValue.lngSurveyDatum != undefined){
              lngSurveyDatum = currentValue.lngSurveyDatum;
            }else{
              lngSurveyDatum = "";
            }
  
            if(currentValue.latSurveyDatumGrad != " " && currentValue.latSurveyDatumGrad != null && currentValue.latSurveyDatumGrad != undefined){
              latSurveyDatumGrad = currentValue.latSurveyDatumGrad;
            }else{
              latSurveyDatumGrad = "";
            }
  
            if(currentValue.lngSurveyDatumGrad != " " && currentValue.lngSurveyDatumGrad != null && currentValue.lngSurveyDatumGrad != undefined){
              lngSurveyDatumGrad = currentValue.lngSurveyDatumGrad;
            }else{
              lngSurveyDatumGrad = "";
            }
  
            if(currentValue.latWGS84 != " " && currentValue.latWGS84 != null && currentValue.latWGS84 != undefined){
              latWGS84 = currentValue.latWGS84;
            }else{
              latWGS84 = "";
            }
            
            if(currentValue.lngWGS84 != " " && currentValue.lngWGS84 != null && currentValue.lngWGS84 != undefined){
              lngWGS84 = currentValue.lngWGS84;
            }else{
              lngWGS84 = "";
            }
           
            if(currentValue.latWGS84Grad != " " && currentValue.latWGS84Grad != null && currentValue.latWGS84Grad != undefined){
              latWGS84Grad = currentValue.latWGS84Grad;
            }else{
              latWGS84Grad = "";
            }
  
            if(currentValue.lngWGS84Grad != " " && currentValue.lngWGS84Grad != null && currentValue.lngWGS84Grad != undefined){
              lngWGS84Grad = currentValue.lngWGS84Grad;
            }else{
              lngWGS84Grad = "";
            }
  
            if(currentValue.latPH != " " && currentValue.latPH != null && currentValue.latPH != undefined){
              latPH = currentValue.latPH;
            }else{
              latPH = "";
            }
  
            if(currentValue.lngPH != " " && currentValue.lngPH != null && currentValue.lngPH != undefined){
              lngPH = currentValue.lngPH;
            }else{
              lngPH = "";
            }
  
            if(currentValue.latPHGrad != " " && currentValue.latPHGrad != null && currentValue.latPHGrad != undefined){
              latPHGrad = currentValue.latPHGrad;
            }else{
              latPHGrad = "";
            }
  
            if(currentValue.lngPHGrad != " " && currentValue.lngPHGrad != null && currentValue.lngPHGrad != undefined){
              lngPHGrad = currentValue.lngPHGrad;
            }else{
              lngPHGrad = "";
            }
  
            if(currentValue.latPHWGS84 != " " && currentValue.latPHWGS84 != null && currentValue.latPHWGS84 != undefined){
              latPHWGS84 = currentValue.latPHWGS84;
            }else{
              latPHWGS84 = "";
            }
  
            if(currentValue.lngPHWGS84 != " " && currentValue.lngPHWGS84 != null && currentValue.lngPHWGS84 != undefined){
              lngPHWGS84 = currentValue.lngPHWGS84;
            }else{
              lngPHWGS84 = "";
            }
  
            if(currentValue.latPHWGS84Grad != " " && currentValue.latPHWGS84Grad != null && currentValue.latPHWGS84Grad != undefined){
              latPHWGS84Grad = currentValue.latPHWGS84Grad;
            }else{
              latPHWGS84Grad = "";
            }
  
            if(currentValue.lngPHWGS84Grad != " " && currentValue.lngPHWGS84Grad != null && currentValue.lngPHWGS84Grad != undefined){
              lngPHWGS84Grad = currentValue.lngPHWGS84Grad;
            }else{
              lngPHWGS84Grad = "";
            }
  
            if(currentValue.latMH != " " && currentValue.latMH != null && currentValue.latMH != undefined){
              latMH = currentValue.latMH;
            }else{
              latMH = "";
            }
  
            if(currentValue.lngMH != " " && currentValue.lngMH != null && currentValue.lngMH != undefined){
              lngMH = currentValue.lngMH;
            }else{
              lngMH = "";
            }
  
            if(currentValue.latMHGrad != " " && currentValue.latMHGrad != null && currentValue.latMHGrad != undefined){
              latMHGrad = currentValue.latMHGrad;
            }else{
              latMHGrad = "";
            }
  
            if(currentValue.lngMHGrad != " " && currentValue.lngMHGrad != null && currentValue.lngMHGrad != undefined){
              lngMHGrad = currentValue.lngMHGrad;
            }else{
              lngMHGrad = "";
            }
  
            if(currentValue.latMHWGS84 != " " && currentValue.latMHWGS84 != null && currentValue.latMHWGS84 != undefined){
              latMHWGS84 = currentValue.latMHWGS84;
            }else{
              latMHWGS84 = "";
            }
  
            if(currentValue.lngMHWGS84 != " " && currentValue.lngMHWGS84 != null && currentValue.lngMHWGS84 != undefined){
              lngMHWGS84 = currentValue.lngMHWGS84;
            }else{
              lngMHWGS84 = "";
            }
  
            if(currentValue.latMHWGS84Grad != " " && currentValue.latMHWGS84Grad != null && currentValue.latMHWGS84Grad != undefined){
              latMHWGS84Grad = currentValue.latMHWGS84Grad;
            }else{
              latMHWGS84 = "";
            }
  
            if(currentValue.lngMHWGS84Grad != " " && currentValue.lngMHWGS84Grad != null && currentValue.lngMHWGS84Grad != undefined){
              lngMHWGS84Grad = currentValue.lngMHWGS84Grad;
            }else{
              lngMHWGS84Grad = "";
            }
  
            if(tipoSurveyDatum.trim() != "" && tipoSurveyDatum.trim() != null){
              this.tipoSurveyDatum = tipoSurveyDatum;
            }
            if(latSurveyDatum.trim() != "" && latSurveyDatum.trim() != null){
              this.latSurveyDatum = latSurveyDatum;
            }
            if(lngSurveyDatum.trim() != "" && lngSurveyDatum.trim() != null){
              this.lngSurveyDatum = lngSurveyDatum;
            }
  
            if(latSurveyDatumGrad.trim() != "" && latSurveyDatumGrad.trim() != null){
              this.latSurveyDatumGrad = latSurveyDatumGrad.replace('? ', '°').replace('?', '°');
            }
  
            if(lngSurveyDatumGrad.trim() != "" && lngSurveyDatumGrad.trim() != null){
              this.lngSurveyDatumGrad = lngSurveyDatumGrad.replace('? ', '°').replace('?', '°');
            }
  
            if(tipoSurveyDatum.trim() != "" && tipoSurveyDatum.trim() != null){
              ++count;
              this.ListDocuments.push({ idallegato: count, descrizione: tipoSurveyDatum, latitude:this.latSurveyDatumGrad, longitude:this.lngSurveyDatumGrad, pathfile: "", idtipo: "SD" } );
            }
            
            if(latWGS84.trim() != "" && latWGS84.trim() != null){
              this.latWGS84 = latWGS84;
            }
            if(lngWGS84.trim() != "" && lngWGS84.trim() != null){
              this.lngWGS84 = lngWGS84;
            }
  
            if(latWGS84Grad.trim() != "" && latWGS84Grad.trim() != null){
              this.latWGS84Grad = latWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(lngWGS84Grad.trim() != "" && lngWGS84Grad.trim() != null){
              this.lngWGS84Grad = lngWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(lngWGS84.trim() != "" && lngWGS84.trim() != null){
              this.coordinates = latWGS84 + "%" + latWGS84Grad.replace('? ', '°').replace('?', '°') + "|" + lngWGS84 + "%" + lngWGS84Grad.replace('? ', '°').replace('?', '°');
              ++count;
              this.ListDocuments.push({ idallegato: count, descrizione: "WGS84", latitude:this.latWGS84Grad, longitude:this.lngWGS84Grad, pathfile: "", idtipo: "WGS84" } );
            }
  
            if(latPH.trim() != "" && latPH.trim() != null){
              this.latPH = latPH;
            }
  
            if(lngPH.trim() != "" && lngPH.trim() != null){
              this.lngPH = lngPH;
            }
            if(latPHGrad.trim() != "" && latPHGrad.trim() != null){
              this.latPHGrad = latPHGrad;
            }
            if(lngPHGrad.trim() != "" && lngPHGrad.trim() != null){
              this.lngPHGrad = lngPHGrad;
            }
  
            if(latPHGrad.trim() != "" && lngPHGrad != "" && latPHGrad.trim() != null && lngPHGrad.trim() != null){
              ++count;
              this.coordinates = latPH + "%" + latPHGrad.replace('? ', '°').replace('?', '°') + "|" + lngPH + "%" + lngPHGrad.replace('? ', '°').replace('?', '°');
              this.ListDocuments.push({ idallegato: count, descrizione: "Pilot Hole", latitude:this.latPHGrad, longitude:this.lngPHGrad, pathfile: "", idtipo: "PH" } );
            }
  
            if(latPHWGS84.trim() != "" && latPHWGS84.trim() != null){
              this.latPHWGS84 = latPHWGS84;
            }
            if(lngPHWGS84.trim() != "" && lngPHWGS84.trim() != null){
              this.lngPHWGS84 = lngPHWGS84;
            }
            if(latPHWGS84Grad.trim() != "" && latPHWGS84Grad.trim() != null){
              this.latPHWGS84Grad = latPHWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(lngPHWGS84Grad.trim() != "" && lngPHWGS84Grad.trim() != null){
              this.lngPHWGS84Grad = lngPHWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(latPHWGS84Grad.trim() != "" && lngPHWGS84Grad != "" && latPHWGS84Grad.trim() != null && lngPHWGS84Grad.trim() != null){
              ++count;
              this.coordinates = latPHWGS84 + "%" + latPHWGS84Grad.replace('? ', '°').replace('?', '°') + "|" + lngPHWGS84 + "%" + lngPHWGS84Grad.replace('? ', '°').replace('?', '°');
              this.ListDocuments.push({ idallegato: count, descrizione: "Pilot Hole - Datum: WGS84", latitude:this.latPHWGS84Grad, longitude:this.lngPHWGS84Grad, pathfile: "", idtipo: "PH" } );
            }
  
            if(latMH.trim() != "" && latMH.trim() != null){
              this.latMH = latMH;
            }
            if(lngMH.trim() != "" && latMH.trim() != null){
              this.lngMH = lngMH;
            }
            if(latMHGrad.trim() != "" && latMHGrad.trim() != null){
              this.latMHGrad = latMHGrad;
            }
            if(lngMHGrad.trim() != "" && lngMHGrad.trim() != null){
              this.lngMHGrad = lngMHGrad;
            }
  
            if(latMHGrad.trim() != "" && lngMHGrad != "" && latMHGrad.trim() != null && lngMHGrad.trim() != null){
              ++count;
              this.coordinates = latMH + "%" + latMHGrad.replace('? ', '°').replace('?', '°') + "|" + lngMH + "%" + lngMHGrad.replace('? ', '°').replace('?', '°');
              this.ListDocuments.push({ idallegato: count, descrizione: "Main Hole", latitude:this.latMHGrad, longitude:this.lngMHGrad, pathfile: "", idtipo: "MH" } );
            }
  
            if(latMHWGS84.trim() != "" && latMHWGS84.trim() != null){
              this.latMHWGS84 = latMHWGS84;
            }
            if(lngMHWGS84.trim() != "" && lngMHWGS84.trim() != null){
              this.lngMHWGS84 = lngMHWGS84;
            }
            if(latMHWGS84Grad.trim() != "" && latMHWGS84Grad.trim() != null){
              this.latMHWGS84Grad = latMHWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(lngMHWGS84Grad.trim() != "" && lngMHWGS84Grad.trim() != null){
              this.lngMHWGS84Grad = lngMHWGS84Grad.replace('? ', '°').replace('?', '°');
            }
  
            if(latMHWGS84.trim() != "" && lngMHWGS84.trim() != "" && latPHWGS84.trim() != null && lngPHWGS84.trim() != null){
              this.coordinates = latMHWGS84 + "%" + this.latMHWGS84Grad + "|" + lngMHWGS84 + "%" + this.lngMHWGS84Grad + "|" + latPHWGS84 + "%" + this.latPHWGS84Grad + "|" + lngPHWGS84 + "%" + this.lngPHWGS84Grad;
            }
  
            if(latMHWGS84Grad.trim() != "" && lngMHWGS84Grad.trim() != "" && latMHWGS84Grad.trim() != null  && lngMHWGS84Grad.trim() != null){
              ++count;
              this.ListDocuments.push({ idallegato: count, descrizione: "Main Hole - Datum: WGS84", latitude:this.latMHWGS84Grad, longitude:this.lngMHWGS84Grad, pathfile: "", idtipo: "MH" } );
            }
  
            this.restApi.getAttachments(this.idpos_param, token).subscribe((data: {}) => {
              this.ListAttachments = data;
              //sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
                  
              this.ListAttachments.forEach( (currentValue, index) => {
                var idallegato = currentValue.idallegato;
                var descrizione = currentValue.descrizione;
                var pathfile = currentValue.pathfile;
                var idtipo = currentValue.idtipo;
  
                ++count;
                this.ListDocuments.push({ idallegato: count, descrizione: descrizione, latitude:"", longitude:"", pathfile: pathfile, idtipo: idtipo} );
        
  
                // //verbale di posizionamento
                // if(idtipo == "1"){
                //    this.vdp_desc = descrizione;
                //    this.vdp_path = pathfile; 
                // }
                
                // //final report
                // if(idtipo == "2"){
                //   this.finalreport_desc = descrizione;
                //   this.finalreport_path = pathfile;
                // }
  
                // //documents
                // if(idtipo == "3"){
                //   this.isdoc = true;
                //   this.ListDocuments.push({ idallegato: idallegato, descrizione: descrizione, pathfile: pathfile } );
                // }
  
                // //vdp mh
                // if(idtipo == "4"){
                //   this.vdpmh_desc = descrizione;
                //   this.vdpmh_path = pathfile
                // }
  
                // //vdp ph
                // if(idtipo == "5"){
                //   this.vdpph_desc = descrizione;
                //   this.vdpph_path = pathfile
                // }
  
              });
  
  
            });
  
  
            this.piattaforma = piattaforma;
            this.nazione = nazione;
            this.anno = anno;
            
            $('#modaldata1').css("display","block");
            //this.sharedatalst = "";
            //this.data.changeMessage("");
        });
  
        
      });
      }
  
  }
  
  openpdf(file){
    PDFObject.embed("https://rcs.explorermarinesboss.com/api/uploads/" + file, "#report");
    $('#report').show(); 
    $('#mapcontainer').hide(); 
    $('#searchmodal').hide(); 
    $('#in').hide();
    $('#print').show();
    $('#modaldata1').css("display","none");
  }
  
  closepdf(){
    $('#report').hide();
    $('#mapcontainer').show();
    $('#searchmodal').hide(); 
    $('#in').show();
    $('#print').hide();
    $('#modaldata1').css("display","block");
  }
  
  opensearch(){
    $('#report').hide(); 
    $('#mapcontainer').hide(); 
    $('#searchmodal').show(); 
    $('#in').hide();
    $('#print').hide();
    $('#modaldata1').css("display","none");
  }
  
  closesearch(){
    $('#report').hide();
    $('#mapcontainer').hide();
    $('#searchmodal').hide(); 
    $('#in').hide();
    $('#print').hide();
    $('#modaldata1').css("display","block");
  }

//#endregion

//#region gets
getTipologiaFile(){
  return this.restApi.getAttachmentsType(this.token).subscribe((data: {}) => {
    this.ListTipFile = data;
  });
}

getListKmlWell(id){
  return this.restApi.getKmlForWell(id,this.token).subscribe((data: {}) => {
    this.ListKmlWell = data;
  });
}

getPiattaformeUtente(idutente, stato, token){
  //console.log('=> getPiattaformeUtente');
  return this.restApi.getPiattaformeAttive(idutente, stato, token).subscribe((data: {}) => {
    this.ListPlatform = data;
    sessionStorage.setItem('platforms', JSON.stringify(this.ListPlatform));
    //console.log('platform: ' + JSON.stringify(this.ListPlatform));
  });
}

getDataSingleRig(idplatform, stealth){
  var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  //console.log('idplatform getDataSingleRig = ' + idplatform);

  this.restApi.getMainEventPlatform(idplatform, token).subscribe((data: {}) => {
    this.ListMainEventTemp = data;
    var len = this.ListMainEventTemp.length;
    if(sessionStorage.getItem("lenMERIG|" + idplatform)){
        var lenSS = sessionStorage.getItem("lenMERIG|" + idplatform);
        if(len > lenSS){
          this.ListMainEvent = data;
          if(len > 0){
            this.showmainevent = true;
          }else{
            this.showmainevent = false;
          }
        }
      }else{
        sessionStorage.setItem("lenMERIG|" + this.idpos_param, len);
        this.ListMainEvent = this.ListMainEventTemp;
        this.showmainevent = true;
      }      
  });

  this.restApi.getPiattaforma(idplatform, token).subscribe((data: {}) => {
    this.ListPlatformSel = data;
    var dynamicname = 'marker';
    var dynamicnumb = 'numb';
    //create an empty list to hold each marker
        
    this.ListPlatformSel.forEach( (currentValue, index) => {
      var processrow = "0";
      var idplatform = currentValue.idpiattaforma;
      this.idpiattaforma = idplatform;
      var idplatform_user = currentValue.idpiattaforma_utente;
      $('#idpiattaformautente').val(idplatform_user);
      var desc = currentValue.denominazione;
      //console.log('desc** = ' + desc);
      var latitude = currentValue.latitudine;
      this.latrig = latitude;
      //console.log('latitude** = ' + latitude);
      var longitude = currentValue.longitudine;
      this.lngrig = longitude;
      //console.log('longitude** = ' + longitude);
      var latitudeg = currentValue.latitudine_gradi;
      //console.log('latitudeg** = ' + latitudeg);
      var longitudeg = currentValue.longitudine_gradi;
      //console.log('longitudeg** = ' + longitudeg);
      var platform_type = currentValue.tipo_piattaforma;
      var heading = currentValue.heading;
      var covalue = currentValue.covalue;
      var headingvalue = currentValue.heading.replace("T", "").trim();
      var headingadjust = 0;
      var path_image = currentValue.path_image;
      var referencepoint = currentValue.referencepoint;
      var twentyfouractivity = currentValue.twentyfouractivity;

      //this.idplatform_user = id;
      var split_latg = latitudeg.split('|');
      var ltgrad = split_latg[0];
      if(ltgrad.length == 1){
        ltgrad = "0" + ltgrad;
      }
      var ltfirst = split_latg[1];
      if(ltfirst.length == 1){
        ltfirst = "0" + ltfirst;
      }
      var ltsecond = split_latg[2];

      var ltsecondsplit1 = ltsecond.toString().split(',')[0];
      var ltsecondsplit2 = ltsecond.toString().split(',')[1];

      if(ltsecondsplit1.length == 1){
        ltsecond = "0" + ltsecondsplit1 + "," + ltsecondsplit2;
      }

      // var lgsecondsplit = ltsecond.toString().split(',')[0];

      // if(lgsecondsplit.length == 1){
      //   ltsecond = "0" + ltsecond;
      // }
      var ltheading = split_latg[3];
  
      var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","") + "\" " + ltheading;
      //console.log('latdef** = ' + latdef);
      var split_long = longitudeg.split('|');
      var lggrad = split_long[0];
      if(lggrad.length == 1){
        lggrad = "00" + lggrad;
      }
      if(lggrad.length == 2){
        lggrad = "0" + lggrad;
      }
      var lgfirst = split_long[1];
      if(lgfirst.length == 1){
        lgfirst = "0" + lgfirst;
      }
      var lgsecond = split_long[2];

      var lgsecondsplit1 = lgsecond.toString().split(',')[0];
      var lgsecondsplit2 = lgsecond.toString().split(',')[1];

      if(lgsecondsplit1.length == 1){
        lgsecond = "0" + lgsecondsplit1 + "," + lgsecondsplit2;
      }

      var lgheading = split_long[3];
      
      var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","") + "\" " + lgheading;
      //console.log('lngdef** = ' + lngdef);
      var hdg_round = parseFloat(heading).toFixed(1);
      //console.log('hdg_round** = ' + hdg_round);

  
      $('#title').text(desc);
      $('#latitude').text(latdef);
      $('#longitude').text(lngdef);
      if(heading != ""){
        $('#heading').text(hdg_round.toString() + "° T");
      }
      $('#idpiattaformautente').val(idplatform_user);
      $('#imageplatform').attr("src", path_image);
      //if(maplocation == "true"){
      //  this.map.flyTo([latitude, longitude], 18);
      //}
      if(stealth == "false"){
        $('#modaldata').css("display","block");
        var width = $(window).width();
        var widthdiv2= width/2;
        var leftposition = widthdiv2 -200;
        $('#modaldata').css("margin-left", leftposition);
        //console.log('width screen = '+ width);
        $('#referencepoint').text(referencepoint);
        //twentyfouractivity = "Aww yeah, you successfully read this important alert message.";
        if(twentyfouractivity != null){
          $('#twentyfouractivity').text("Next 24 hour activity: " + twentyfouractivity);
        }else{
          $('#twentyfouractivity').text("Next 24 hour activity: ");
        }
        sessionStorage.setItem('openpopup', 'true');
      }else{
        $('#modaldata').css("display","none");
        sessionStorage.setItem('openpopup', 'false');
      }

      this.sharedatalst = "";
      this.data.changeMessage("");
    });
  });
}

getActiveKML(id){
  var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  //console.log('idplatform** = ' + idplatform);

  this.restApi.getSingleKmlForUser(id, token).subscribe((data: {}) => {
    this.ListSingleKml = data;
        
    this.ListSingleKml.forEach( (currentValue, index) => {
      var processrow = "0";
      var idkmz_user = currentValue.idkmz_user;
      var iduser = currentValue.iduser;
      //$('#idpiattaformautente').val(idplatform_user);
      var idkmz = currentValue.idkmz;
      //console.log('idkmz** = ' + idkmz);
      var kmldesc = currentValue.kmldesc;
      //console.log('kmldesc** = ' + kmldesc);
      var kmlpath = currentValue.kmlpath;
      //console.log('kmlpath** = ' + kmlpath);
      var kmltype = currentValue.kmltype;
      //console.log('kmltype** = ' + kmltype);
      var kmlactive = currentValue.kmlactive;
      //console.log('kmlactive** = ' + kmlactive);
      var changekmlactive = "0";
            
      this.sharedatalst = "";
      this.data.changeMessage("");
    });
  });
  //window.location.reload();
  //this.resetMap();
  //location.reload();
  // this.map.off();
  // this.map.remove();
  // this.initMap();
}

getWellWGS84Coord(id){
  this.restApi.getPosizionamentiCoordinateSingleForPozzoWGS84(id, this.token).subscribe((data: {}) => {
    this.ListCoordWGS84Temp = data;
    //sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
        
    this.ListCoordWGS84Temp.forEach( (currentValue, index) => {
      var latitude = currentValue.latitude;
      var latitudegrad = currentValue.latitudegrad;
      var longitude = currentValue.longitude;
      var longitudegrad = currentValue.longitudegrad;

      this.coordinates = latitude + "%" + latitudegrad.replace('? ', '°').replace('?', '°') + "|" + longitude + "%" + longitudegrad.replace('? ', '°').replace('?', '°');
      //this.coordinates = latitude + "|" + longitude;
      //console.log('coordinates = ' + this.coordinates);

    });

});
}
//#endregion

//#region functions

actionOpenData() {
  this.subscription = this.data.currentMessage.subscribe(sharedatalst => this.sharedatalst = sharedatalst);
  //console.log('sharedatalst = ' + this.sharedatalst);
  //this.sharedatalst = sessionStorage.getItem("actrqst");
  if(this.sharedatalst != ""){
    this.sharedatabkp = this.sharedatalst;
    
    var arr: any = [];
    arr = this.sharedatalst.split('|');
    var len = arr.length;
    var type = arr[0].toString();


    switch(type){
      case "POS":
        //data positioning
        //year=2022&nation=240&idpos=27
        var year = arr[1].toString();
        var nation = arr[2].toString();
        var idpos = arr[3].toString();
        var activepos = "";
        if(len == 5){
          activepos = arr[4].toString();
        }
        
        this.idpos_param = idpos;
        //console.log('idpos_param = ' + this.idpos_param);
        this.yearsel = year;
        //console.log('yearsel = ' + this.yearsel);
        this.nationsel = nation;
        //console.log('nationsel = ' + this.nationsel);
        //var activepos = activepos;

        if(activepos == 'true'){
          this.isActivated = true;
          this.activatedAllPositioning();
        }
        
        // if(activepos == 'false'){
        //   this.isActivated = false;
        //   this.resetMap();
        // }

        if(this.idpos_param != "" && this.idpos_param != undefined){
          //console.log('idparam = ' + this.idpos_param);
          this.running = true;
          this.openlargemodalextend();
        }
        break;
      case "RIG":
        //data rig
        //id=1%7C43.04131%7C14.001189483333333
        //"RIG|" + id + "|" + lat + "|" + lng; 
        var arr: any = [];
        arr = this.sharedatalst.split('|');
        var len = arr.length;
        var id = arr[1];
        var lat = arr[2];
        var lng = arr[3];

        if(id){
          sessionStorage.setItem('idplatform', id);
          $('#idpiattaforma').val(id);
          sessionStorage.setItem('latsession', lat);
          sessionStorage.setItem('lngsession', lng);
          this.map.zoom = 12;
          //this.setCurrentZoom();
          //this.setCoordinatesCenterMap();
          this.running = true;
          this.getDataSingleRig(id, "false");
        }
        break;
      case "KML":
        arr = this.sharedatalst.split('|');
        var len = arr.length;
        var id = arr[1];
        //console.log('id kml = ' + id);
        this.running = true;
        this.getActiveKML(id);
        
        break;
      case "ACTIVEPOS":
        arr = this.sharedatalst.split('|');
        var len = arr.length;
        var value = arr[1];
        if(value == "true"){
            this.isActivated = true;
            this.activatedAllPositioning();
        }            
        break;
      case "SEARCH":
        
        break;
    }
  }

}

resetMap(){
  //#1) delete all marker
  this.setMarkers();
  //#2) load kml
  this.loadKml();
  //#3) load rig
  //#4) load positioning

}

calculate(){
  var value = this.calculator;
  var first = value.split('°')[0].toString();
  var prime = value.split(' \'')[0].toString();
  var prime1 = prime.split('°')[1].toString();
  var prime2 = prime1.split('\'')[0].toString();
  var second = value.split('\'')[1].toString();
  //console.log('value' + value);
  //console.log('first' + first);
  //console.log('prime' + prime2);
  //console.log('second' + second);
  var calc1 = parseFloat(second) / 60;
  var calc2 = parseFloat(prime2) + calc1;
  var calc3 = calc2/60;
  var calc4 = parseFloat(first) + calc3;
  this.calculator = calc4.toString().replace(".",",");
}

maplocation(idpos, idnation, pozzo, coordinates){

  //('idpos' + idpos);
  //console.log('idnation' + idnation);
  //console.log('pozzo' + pozzo);
  //console.log('coordinates' + coordinates);

  const fontAwesomeIcon = L.divIcon({
    html: '<i class="fa fa-dot-circle-o fa-2x" aria-hidden="true"></i>',
    iconSize: [20, 20],
    className: 'myDivIcon'
  });
  
  var coord = [];
  if(coordinates){
    coord = coordinates.split('|');
  

  var len = coord.length;
  
  var lat1 = "";
  var lat1grad = "";
  var lng1 = "";
  var lng1grad = "";
  var lat2 = "";
  var lat2grad = "";
  var lng2 = "";
  var lng2grad = "";

  var icon_normal_blue = new L.icon({
    iconUrl: 'http://rcs.explorermarinesboss.com/api/images/favorite_41_blue.png',
    iconAnchor: [12, 41],
    popupAnchor: [0, -41]
  });

  var icon_normal_red = new L.icon({
    iconUrl: 'http://rcs.explorermarinesboss.com/api/images/favorite_41_red.png',
    iconAnchor: [12, 41],
    popupAnchor: [0, -41]
  });

  if(len == 2){
    //console.log('len = 2');
    var lat_arr = coord[0].split('%');
    var lng_arr = coord[1].split('%');

    lat1 = lat_arr[1].replace(',','.');
    //console.log('lat1 = ' + lat1);
    lat1grad = lat_arr[0];
    lng1 = lng_arr[1].replace(',','.');
    //console.log('lng1 = ' + lng1);
    lng1grad = lng_arr[0];

    this.markers[idpos] = new L.Marker([lat1, lng1], {
      icon: fontAwesomeIcon,
      title: pozzo + " - [" + lat1grad + " - " + lng1grad + "]"
    }).bindTooltip(pozzo + " - [" + lat1grad + " - " + lng1grad + "]", 
    {
        permanent: true, 
        direction: 'left'
    })
    .on('click', this.openlargemodalnavigate(idpos, idnation)).addTo(this.map);
    sessionStorage.setItem('latsession', lat1);
    sessionStorage.setItem('lngsession', lng1);

    this.map.flyTo([lat1, lng1], 18);
     $('#modaldata1').css("margin-left","350px");

  }

  if(len == 4){
    //console.log('len = 4');
    var lat1_arr = coord[0].split('%');
    var lng1_arr = coord[1].split('%');

    lat1 = lat1_arr[0].replace(',','.');
    lat1grad = lat1_arr[1];
    lng1 = lng1_arr[0].replace(',','.');
    lng1grad = lng1_arr[1];

    var lat2_arr = coord[2].split('%');
    var lng2_arr = coord[3].split('%');

    lat2 = lat2_arr[0].replace(',','.');
    lat2grad = lat2_arr[1];
    lng2 = lng2_arr[0].replace(',','.');
    lng2grad = lng2_arr[1];


    this.markers[idpos] = new L.Marker([lat1, lng1], {
      icon: fontAwesomeIcon,
      title: pozzo + " - Main Hole" + "[" + lat1grad + " - " + lng1grad + "]"
    })
    .bindTooltip(pozzo + " - Main Hole" + "[" + lat1grad + " - " + lng1grad + "]", 
    {
        permanent: true, 
        direction: 'left'
    })
    .on('click', this.openlargemodalnavigate(idpos, idnation)).addTo(this.map);

    this.markers[idpos] = new L.Marker([lat2, lng2], {
      icon: fontAwesomeIcon,
      title: pozzo + " - Pilot Hole" + "[" + lat2grad + " - " + lng2grad + "]"
    })
    .bindTooltip(pozzo + " - Pilot Hole" + "[" + lat2grad + " - " + lng2grad + "]", 
    {
        permanent: true, 
        direction: 'right'
    })
    .on('click', this.openlargemodalnavigate(idpos, idnation)).addTo(this.map);

    //this.map.panTo(new L.LatLng(lat1, lng1));
    sessionStorage.setItem('latsession', lat1);
    sessionStorage.setItem('lngsession', lng1);

    this.map.flyTo([lat1, lng1], 18);
    $('#modaldata1').css("margin-left","350px");
    }
  }

  var lenCoord = this.ListCoordinates.length;

  if(lenCoord > 0){
    this.ListCoordinates.forEach( (currentValue, index) => {
      var idcoord = currentValue.idcoord;
      var idposizionamento = currentValue.idposizionamento;
      var descrizione = currentValue.descrizione;
      var idtipologia = currentValue.idtipologia;
      var idtipo = currentValue.idtipo;
      var tipologia = currentValue.tipologia;
      var latitude = currentValue.latitude.replace(',','.');
      this.lattemp = latitude;
      var latitudegrad = currentValue.latitudegrad;
      var longitude = currentValue.longitude.replace(',','.');
      this.lngtemp = longitude;
      var longitudegrad = currentValue.longitudegrad;
      var ordine = currentValue.ordine;
      var desctype = "";

      if(idtipologia == "1"){
        desctype = "Main Hole";
      }

      if(idtipologia == "2"){
        desctype = "Pilot Hole";
      }

      this.markers[idpos] = new L.Marker([latitude, longitude], {
        icon: fontAwesomeIcon,
        title: pozzo + " - " + desctype + "[" + latitudegrad + " - " + longitudegrad + "]"
      })
      .bindTooltip(pozzo + " - " + desctype + "[" + latitudegrad + " - " + longitudegrad + "]", 
      {
          permanent: true, 
          direction: 'right'
      })
      .on('click', this.openlargemodalnavigate(idpos, idnation)).addTo(this.map);

    });
    this.map.flyTo([this.lattemp, this.lngtemp], 18);
    $('#modaldata1').css("margin-left","350px");
  }

}

activeAllKml(){
  this.ListKmlWell.forEach((currentValue, index) => {
    var idkmz = currentValue.idkmz;
    var active = currentValue.active;
    this.changeStatusKml(idkmz, active);
  });
}

loadKmlForWell(){
  var kmz = L.kmzLayer().addTo(this.map);

  this.ListKmlWell.forEach( (currentValue, index) => {
    var idkmz = currentValue.idkmz;
    var path = currentValue.path;
    var active = currentValue.active;
    if(active == "1"){
      kmz.load(path);    
    }
  });

}

changeStatusKml(idkmz, active){
  
  var newactive = "0";
  
  if(active == "0"){
    newactive = "1";
  }

  var upd = {
    "active": newactive,
    "idkmz": idkmz
  }

  this.restApi.updateStatusKmlUtente(upd).subscribe((data: {}) => {
    this.restApi.getKmlForWell(this.idpos_param,this.token).subscribe((data: {}) => {
      this.ListKmlWell = [];
      this.ListKmlWell = data;
    });
  });

  this.loadKmlForWell();

}

setStatusTabKml(){
  if(this.activekmltab == "general"){
    this.activekmltab = "upload";
  }else{
    this.activekmltab = "general";
  }
}

setStatusTabDoc(){
  if(this.activedoctab == "general"){
    this.activedoctab = "upload";
  }else{
    this.activedoctab = "general";
  }
}

uploadFiles = (files, type, content) => {
    
  if (files.length === 0) {
    this.filenameUpload = "";
    return;
  }

  let fileToUpload = <File>files[0];
  const formData = new FormData();
  formData.append('avatar', fileToUpload, fileToUpload.name);
  //formData.append('avatar', this.form.get('avatar').value);
  this.filenameUpload = fileToUpload.name;
  if(type == "kml"){
    ++this.countfiles;
    this.ListFilesTemp.push({"IdFile": this.countfiles.toString(),"File": fileToUpload.name});
    //console.log('ListFilesTemp = ' + JSON.stringify(this.ListFilesTemp));
  }else{
    ++this.countfiles2;
    this.ListFilesTemp2.push({"IdFile": this.countfiles2.toString(),"File": fileToUpload.name});
    //console.log('ListFilesTemp2 = ' + JSON.stringify(this.ListFilesTemp2));
  }

  
  this.filenameUpload = fileToUpload.name;

  var filename = this.filenameUpload.split('.')[0];
  var ext = this.filenameUpload.split('.')[1];

  this.filenameUploadMod = filename + '_MOD.' + ext.toLowerCase();

  if (ext == ext.toUpperCase()){
     var message = "Attenzione: l'estensione del file non può contenere lettere maiuscole.";
     var action = "ok"
     this.openSnackBar(message, action);
     return;
  }

 //Upload kml
  this.restApi.uploadKmlWell(formData).subscribe((data: {}) => {
 
      var utente = sessionStorage.getItem("username");
        
      if(type == "kml"){

        var server_url = 'http://rcs.explorermarinesboss.com/api/uploads/Positioning/' + this.anno + '/' + this.codiceprogettoint + '/' + this.filenameUploadMod.toLowerCase();
        
        
        //Trasformation kml
        
        this.restApi.getKmlTrasformationEngine(this.idpos_param, this.anno, this.codiceprogettoint, this.filenameUpload.toLowerCase(), this.token).subscribe((data: {}) => {
          this.ListAttachments = data;
          //sessionStorage.setItem('ListKml', JSON.stringify(this.ListKml));
          var kmz = {
            "descrizione": this.filenameUploadMod.toLowerCase(),
            "path": server_url,
            "type": this.tipologiafile,
            "idposizionamento": this.idpos_param, 
            "idcliente": this.idcliente, 
            "generic": "0", 
            "shape": "0", 
            "active": "1", 
            "token": this.token
          };
          //console.log('attach = ' + JSON.stringify(kmz));
        
          this.restApi.createKmz(kmz).subscribe((data: {}) => {
            //this.pozzoLoad(this.idposizionamento);
            var message = "Importazione kml avvenuta con successo.";
            var action = "ok"
            this.openSnackBar(message, action);

            if(this.activekmltab == "general"){
              this.activekmltab = "upload";
            }else{
              this.activekmltab = "general";
            }

          });              
    
          });

      }else{
        var pathfile = "Positioning/" + this.anno + "/" +  this.codiceprogettoint + "/" + this.filenameUpload;
        var tipfile = this.filenameUpload.split('.')[1];
        var attach = {
          "descrizione": this.filenameUpload,
          "pathfile": pathfile,
          "idtipo": tipfile,
          "idposizionamento": this.idposizionamento, 
          "token": this.token
        };
        //console.log('attach = ' + JSON.stringify(attach));
      
        this.restApi.createAttach(attach).subscribe((data: {}) => {
          //this.pozzoLoad(this.idposizionamento);
          var message = "Importazione file avvenuta con successo.";
          var action = "ok"
          this.openSnackBar(message, action);
        });
      }
 
      if(this.countfiles > 0){
        //console.log('countfile > 0');
        this.uploadfile_msg = false;
      }else{
        //console.log('countfile = 0');
        this.uploadfile_msg = true;
      }

  });

  //reset input file
  this.myInputVariable.nativeElement.value = "";

}

activatedAllPositioning(){
  const fontAwesomeIcon = L.divIcon({
    html: '<i class="fa fa-dot-circle-o fa-2x" aria-hidden="true" style="color:#0b25ab;"></i>',
    iconSize: [20, 20],
    className: 'myDivIcon'
  });
  if(this.isActivated){
      //active all positioning
      var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
      this.restApi.getPosizionamenti(token).subscribe((data: {}) => {
        this.ListPosizMonitor = [];
        this.ListPosizMonitor = data;
        var tipoSurveyDatum = "";
        var latSurveyDatum = "";
        var lngSurveyDatum = "";
        var latSurveyDatumGrad = "";
        var lngSurveyDatumGrad = "";
        var latWGS84 = ""; 
        var lngWGS84 = ""; 
        var latWGS84Grad = ""; 
        var lngWGS84Grad = ""; 
        var latPH = ""; 
        var lngPH = ""; 
        var latPHGrad = ""; 
        var lngPHGrad = ""; 
        var latPHWGS84 = ""; 
        var lngPHWGS84 = ""; 
        var latPHWGS84Grad = ""; 
        var lngPHWGS84Grad = ""; 
        var latMH = ""; 
        var lngMH = ""; 
        var latMHGrad = ""; 
        var lngMHGrad = ""; 
        var latMHWGS84 = ""; 
        var lngMHWGS84 = ""; 
        var latMHWGS84Grad = ""; 
        var lngMHWGS84Grad = ""; 

        this.ListPosizMonitor.forEach((currentValue, index) => {

          var idposizionamento = currentValue.idposizionamento;
          var codiceprogetto = currentValue.codiceprogetto;
          var codicepozzo = currentValue.codicepozzo;
          var nomepozzo = currentValue.nomepozzo;
          var idpiattaforma = currentValue.idpiattaforma;
          var piattaforma = currentValue.piattaforma;
          var anno = currentValue.anno;
          var idnazione = currentValue.idnazione;
          this.idnazione = idnazione;
          var nazione = currentValue.nazione;
          var idcliente = currentValue.idcliente;
          var cliente = currentValue.cliente;
          var titleWell = "";

          if(codicepozzo == null){
            titleWell = codiceprogetto + " " + nomepozzo;
          }else{
            titleWell = codicepozzo + " " + nomepozzo + " " + codiceprogetto;
          }

          if(currentValue.tipoSurveyDatum != " " && currentValue.tipoSurveyDatum != null && currentValue.tipoSurveyDatum != undefined){
            tipoSurveyDatum = currentValue.tipoSurveyDatum;
          }else{
            tipoSurveyDatum = "";
          } 

          if(currentValue.latSurveyDatum != " " && currentValue.latSurveyDatum != null && currentValue.latSurveyDatum != undefined){
            latSurveyDatum = currentValue.latSurveyDatum;
          }else{
            latSurveyDatum = "";
          }

          if(currentValue.lngSurveyDatum != " " && currentValue.lngSurveyDatum != null && currentValue.lngSurveyDatum != undefined){
            lngSurveyDatum = currentValue.lngSurveyDatum;
          }else{
            lngSurveyDatum = "";
          }

          if(currentValue.latSurveyDatumGrad != " " && currentValue.latSurveyDatumGrad != null && currentValue.latSurveyDatumGrad != undefined){
            latSurveyDatumGrad = currentValue.latSurveyDatumGrad;
          }else{
            latSurveyDatumGrad = "";
          }

          if(currentValue.lngSurveyDatumGrad != " " && currentValue.lngSurveyDatumGrad != null && currentValue.lngSurveyDatumGrad != undefined){
            lngSurveyDatumGrad = currentValue.lngSurveyDatumGrad;
          }else{
            lngSurveyDatumGrad = "";
          }

          if(currentValue.latWGS84 != " " && currentValue.latWGS84 != null && currentValue.latWGS84 != undefined){
            latWGS84 = currentValue.latWGS84;
          }else{
            latWGS84 = "";
          }
          
          if(currentValue.lngWGS84 != " " && currentValue.lngWGS84 != null && currentValue.lngWGS84 != undefined){
            lngWGS84 = currentValue.lngWGS84;
          }else{
            lngWGS84 = "";
          }
         
          if(currentValue.latWGS84Grad != " " && currentValue.latWGS84Grad != null && currentValue.latWGS84Grad != undefined){
            latWGS84Grad = currentValue.latWGS84Grad;
          }else{
            latWGS84Grad = "";
          }

          if(currentValue.lngWGS84Grad != " " && currentValue.lngWGS84Grad != null && currentValue.lngWGS84Grad != undefined){
            lngWGS84Grad = currentValue.lngWGS84Grad;
          }else{
            lngWGS84Grad = "";
          }

          if(currentValue.latPH != " " && currentValue.latPH != null && currentValue.latPH != undefined){
            latPH = currentValue.latPH;
          }else{
            latPH = "";
          }

          if(currentValue.lngPH != " " && currentValue.lngPH != null && currentValue.lngPH != undefined){
            lngPH = currentValue.lngPH;
          }else{
            lngPH = "";
          }

          if(currentValue.latPHGrad != " " && currentValue.latPHGrad != null && currentValue.latPHGrad != undefined){
            latPHGrad = currentValue.latPHGrad;
          }else{
            latPHGrad = "";
          }

          if(currentValue.lngPHGrad != " " && currentValue.lngPHGrad != null && currentValue.lngPHGrad != undefined){
            lngPHGrad = currentValue.lngPHGrad;
          }else{
            lngPHGrad = "";
          }

          if(currentValue.latPHWGS84 != " " && currentValue.latPHWGS84 != null && currentValue.latPHWGS84 != undefined){
            latPHWGS84 = currentValue.latPHWGS84;
          }else{
            latPHWGS84 = "";
          }

          if(currentValue.lngPHWGS84 != " " && currentValue.lngPHWGS84 != null && currentValue.lngPHWGS84 != undefined){
            lngPHWGS84 = currentValue.lngPHWGS84;
          }else{
            lngPHWGS84 = "";
          }

          if(currentValue.latPHWGS84Grad != " " && currentValue.latPHWGS84Grad != null && currentValue.latPHWGS84Grad != undefined){
            latPHWGS84Grad = currentValue.latPHWGS84Grad;
          }else{
            latPHWGS84Grad = "";
          }

          if(currentValue.lngPHWGS84Grad != " " && currentValue.lngPHWGS84Grad != null && currentValue.lngPHWGS84Grad != undefined){
            lngPHWGS84Grad = currentValue.lngPHWGS84Grad;
          }else{
            lngPHWGS84Grad = "";
          }

          if(currentValue.latMH != " " && currentValue.latMH != null && currentValue.latMH != undefined){
            latMH = currentValue.latMH;
          }else{
            latMH = "";
          }

          if(currentValue.lngMH != " " && currentValue.lngMH != null && currentValue.lngMH != undefined){
            lngMH = currentValue.lngMH;
          }else{
            lngMH = "";
          }

          if(currentValue.latMHGrad != " " && currentValue.latMHGrad != null && currentValue.latMHGrad != undefined){
            latMHGrad = currentValue.latMHGrad;
          }else{
            latMHGrad = "";
          }

          if(currentValue.lngMHGrad != " " && currentValue.lngMHGrad != null && currentValue.lngMHGrad != undefined){
            lngMHGrad = currentValue.lngMHGrad;
          }else{
            lngMHGrad = "";
          }

          if(currentValue.latMHWGS84 != " " && currentValue.latMHWGS84 != null && currentValue.latMHWGS84 != undefined){
            latMHWGS84 = currentValue.latMHWGS84;
          }else{
            latMHWGS84 = "";
          }

          if(currentValue.lngMHWGS84 != " " && currentValue.lngMHWGS84 != null && currentValue.lngMHWGS84 != undefined){
            lngMHWGS84 = currentValue.lngMHWGS84;
          }else{
            lngMHWGS84 = "";
          }

          if(currentValue.latMHWGS84Grad != " " && currentValue.latMHWGS84Grad != null && currentValue.latMHWGS84Grad != undefined){
            latMHWGS84Grad = currentValue.latMHWGS84Grad;
          }else{
            latMHWGS84 = "";
          }

          if(currentValue.lngMHWGS84Grad != " " && currentValue.lngMHWGS84Grad != null && currentValue.lngMHWGS84Grad != undefined){
            lngMHWGS84Grad = currentValue.lngMHWGS84Grad;
          }else{
            lngMHWGS84Grad = "";
          }
    
          if(latSurveyDatumGrad != ""){
            latSurveyDatumGrad = latSurveyDatumGrad.replace('? ', '°').replace('?', '°');
          }
    
          if(lngSurveyDatumGrad != ""){
            lngSurveyDatumGrad = lngSurveyDatumGrad.replace('? ', '°').replace('?', '°');
          }
          
          if(latWGS84Grad != ""){
            latWGS84Grad = latWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    
          if(lngWGS84Grad != ""){
            lngWGS84Grad = lngWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    
          var coordinateswgs84 = "";

          if(latWGS84 != "" && lngWGS84 != ""){
            coordinateswgs84 = latWGS84 + "%" + latWGS84Grad.replace('? ', '°').replace('?', '°') + "|" + lngWGS84 + "%" + lngWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    

          if(latPHWGS84Grad != ""){
            latPHWGS84Grad = latPHWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    
          if(lngPHWGS84Grad != ""){
            lngPHWGS84Grad = lngPHWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    

          if(latMHWGS84Grad != ""){
            latMHWGS84Grad = latMHWGS84Grad.replace('? ', '°').replace('?', '°');
          }
    
          if(lngMHWGS84Grad != ""){
            lngMHWGS84Grad = lngMHWGS84Grad.replace('? ', '°').replace('?', '°');
          }
          
          var coordinatesph = "";
          
          if(latPHWGS84 != "" && lngPHWGS84 != "" && latPHWGS84Grad != "" && lngPHWGS84Grad != ""){
            coordinatesph = latPHWGS84 + "%" + this.latPHWGS84Grad + "|" + lngPHWGS84 + "%" + this.lngPHWGS84Grad;
          }          

          var coordinatesmh = "";

          if(latMHWGS84 != "" && lngMHWGS84 != "" && latPHWGS84 != "" && lngPHWGS84 != ""){
            coordinatesmh = latMHWGS84 + "%" + this.latMHWGS84Grad + "|" + lngMHWGS84 + "%" + this.lngMHWGS84Grad;
          }
    
          this.piattaforma = piattaforma;
          this.nazione = nazione;
          this.anno = anno;
    
          var coord = [];
          
          
          if(coordinateswgs84){
            coord = coordinateswgs84.split('|');
          }else{
            if(coordinatesmh){
              coord = coordinatesmh.split('|');
            }else{
              coord = coordinatesph.split('|');
            }
          }  
        
          var len = coord.length;
          
          var lat1 = "";
          var lat1grad = "";
          var lng1 = "";
          var lng1grad = "";
          var lat2 = "";
          var lat2grad = "";
          var lng2 = "";
          var lng2grad = "";
        
          var icon_normal_blue = new L.icon({
            iconUrl: 'http://rcs.explorermarinesboss.com/api/images/favorite_41_blue.png',
            iconAnchor: [12, 41],
            popupAnchor: [0, -41]
          });
        
          var icon_normal_red = new L.icon({
            iconUrl: 'http://rcs.explorermarinesboss.com/api/images/favorite_41_red.png',
            iconAnchor: [12, 41],
            popupAnchor: [0, -41]
          });
        
          if(len == 2){
            var lat_arr = coord[0].split('%');
            var lng_arr = coord[1].split('%');
        
            lat1 = lat_arr[0].replace(',','.');
            lat1grad = lat_arr[1];
            lng1 = lng_arr[0].replace(',','.');
            lng1grad = lng_arr[1];
        
            this.markers[idposizionamento] = new L.Marker([lat1, lng1], {
              icon: fontAwesomeIcon,
              //title: this.titleWell + " - [" + lat1grad + " - " + lng1grad + "]"
            }).bindTooltip(titleWell + " - [" + lat1grad + " - " + lng1grad + "]", 
            {
                permanent: false, 
                direction: 'left'
            })
            .on('click', this.openlargemodalnavigate(idposizionamento, idnazione)).addTo(this.map);
        
            //this.map.flyTo([lat1, lng1], 18);
            //$('#modaldata1').css("margin-left","350px");
        
          }
        
          if(len == 4){
            var lat1_arr = coord[0].split('%');
            var lng1_arr = coord[1].split('%');
        
            lat1 = lat1_arr[0].replace(',','.');
            lat1grad = lat1_arr[1];
            lng1 = lng1_arr[0].replace(',','.');
            lng1grad = lng1_arr[1];
        
            var lat2_arr = coord[2].split('%');
            var lng2_arr = coord[3].split('%');
        
            lat2 = lat2_arr[0].replace(',','.');
            lat2grad = lat2_arr[1];
            lng2 = lng2_arr[0].replace(',','.');
            lng2grad = lng2_arr[1];
                
            this.markers[idposizionamento] = new L.Marker([lat1, lng1], {
              icon: fontAwesomeIcon,
              //title: this.titleWell + " - Main Hole" + "[" + lat1grad + " - " + lng1grad + "]"
            })
            .bindTooltip(titleWell + " - Main Hole" + "[" + lat1grad + " - " + lng1grad + "]", 
            {
                permanent: false, 
                direction: 'left'
            })
            .on('click', this.openlargemodalnavigate(idposizionamento, idnazione)).addTo(this.map);
        
            this.markers[idposizionamento] = new L.Marker([lat2, lng2], {
              icon: fontAwesomeIcon,
              //title: this.titleWell + " - Pilot Hole" + "[" + lat2grad + " - " + lng2grad + "]"
            })
            .bindTooltip(titleWell + " - Pilot Hole" + "[" + lat2grad + " - " + lng2grad + "]", 
            {
                permanent: false, 
                direction: 'right'
            })
            .on('click', this.openlargemodalnavigate(idposizionamento, idnazione)).addTo(this.map);

        
          }
          
      });

      }); 
  }else{
      //inactive all positioning

  }
}
//#endregion

//#region utilities
deg2rad(degrees)
{
  var pi = Math.PI;
  return degrees * (pi/180);
}

rad2deg(radians)
{
  var pi = Math.PI;
  return radians * (180/pi);
}

setUnits(){
  switch(this.units){
    case 'm':
      this.units = "km";
      break;
    case 'km':
      this.units = "NM";
      break;
    case 'NM':
      this.units = "m";
      break;
  }
}

lngLatToLatLng(lngLat) {
  return [lngLat[1], lngLat[0]];
}
//#endregion

//#region core
switchActionOperation(){
  if(Number(this.currentzoom) >= 14){
    //draw shapes
    this.setShapes();    
    //this.setMarkers();
  }else{
    //draw markers
    this.setMarkers();
  }
}

markerGarbageCollector(){

  for(let i in this.markerdata) {
    if(this.markerdata[i] != undefined) {
        try {
          this.clearMarker(this.markerdata[i]);
        }
        catch(e) {
            //console.log("problem with " + e + this.map._layers[i]);
        }
    }
}

  this.setShapes();  
}

clearMarker(id) {
	//console.log(this.markers)
  var new_markers = []
  this.markers.forEach(function(marker) {
    if (marker == id) this.map.removeLayer(marker)
    else new_markers.push(marker)
  })
  this.markers = new_markers;
}

setShapes(){
  
  var currPlatform = sessionStorage.getItem("idplatform");

  this.ListPlatform.forEach((currentValue, index) => {
    /************** START DATA ENTRY ***************/
    
    var idplatform = currentValue.idpiattaforma;

    this.restApi.getPiattaforma(idplatform, this.token).subscribe((data: {}) => {
      this.ListPlatformSel = data;
      var dynamicname = 'marker';
      var dynamicnumb = 'numb';
      //create an empty list to hold each marker
          
      this.ListPlatformSel.forEach( (currentValue, index) => {
        var idplatform = currentValue.idpiattaforma;
        var idshapefiles = currentValue.idshapefiles;
        var latitude = currentValue.latitudine;
        var longitude = currentValue.longitudine;
        var platform_type = currentValue.tipo_piattaforma;
        var heading = currentValue.heading;
        var covalue = currentValue.covalue;
        var headingvalue = currentValue.heading.replace("T", "").replace("/n", "").replace("/t", "").replace("°", "").trim();
        var headingadjust = 0;

        var isPerimeter = this.checkPointToPerimeter(latitude,longitude);
        console.log("this.countiter = " + this.countiter);
        if(isPerimeter == 1){

          this.drawShape(idshapefiles, latitude, longitude, headingvalue);
          // if(this.countiter == 0){
          //   this.drawShape(idshapefiles, "39.0620339", "17.17295285", headingvalue);
          //   ++this.countiter;
          // }
          // if(this.countiter == 1){
          //   this.drawShape(idshapefiles, "39.0620439", "17.17295285", headingvalue);
          //   ++this.countiter;
          // }
          // if(this.countiter == 2){
          //   this.drawShape(idshapefiles, "39.0620539", "17.17295285", headingvalue);
          //   ++this.countiter;
          // }
          // if(this.countiter == 3){
          //   this.drawShape(idshapefiles, "39.0620639", "17.17295285", headingvalue);
          //   ++this.countiter;
          // }
          // if(this.countiter == 4){
          //   this.drawShape(idshapefiles, "39.0620739", "17.17295285", headingvalue);
          //   this.countiter = 0;
          // }
          //console.log("idplatform = " + idplatform + " it's on the perimeter");

        }else{
          //console.log("idplatform = " + idplatform + " it's not in the perimeter");
        }
      });
    });
  

  });
}

markers_arr = [];

setMarkersEvo(coords){
  var id;
  ++this.countshape;
 
  var popupContent =
    '<p>Some Infomation</p></br>' +
    '<p>test</p></br>' +
    '<button (click)="clearMarker(' + this.countshape + ')">Clear Marker</button>';
  var myMarker = L.marker(coords, {
    draggable: false
  });
  myMarker._id = this.countshape;
  var myPopup = myMarker.bindPopup(popupContent, {
    closeButton: false
  });
  this.map.addLayer(myMarker);
  this.markers_arr.push(myMarker);
}

setMarkers(){
  this.markers = {};
  //console.log("set markers");
  this.markerdata = [];
  this.fg.clearLayers();
  //console.log('zoom = ' + this.map.getZoom());
  //if(this.map.getZoom()>=6){
  //$(".leaflet-marker-icon leaflet-zoom-animated leaflet-interactive").remove();
  //$("img[src='http://rcs.explorermarinesboss.com/api/images/marker-icon.png']").remove();

  // const fontAwesomeIcon = L.divIcon({
  //   html: '<i class="fa fa-map-marker fa-2x" aria-hidden="true" style="color:#ab0b4b;"></i>',
  //   iconSize: [20, 20],
  //   className: 'myDivIcon'
  // });

  // marker dot point
  const fontDotIcon = L.divIcon({
    html: '<p style="font-size:24pt;">.</p>',
    iconSize: [20, 20],
    className: 'myDivIcon'
  });

  // const fontAwesomeIcon = L.divIcon({
  //   html: '<i class="fa fa-circle large_icon" aria-hidden="true style="color:#ab0b4b;"></i>',
  //   iconSize: [20, 20],
  //   className: 'myDivIcon'
  // });
  
  const fontAwesomeIcon = L.divIcon({
    html: '<i class="fa fa-plus-square-o fa-2x" aria-hidden="true style="color:#ab0b4b;"></i>',
    iconSize: [20, 20],
    className: 'myDivIcon'
  });
  //<i class="fa fa-plus-square-o fa-2x" aria-hidden="true style="color:#ab0b4b;"></i>

  var idutente = sessionStorage.getItem("idutente");
  //console.log('idutente = ' + idutente);
  var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  var markerArray = [];
    
  var latitudeg = "";
  var longitudeg = "";
  var heading = "";
  var platform_type = "";
  var stato = "1";
  
    this.restApi.getPiattaformeAttive(idutente, stato, token).subscribe((data: {}) => {
      this.ListPlatform = data;
	    var dynamicname = 'marker';
      var dynamicnumb = 'numb';
      //create an empty list to hold each marker
      var idplatform1 = "";  
      this.ListPlatform.forEach((currentValue, index) => {
        /************** START DATA ENTRY ***************/
        var processrow = "0";
        idplatform1 = currentValue.idpiattaforma;
		    var idplatform_user = currentValue.idpiattaforma_utente;

        var desc = currentValue.denominazione;
        var latitude = currentValue.latitudine;
        var longitude = currentValue.longitudine;

        var isPerimeter = this.checkPointToPerimeter(latitude,longitude);

        // if(isPerimeter == 1){
        //   console.log("idplatform marker = " + idplatform1 + " it's on the perimeter");
        // }else{
        //   console.log("idplatform marker = " + idplatform + " it's not in the perimeter");
        // }

        if(isPerimeter == 1){

        var latitudeg = currentValue.latitudine_gradi;
        var longitudeg = currentValue.longitudine_gradi;
        var platform_type = currentValue.tipo_piattaforma;
        var heading = currentValue.heading;
        var covalue = currentValue.covalue;
        var referencepoint = currentValue.referencepoint;
        var twentyfouractivity = currentValue.twentyfouractivity;
        var headingvalue = currentValue.heading.replace("T", "").trim();
        var headingadjust = 0;
        if(covalue != 0){
          headingadjust = parseFloat(headingvalue);// + parseFloat(covalue);
        }

        var serverrdp = currentValue.serverrdp;
        var serverrdpusr = currentValue.serverrdpusr;
        var serverrdppwd = currentValue.serverrdppwd;
        var path_image = currentValue.path_image;
        var hdg_round = headingadjust.toFixed(1);

        var idplatform = $('#idpiattaforma').val();

        var split_latg = latitudeg.split('|');
        var ltgrad = split_latg[0];
        var ltfirst = split_latg[1];
        var ltsecond = split_latg[2];
        var ltheading = split_latg[3];
     
        var ltsecondsplit1 = ltsecond.toString().split(',')[0];
        var ltsecondsplit2 = ltsecond.toString().split(',')[1];
  
        if(ltsecondsplit1.length == 1){
          ltsecond = "0" + ltsecondsplit1 + "," + ltsecondsplit2;
        }

        if(ltheading != undefined || ltheading == ""){
          var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","");
        }else{
          var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","");
        }

        var split_long = longitudeg.split('|');
        var lggrad = split_long[0];
        var lgfirst = split_long[1];
        var lgsecond = split_long[2];

        var lgsecondsplit1 = lgsecond.toString().split(',')[0];
        var lgsecondsplit2 = lgsecond.toString().split(',')[1];
  
        if(lgsecondsplit1.length == 1){
          lgsecond = "0" + lgsecondsplit1 + "," + lgsecondsplit2;
        }

        var lgheading = split_long[3];

        if(lgheading != undefined || lgheading == ""){
          var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","");
        }else{
          var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","");
        }
      
        var hdg_round = hdg_round; 
        
        if(idplatform == idplatform1){
          // console.log('######################################################');
          // $('#title').text(desc);
          // $('#latitude').text(latdef);
          // $('#referencepoint').text(referencepoint);
          // if(twentyfouractivity != null){
          //   $('#twentyfouractivity').text("Next 24 hour activity: " + twentyfouractivity);
          // }else{
          //   $('#twentyfouractivity').text("Next 24 hour activity: ");
          // }
          // $('#longitude').text(lngdef);
          // $('#heading').text(hdg_round.toString() + "° T");
          // //$('#idpiattaforma').val(idplatform_user);
          // $('#imageplatform').attr("src", path_image);
          this.getDataSingleRig(idplatform, "false");
        }
      
		  var marker_image = "";  
          switch(platform_type){
            case "1":
              marker_image = 'http://rcs.explorermarinesboss.com/api/images/ship_blue.png';
              break;
            case "2":
              marker_image = 'http://rcs.explorermarinesboss.com/api/images/ship_red.png';
              break;
          }
		  
          var icon_normal = new L.icon({
            iconUrl: 'http://rcs.explorermarinesboss.com/api/images/favorite_41_heavenly.png',
            iconAnchor: [12, 41],
            popupAnchor: [0, -41]
        });

		  if (!this.markers.hasOwnProperty(currentValue.idpiattaforma)) {
        var idplatform = $('#idpiattaforma').val();
        //console.log('idplatform = ' + idplatform);
        //console.log('currentValue.idpiattaforma = ' + currentValue.idpiattaforma);
        
        if(idplatform == currentValue.idpiattaforma){
          //console.log('x');
          this.getDataSingleRig(idplatform, "false");
          // $('#title').text(desc);
          // $('#latitude').text(latdef);
          // $('#longitude').text(lngdef);
          // $('#referencepoint').text(referencepoint);
          // //twentyfouractivity = "Aww yeah, you successfully read this important alert.";
          // if(twentyfouractivity != null){
          //   $('#twentyfouractivity').text("Next 24 hour activity: " + twentyfouractivity);
          // }else{
          //   $('#twentyfouractivity').text("Next 24 hour activity: ");
          // }
          // if(covalue != 0){
          //   $('#heading').text(hdg_round.toString() + "° T");
          // }else{
          //   $('#heading').text(heading);
          // }
          // //$('#idpiattaforma').val(idplatform_user);
          // $('#imageplatform').attr("src", path_image);
        }
        //console.log('lat = ' + currentValue.latitudine + ' lon = ' + currentValue.longitudine);
		    this.markerdata.push(currentValue.idpiattaforma);
        this.markers[currentValue.idpiattaforma] = new L.Marker([currentValue.latitudine, currentValue.longitudine], {
          //rotationAngle: headingvalue,
          //rotationOrigin: 'center',
          icon: fontAwesomeIcon,
          // icon: new L.Icon({
          //     iconSize: [22, 22],
          //     iconAnchor: [11, 22],
          //     iconUrl: marker_image,
          //   }),
            title: desc})
            .bindTooltip(desc + " [" + currentValue.latitudine + " " + currentValue.longitudine + " ]", 
            {
                permanent: false, 
                direction: 'left'
            })
            .on('click', this.onMarkerClick(idplatform1, idplatform_user, desc, latitude, longitude, hdg_round, latitudeg, longitudeg, path_image, twentyfouractivity, referencepoint)).addTo(this.fg);
		    this.markers[currentValue.idpiattaforma].previousLatLngs = [];
		  } else {
        //console.log('y');
       
        this.map.removeLayer(this.markers[currentValue.idpiattaforma]);

		    this.markers[currentValue.idpiattaforma].previousLatLngs.push(this.markers[currentValue.idpiattaforma].getLatLng());
		    this.markers[currentValue.idpiattaforma].setLatLng([currentValue.latitudine, currentValue.longitudine]);
   		  }

      }

    });
    
    //console.log('markers = ' + JSON.stringify(this.markers));
    });

}

onMarkerClick(id, idplatform_user, desc, lat, lng, hdg, latg, lngg, path_image, twentyfouractivity, referencepoint){
  
  var idplatform = "0";

  if($('#idpiattaforma').val() != null){
    idplatform = $('#idpiattaforma').val();
  }else{
    idplatform = sessionStorage.getItem("idplatform");
  }

  var token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";
  //console.log('***********');
  //console.log('id = ' + id);
  //console.log('idplatform = ' + idplatform);
  // console.log('latitudeg = ' + lat);
  // console.log('latitudeg = ' + latg);
  // console.log('longitude = ' + lng);
  // console.log('longitudeg = ' + lngg);
  // console.log('heading = ' + hdg);
  //console.log('***********');


  // this.restApi.getMainEventPlatform(idplatform, token).subscribe((data: {}) => {
  //   this.ListMainEvent = data;

  //   var len = this.ListMainEvent.length;

  //   if(len > 0){
  //     this.showmainevent = true;
  //   }else{
  //     this.showmainevent = false;
  //   }

  //   console.log('ListMainEvent = ' + JSON.stringify(this.ListMainEvent));
  // });
  if($('#idpiattaforma').val() != null){
    idplatform = $('#idpiattaforma').val();
  }else{
    idplatform = sessionStorage.getItem("idplatform");
  }
  if(idplatform == "-1"){ 
    this.idplatform_user = id;
    var split_latg = latg.split('|');
    var ltgrad = split_latg[0];
    if(ltgrad.length == 1){
      ltgrad = "0" + ltgrad;
    }
    var ltfirst = split_latg[1];
    if(ltfirst.length == 1){
      ltfirst = "0" + ltfirst;
    }
    var ltsecond = split_latg[2];
    var ltheading = split_latg[3];

    //var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","") + + "&#8222; ";
    var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","") + " &#8222; " + ltheading;
    //latdef += " " + ltheading;
    
    var split_long = lngg.split('|');
    var lggrad = split_long[0];
    if(lggrad.length == 1){
      lggrad = "00" + lggrad;
    }
    if(lggrad.length == 2){
      lggrad = "0" + lggrad;
    }
    var lgfirst = split_long[1];
    if(lgfirst.length == 1){
      lgfirst = "0" + lgfirst;
    }
    var lgsecond = split_long[2];
    var lgheading = split_long[3];
    
    var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","");
    var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","") + " &#8222; " + lgheading;
    //lngdef += " " + lgheading;

    var hdg_round = parseFloat(hdg).toFixed(1);

    return function(ev) {
      //window.location.href = 'Items.php?parkid=' + desc;
      //alert('desc = ' + desc);
      // if(idplatform == id){
        $('#modaldata').css("display","block");
        //$('#modaldata').css("margin-left","-330px");
        sessionStorage.setItem('openpopup', 'true');
        $('#title').text(desc);
        $('#latitude').text(latdef);
        $('#longitude').text(lngdef);
        $('#heading').text(hdg_round.toString() + "° T");
        $('#idpiattaforma').val(id);
        $('#idpiattaformautente').val(idplatform_user);
        $('#imageplatform').attr("src", path_image);
        $('#referencepoint').text(referencepoint);
        //twentyfouractivity = "Aww yeah, you successfully read this important.";
        if(twentyfouractivity != null){
          $('#twentyfouractivity').text("Next 24 hour activity: " + twentyfouractivity);
        }else{
          $('#twentyfouractivity').text("Next 24 hour activity: ");
        }
      // }
  }

  }else{
    //console.log('iiid = ' + id);
    if($('#idpiattaforma').val() != null){
      idplatform = $('#idpiattaforma').val();
    }else{
      idplatform = sessionStorage.getItem("idplatform");
    }
    //console.log('idplatform** = ' + idplatform);
    this.restApi.getPiattaformaUtente(idplatform, token).subscribe((data: {}) => {
      this.ListPlatformSel = [];
      this.ListPlatformSel = data;
	    var dynamicname = 'marker';
      var dynamicnumb = 'numb';
      //create an empty list to hold each marker
      	  
      this.ListPlatformSel.forEach( (currentValue, index) => {
        var processrow = "0";
        var idplatform = currentValue.idpiattaforma;
		    var idplatform_user = currentValue.idpiattaforma_utente;
        $('#idpiattaformautente').val(idplatform_user);
        var desc = currentValue.denominazione;
        //console.log('desc** = ' + desc);
        var latitude = currentValue.latitudine;
        //console.log('latitude** = ' + latitude);
        var longitude = currentValue.longitudine;
        //console.log('longitude** = ' + longitude);
        var latitudeg = currentValue.latitudine_gradi;
        //console.log('latitudeg** = ' + latitudeg);
        var longitudeg = currentValue.longitudine_gradi;
        //console.log('longitudeg** = ' + longitudeg);
        var platform_type = currentValue.tipo_piattaforma;
        var heading = currentValue.heading;
        var covalue = currentValue.covalue;
        var headingvalue = currentValue.heading.replace("T", "").trim();
        var headingadjust = 0;
        var referencepoint = currentValue.referencepoint;
        var twentyfouractivity = currentValue.twentyfouractivity;

        this.idplatform_user = id;
        var split_latg = latitudeg.split('|');
        var ltgrad = split_latg[0];
        if(ltgrad.length == 1){
          ltgrad = "0" + ltgrad;
        }
        var ltfirst = split_latg[1];
        if(ltfirst.length == 1){
          ltfirst = "0" + ltfirst;
        }
        var ltsecond = split_latg[2];
        var ltheading = split_latg[3];
    
        var latdef = ltgrad + "° " + ltfirst + "\' " + ltsecond.replace("-","") + "&#8222; " + ltheading;
        //console.log('latdef** = ' + latdef);
        var split_long = longitudeg.split('|');
        var lggrad = split_long[0];
        if(lggrad.length == 1){
          lggrad = "00" + lggrad;
        }
        if(lggrad.length == 2){
          lggrad = "0" + lggrad;
        }
        var lgfirst = split_long[1];
        if(lgfirst.length == 1){
          lgfirst = "0" + lgfirst;
        }
        var lgsecond = split_long[2];
        var lgheading = split_long[3];
        
        var lngdef = lggrad + "° " + lgfirst + "\' " + lgsecond.replace("-","") + "\" " + lgheading;
        //console.log('lngdef** = ' + lngdef);
        var hdg_round = parseFloat(heading).toFixed(1);
        //console.log('hdg_round** = ' + hdg_round);
        return function(ev) {
          //window.location.href = 'Items.php?parkid=' + desc;
          //alert('desc = ' + desc);
          $('#modaldata').css("display","block");
          sessionStorage.setItem('openpopup', 'true');
          $('#title').text(desc);
          $('#latitude').text(latdef);
          $('#longitude').text(lngdef);
          $('#heading').text(hdg_round.toString() + "° T");
          $('#idpiattaformautente').val(idplatform_user);
          $('#imageplatform').attr("src", path_image);
          $('#referencepoint').text(referencepoint);
          if(twentyfouractivity != null){
            $('#twentyfouractivity').text("Next 24 hour activity: " + twentyfouractivity);
          }else{
            $('#twentyfouractivity').text("Next 24 hour activity: ");
          }

      }

      });
    });
  }
}

drawShape(id, lat, lng, heading){
  //this.clearMap();
  var latn = Number(lat); 
  var lngn = Number(lng);
  
  var latlng = L.latLng(latn, lngn);
  var offset = [];
  var coord = [];
  var coordofst = [];
  var ptdraw = 0;
  var count = 0;
  var style = {
    color: "#000000",
    stroke: "#ffffff",
    weight: 1,
    opacity: 1
     }, 
    stroke = {
    color: "#fff",
    weight: 7,
    opacity: 0.4
    };
  
  var style1 = {
    color:"#000000",
    radius: 0.2
  }; 
  //coord.push(latlng);
  
  this.point0 = L.latLng(latn, lngn);

  var fontAwesomeIconPoint = L.divIcon({
    html: '<i class="fa fa-circle fa-2xs" aria-hidden="true" style="color:black;"></i>',
    iconSize: [10, 10],
    className: 'myDivIcon'
  });

     this.countOffset = 0;
     this.fg.clearLayers();
    if(this.ListShapeFiles.length > 0){
      var datas = [];
      var polylinetemp = [];
      this.ListShapeFiles.forEach((currentValue, index) => {
        var idshapefiles = currentValue.idshapefiles;  
        //console.log('idshapefiles = ' + idshapefiles); 
        if(idshapefiles == id){ 
        var datass = currentValue.data;
        
        datas = datass.split('#');
        let fLen = datas.length;
        var pointarchive;

        for (let i = 0; i < fLen; i++) {
          var child = datas[i];
          //console.log('child = ' + child);
          var childexp = child.split('|');
          //console.log('childexp = ' + childexp);
          let cLen = childexp.length;
          //console.log('cLen = ' + cLen);
          var description = childexp[6].split('=')[1];
          //console.log('description = ' + description);
          var ptdraw = childexp[6].split('=')[1];
          //console.log('ptdraw = ' + ptdraw);
          var width = childexp[7].split('=')[1];
          //console.log('width = ' + width);
          var angle = childexp[8].split('=')[1];
          //console.log('angle = ' + angle);

          if(heading.includes("*")){
            var hdgtarr = heading.toString().split('*');
            var hdgarr = hdgtarr[0].toString().split('.');  
          }else{
            var hdgarr = heading.toString().split('.');
          }

          //console.log('hdgarr = ' + hdgarr);
          var lenhdg = hdgarr.length;
          //console.log('lenhdg = ' + lenhdg);

          if(lenhdg == 1){
            //var headangle = (Number(angle.replace(',','.')) + Number(heading) + 0.00001)*-1;
            var headangle = Number((angle.replace(',','.'))*-1) + Number(heading) + 0.00001;
            //console.log('headangle = ' + headangle);
          }else{
            var hdg1 = hdgarr[0];
            //console.log('hdg1 = ' + hdg1);
            var hdg2 = hdgarr[1];
            //console.log('hdg2 = ' + hdg2);
            if(hdg2 == "0" || hdg2 == "00" || hdg2 == "000" || hdg2 == "0000" || hdg2 == "00000"){
              //var headangle = (Number(angle.replace(',','.')) + Number(hdg1) + 0.00001)*-1;  
              var headangle = Number((angle.replace(',','.')*-1)) + Number(hdg1) + 0.00001;  
              //console.log('headangle 1 = ' + hdg2);
            }else{
              var hdg = Number(hdg1+'.'+hdg2);
              //var headangle = (Number(angle.replace(',','.')) + Number(hdg))*-1;
              var headangle = Number((angle.replace(',','.')*-1)) + Number(hdg);
              //console.log('headangle 2 = ' + hdg2);
            }
            // + 0.00001;
          }
                    
          var type = childexp[9].split('=')[1];
          //console.log('type = ' + type);

          if(type == "coord"){
              //console.log('count = ' + i + ' coord latlng = ' + latlng + 'angle = ' + angle + ' width = ' + width); 
              if(ptdraw == 0){
                if(coord.length > 0){
                  //if(this.countshape == 0){
                    //var poly1 = L.polyline(coord, style);//.addTo(this.map);
                    polylinetemp.push(coord);
                    //console.log('polyline 1');
                  //}else{
                    //this.polyline2 = L.polyline(coord, style).addTo(this.map);
                    //this.polyline1.remove;
                    //console.log('polyline 2');
                  //}
                  coord = [];
                  var point = GeometryUtil.destination(this.point0, headangle.toString().replace(',','.'), width.replace(',','.'));
                  coord.push(point);
                }else{
                  coord = [];
                  var point = GeometryUtil.destination(this.point0, headangle.toString().replace(',','.'), width.replace(',','.'));
                  coord.push(point);
                }
              }else{
                var point = GeometryUtil.destination(this.point0, headangle.toString().replace(',','.'), width.replace(',','.'));
                //this.point0 = point;
                coord.push(point);
              }

            ++count;
          }

          if(type == "offset"){
            ++this.countOffset;
            //console.log('count = ' + i + ' offset latlng = ' + latlng + 'angle = ' + angle + ' width = ' + width); 
            var point = GeometryUtil.destination(this.point0, headangle.toString().replace(',','.'), width.replace(',','.'));
            //console.log('offset latlng = ' + this.point0 + 'angle = ' + angle + ' width = ' + width); 
            
            L.circle([point.lat, point.lng], style1).addTo(this.fg).bindTooltip(description , 
            {
                permanent: false, 
                direction: 'left'
            });

          }

        }

        //this.polyline1 = null;
        
        L.polyline(polylinetemp, style).addTo(this.fg);
        // switch(this.countpoly){
        //   case 0:
        //     // this.polyline1 = L.polyline(polylinetemp, style).addTo(this.map);
        //     ++this.countpoly;
        //     this.polyline1.addLayer(L.polyline(polylinetemp, style))
        //     this.polyline1.addTo(this.map);
        //     console.log('countpoly = ' + this.countpoly);
        //     break;
        //   case 1:
        //     this.map.removeLayer(this.polyline1);
        //     //this.polyline1.remove(this.map);
        //     //this.polyline2 = L.polyline(polylinetemp, style).addTo(this.map);
        //     // this.polyline1.removeFrom(this.map);
        //     this.polyline2.addLayer(L.polyline(polylinetemp, style))
        //     this.polyline2.addTo(this.map);
        //     ++this.countpoly;
        //     console.log('countpoly = ' + this.countpoly);
        //     break;
        //     case 2:            
        //      this.map.removeLayer(this.polyline2);
        //   //   this.polyline2.remove(this.map);
        //   //   this.polyline1 = L.polyline(polylinetemp, style).addTo(this.map);
        //   //   this.countpoly = 0;
        //   //   console.log('countpoly = ' + this.countpoly);
        //     //this.polyline2.remove(this.map);
        //     //this.polyline2 = L.polyline(polylinetemp, style).addTo(this.map);
        //     // this.polyline1.removeFrom(this.map);
        //     this.polyline1.addLayer(L.polyline(polylinetemp, style))
        //     this.polyline1.addTo(this.map);
        //     this.countpoly = 0;
        //     break;
        // }

        // if(this.countshape == 0){
        //   this.countshape = 1;
        //   this.polyline2.remove;
        // }else{
        //   this.countshape = 0;
        //   this.polyline1.remove;
        // }

      }
      });
    }
  // });

}
//#endregion

//#region gestione files
/**
 * Convert Files list to normal array list
 * @param files (Files List)
 */
prepareFilesList(files: Array<any>) {
  for (const item of files) {
    item.progress = 0;
    this.files.push(item);
  }
  this.uploadFilesSimulator(0);
  this.uploadFile(this.files);
}

/**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * on file drop handler
 */
onFileDropped($event) {
  this.prepareFilesList($event);
  this.showtablereport = false;
}

/**
 * handle file from browsing
 */
fileBrowseHandler(files) {
  this.prepareFilesList(files); 
}

/**
 * Delete file from files list
 * @param index (File index)
 */
deleteFile(index: number) {
  this.files.splice(index, 1);
}

/**
 * Simulate the upload process
 */
uploadFilesSimulator(index: number) {
  setTimeout(() => {
    if (index === this.files.length) {
      return;
    } else {
      const progressInterval = setInterval(() => {
        if (this.files[index].progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
          
        } else {
          this.files[index].progress += 5;
        }
      }, 200);
    }
  }, 1000);
}

uploadFile = (files) => {

  
  if (files.length === 0) {
    this.filenameUpload = "";
    return;
  }

  let fileToUpload = <File>files[0];
  const formData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);
  
  this.filenameUpload = fileToUpload.name;

  this.restApi.uploadFileWell(this.anno, this.codiceprogettoint, formData).subscribe({
      next: (event) => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
        
        var utente = sessionStorage.getItem("username");

      }
    },
    error: (err: HttpErrorResponse) => console.log(err)
  });
}

saveAttachment(){
  var attach: any = [];
  //console.log('saveAttachment');
      
  var pathfile = "Positioning/" + this.anno + "/" +  this.codiceprogettoint + "/" + this.filenameUpload;

  if(this.filenameUpload.includes("_") == true){
    var prefix_arr = this.filenameUpload.split('_');
    var prefix = prefix_arr[0];
    this.tipologiafile = this.getTypologyId(prefix);
  }else{
    var ext_arr = this.filenameUpload.split('.');
    var ext = prefix_arr[1];
    this.tipologiafile = this.getTypologyId(ext);
  }

  if(this.action == "new"){
    //console.log('insert');
    //insert
    attach = {
      "descrizione": this.filenameUpload,
      "pathfile": pathfile,
      "idtipo": this.tipologiafile,
      "idposizionamento": this.idposizionamento, 
      "token": this.token
    };
    //console.log('attach = ' + JSON.stringify(attach));
  
    this.restApi.createAttach(attach).subscribe((data: {}) => {
      //this.pozzoLoad(this.idposizionamento);
      // var message = "Salvataggio documento pozzo avvenuto con successo.";
      // var action = "ok"
      // this.openSnackBar(message, action);
    });

  }else{
    //update
    //console.log('update');
    attach = {
      "descrizione": this.filenameUpload,
      "pathfile": pathfile,
      "idtipo": this.tipologiafile,
      "idposizionamento": this.idposizionamento, 
      "idallegato":this.idallegato,
      "token": this.token
    };
  
    this.restApi.updateAttach(attach).subscribe((data: {}) => {
      // var message = "Aggiornamento documento pozzo avvenuto con successo.";
      // var action = "ok"
      // this.openSnackBar(message, action);
    });
  }
}

getTypologyId(code){
  var idallegato = "";
  
  this.ListTipFile.forEach((currentValue, index) => {
    var codice = currentValue.codice;
    if(codice == code){
      idallegato = currentValue.idallegati_tipo;
    }
  });

  return idallegato;
}

openModalSave(content) {
  // this.idmodal = "normal";
  // this.titleModal = "Invio richiesta";
  // this.bodyModal = "Vuoi inviare la richiesta di censimento?";
  // this.buttonAction = "Invia";
  // this.actionOp = "1";
  // this.modalService.open(content).result.then((result) => {
  //   this.closeResult = `Closed with: ${result}`;
  // }, (reason) => {
  //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  // });
}

openlargemodalnavigate(idpozizionamento, nation){
  var year = this.yearsel;
  //console.log();
  ///var nation = this.nationsel;
  var idpozzo = $('#idpozzo').val();
  if(idpozzo == "-1"){
    // this.router.navigate( [''], { queryParams: {year:year, nation:nation, idpos: idpozizionamento, activepos:this.isActivated}})
    // .then(() => {
    //   window.location.reload();
    // });
  }
}

ngOnDestroy() {
  clearInterval(this.timerRef);
} 

capture()
{
  this.captureService.getImage(this.screen.nativeElement, true).toPromise().then(img=>{
    //console.log(img);
    this.imgBase64=img
    this.save()
  })
}

DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
      
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
    
      return new Blob([ia], { type: mimeString })
}

ip="http://rcs.explorermarinesboss.com/api/utility/uploadimg.php"
save(){
  const file = this.DataURIToBlob(this.imgBase64)
  const formData = new FormData();
  formData.append('file', file, 'image.jpg')
  let url="uploadimg.php"
  this.http.post("http://beta.explorermarinesboss.com/api/utility/uploadimg.php",formData).subscribe(data=>{
    

  })
}

addWindy() {

  var asd = this.http.get("https://tf.istrongcloud.com/data/gfs/gfs.json").map((res: Response) => res.json());

  this.http.get("https://tf.istrongcloud.com/data/gfs/gfs.json")
     // Call map on the response observable to get the parsed people object
     .map((res: Response) => res.json())
     // Subscribe to the observable to get the parsed people object and attach it to the
     // component
     .subscribe(people => this.test = people);
 


 $.ajax({
   url: "https://tf.istrongcloud.com/data/gfs/gfs.json",
   type: 'GET',
   success: function (data) {
     //console.log(data)
     var arr = [];
     for (var i in data) {
       arr.push(data[i])
     }
     var windata = arr[0];
     var opts = {
       pane: "shadowPane",
       dataType: "wind",
       theme: "dark",
       isTOverlayer: true, //是否使用温度底图
       windyStaticOpts: {
         PARTICLE_LINE_LENGTH: 0.16,
         PARTICLE_LINE_WIDTH: 1,
         PARTICLE_MULTIPLIER: 0.005,
         PARTICLE_OPACITY: 1,
         PARTICLE_REDUCTION: 1.1447142425533319,
         VELOCITY_SCALE: 0.011447142425533319,
         FRAME_RATE: 15,
         FRAME_TIME: 66.66666666666667,
         MAX_PARTICLE_AGE: 90,
         MAX_TEMPERATURE_K: 317.15,
         MIN_TEMPERATURE_K: 261.15,
         PARTICLE_LINE_COLORs: ["rgb(255,255,255)"]
       }
     }

     var windfarm = new L.WindyLayer(opts);
     this.map.addLayer(windfarm);
     windfarm.loadWindy(windata);
   }
 });
}

//#endregion

}

function throwError(err: any) {
  throw new Error('Function not implemented.');
}


<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">Tooltips</h5>
    <div class="text-center">
      <button id="exButton1" type="button" class="btn mr-2 btn-primary" placement="top" ngbTooltip="Tooltip on top">
        Tooltip on top
      </button>
      <button id="exButton2" type="button" class="btn mr-2 btn-primary" placement="right" ngbTooltip="Tooltip on right">
        Tooltip on right
      </button>
      <button id="exButton3" type="button" class="btn mr-2 btn-primary" placement="bottom"
              ngbTooltip="Tooltip on bottom">
        Tooltip on bottom
      </button>
      <button id="exButton4" type="button" class="btn btn-primary" placement="left" ngbTooltip="Tooltip on left">
        Tooltip on left
      </button>
    </div>
  </div>
</div>

<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
     <div class="form-inline" *ngIf="status=='master'">
        <label class="sr-only" for="inlineFormInputName2">Name</label>
        <input type="text" class="form-control form-control-sm mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ricerca Anagrafica" [(ngModel)]="keysearch">
      
        <label class="sr-only" for="inlineFormInputGroupUsername2">Tipologia</label>
        <select class="custom-select custom-select-sm mb-2 mr-sm-2" id="inlineFormCustomSelect">
          <option selected>Seleziona tipologia...</option>
          <option value="1">Rig Positioning</option>
          <option value="2">Supervisor</option>
        </select>
      
        <button type="button" class="btn btn-primary btn-sm mb-2 mr-1" (click)="searchMED()">Cerca</button>
        <button type="button" class="btn btn-warning btn-sm mb-2" (click)="loadMED()">Reset</button>
        <button type="button" class="btn btn-success btn-sm ml-2 mb-2" (click)="newMED()"><fa-icon class="mr-2" [icon]="faPlus"></fa-icon> Nuova Anagrafica</button>
        <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
      </div>
  
      <div class="row" *ngIf="status=='detail'">
        <div class="col-md-8 mt-2">
          <div class="form-inline">
            <div class="form-group">
              <h5 class="card-title"><strong>{{destitle}}</strong></h5>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
          <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Elenco Utenti</button>
          <button class="btn btn-primary active pull-right ml-2" (click)="saveMED()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Salva</button>
          <button class="btn btn-primary active pull-right" (click)="newMED()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo</button>
        </div>
      </div>
  
    </div>
  
      <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Cognome</span></div>
            <input type="text" class="form-control" id="cognome" name="cognome" [(ngModel)]="cognome"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Nome</span></div>
            <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="nome"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Username</span></div>
            <input type="text" class="form-control" id="username" name="username" [(ngModel)]="username"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend primary"><span class="input-group-text primary">Email</span></div>
            <input type="text" class="form-control" id="email" name="email" [(ngModel)]="email" />
          </div>
        </div>
      </div>
      <div class="form-row ml-4 mr-4 mb-4"  *ngIf="status=='detail'">
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text warning">Password</span></div>
            <input type="text" class="form-control" id="password" name="password" [(ngModel)]="password"/>
          </div>
        </div>
        <div class="col-md-3 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text warning">Conferma Pwd</span></div>
            <input type="text" class="form-control" id="password" name="password" [(ngModel)]="confirmpassword"/>
          </div>
        </div>
        <div class="col-md-2 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Ruolo</span></div>
            <select class="custom-select custom-select-sm" id="ruolo" name="ruolo" [(ngModel)]="ruolo" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option value="1">Admin</option>
              <option value="2">Vendita</option>
              <option value="3">Magazzino</option>
              <option value="4">User</option>
            </select>
          </div>
        </div>
        <div class="col-md-2 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Attivo</span></div>
            <select class="custom-select custom-select-sm" id="attivo" name="attivo" [(ngModel)]="attivo" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="">seleziona</option>
              <option value="1">Attivo</option>
              <option value="0">Non attivo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer pb-1"  *ngIf="status=='detail'">
      <div class="form-row mb-4">
        <div class="col-8 mt-2">
          <h5 class="m-0 p-0" style="font-weight: bold;">Join Piattaforme</h5>
        </div>
        <div class="col-4 mt-1 mb-2">
          <button class="btn btn-primary active pull-right" (click)="openModalSelPlatform(content)"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo Join</button>
        </div>
      </div>
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Platform</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Heading</th>
            <th scope="col">Attiva</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of PiattaformeUtenti; let i = index">
            <td class="pt-3">{{item.denominazione}}</td>
            <td class="pt-3">{{item.latitudine}}</td>
            <td class="pt-3">{{item.longitudine}}</td>
            <td class="pt-3">{{item.heading}}</td>
            <td class="pt-3">
              <span class="badge badge-success p-1" *ngIf="item.attiva == 1">Attiva</span>
              <span class="badge badge-danger p-1" *ngIf="item.attiva == 0">Non attiva</span>
            </td>
            <td>
              <button class="btn-shadow btn btn-danger btn-sm" (click)="openModalJoin(content, item.idpiattaforma_utente)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella join"></i></button>
              <button class="btn-shadow btn btn-success btn-sm ml-1" (click)="openModalActivateJoin(content, item.idpiattaforma_utente, item.attiva)"><i class="fa fa-fw fa-lg pb-2 pt-2" aria-hidden="true" title="Attiva/disattiva Join"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
        <h4 class="alert-heading">Elenco utenti vuoto</h4>
        <p>Nessun utenti presente. Creare un nuovo utente e riporvare.</p>
      </div> 
     <div class="mat-elevation-z0 w-100" *ngIf="status=='master' && showList == true">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
    
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let row"> {{row.iddescription}} </td>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let row"> {{row.description}} </td>
        </ng-container>
    
        <!-- Name Column 
        <ng-container matColumnDef="typerig">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type RIG </th>
          <td mat-cell *matCellDef="let row"> {{row.typerig}} </td>
        </ng-container>-->

        <!-- Name Column -->
        <ng-container matColumnDef="attivita">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Attività </th>
          <td mat-cell *matCellDef="let row"> {{row.attivita}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Action </th>
          <td mat-cell *matCellDef="let row" class="text-right"> 
            <div role="group" class="btn-group-sm btn-group">
              <button class="btn-shadow btn btn-success btn-sm mr-1 mt-1 mb-1" (click)="medLoad(row.iddescription)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica utente"></i></button>
              <button class="btn-shadow btn btn-danger btn-sm mt-1 mb-1" (click)="openModalDelete(content, row.iddescription)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella utente"></i></button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="d-block p-4 text-center card-footer">
    </div>
  </div>
  
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="this.idmodal == 'normal'">{{bodyModal}}</p>
      <table class="table table-striped table-hover" *ngIf="this.idmodal == 'selplatform'">
        <thead>
          <tr>
            <th scope="col">Platform</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Heading</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Piattaforme; let i = index">
            <td class="pt-3">{{item.denominazione}}</td>
            <td class="pt-3">{{item.latitudine}}</td>
            <td class="pt-3">{{item.longitudine}}</td>
            <td class="pt-3">{{item.heading}}</td>
            <td>
              <button class="btn-shadow btn btn-success btn-sm" (click)="selPlatformJoin(content, item.idpiattaforma)"><i aria-hidden="true" title="check" class="fa fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Seleziona piattaforma"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      <button *ngIf="this.actionOp == '1'" type="button" class="btn btn-primary" (click)="deleteUtente(idutente); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '2'" type="button" class="btn btn-primary" (click)="deleteJoin(idutente); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '3'" type="button" class="btn btn-primary" (click)="changeStatusJoin(idutente); c('Close click')">{{buttonAction}}</button>
    </div>
  </ng-template>
<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
     <div class="form-inline" *ngIf="status=='master'">
        <label class="sr-only" for="inlineFormInputName2">Name</label>
        <input type="text" class="form-control form-control-sm mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ricerca Anagrafica" [(ngModel)]="keysearch">
      
        <label class="sr-only" for="inlineFormInputGroupUsername2">Tipologia</label>
        <select class="custom-select custom-select-sm mb-2 mr-sm-2" id="inlineFormCustomSelect">
          <option selected>Seleziona tipologia...</option>
          <option value="1">Rig Positioning</option>
          <option value="2">Supervisor</option>
        </select>
      
        <button type="button" class="btn btn-primary btn-sm mb-2 mr-1" (click)="searchMED()">Cerca</button>
        <button type="button" class="btn btn-warning btn-sm mb-2" (click)="loadMED()">Reset</button>
        <button type="button" class="btn btn-success btn-sm ml-2 mb-2" (click)="newMainEvent()"><fa-icon class="mr-2" [icon]="faPlus"></fa-icon> Nuovo Main Event</button>
        <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
      </div>
  
      <div class="row" *ngIf="status=='detail'">
        <div class="col-md-8 mt-2">
          <div class="form-inline">
            <div class="form-group">
              <h5 class="card-title"><strong>{{destitle}}</strong></h5>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
          <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Elenco Main Event</button>
          <button class="btn btn-primary active pull-right ml-2" (click)="openModalUpdate(content)"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Salva</button>
          <button class="btn btn-primary active pull-right" (click)="newMED()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo</button>
        </div>
      </div>
  
    </div>
  
      <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text success">Description</span></div>
            <select class="custom-select custom-select-sm" id="iddescription" name="iddescription" (change)="onMEDSelect($event)" [(ngModel)]="iddescription" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of MEDList" [value]="obj.iddescription">{{obj.description}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Description</span></div>
            <input type="text" class="form-control" id="description" name="description" [(ngModel)]="description"/>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Data</span></div>
            <input type="text" class="form-control" id="data" name="data" [(ngModel)]="data"/>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Status</span></div>
            <select class="custom-select custom-select-sm" id="approved" name="approved" [(ngModel)]="approved" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="-1">select</option>
              <option value="1">Approved</option>
              <option value="0">Not Approved</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend primary"><span class="input-group-text primary">Pozzo</span></div>
            <select class="custom-select custom-select-sm" id="idposizionamento" name="idposizionamento" [(ngModel)]="idposizionamento" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of PozziList" [value]="obj.idposizionamento">{{obj.codiceprogetto}} {{obj.nomepozzo}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend primary"><span class="input-group-text primary">RIG</span></div>
            <select class="custom-select custom-select-sm" id="idpiattaforma" name="idpiattaforma" [(ngModel)]="idpiattaforma" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="0">seleziona</option>
              <option *ngFor="let obj of PiattaformeList" [value]="obj.idpiattaforma">{{obj.denominazione}}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
        <h4 class="alert-heading">Elenco utenti vuoto</h4>
        <p>Nessun utenti presente. Creare un nuovo utente e riporvare.</p>
      </div>  -->
     <div class="mat-elevation-z0 w-100" *ngIf="status=='master' && showList == true">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
    
        <!-- ID Column -->
        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
          <td mat-cell *matCellDef="let row"> {{row.data}} </td>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="descrizione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let row"> {{row.description}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="rig">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> RIG </th>
          <td mat-cell *matCellDef="let row"> {{row.piattaforma}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="pozzo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Pozzo </th>
          <td mat-cell *matCellDef="let row"> {{row.pozzo}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Action </th>
          <td mat-cell *matCellDef="let row" class="text-right"> 
            <div role="group" class="btn-group-sm btn-group">
                <button class="btn-shadow btn btn-success btn-sm mr-1 mt-1 mb-1" *ngIf="row.approved == '1'" (click)="authorize(row.idmainevent, '0')"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" title="Aproved"></i></button>
                <button class="btn-shadow btn btn-danger btn-sm mr-1 mt-1 mb-1" *ngIf="row.approved == '0'" (click)="authorize(row.idmainevent,'1')"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" title="thumbs-o-down"></i></button>
                <button class="btn-shadow btn btn-warning btn-sm mr-1 mt-1 mb-1" (click)="loadMainEvent(row.idmainevent)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica main event"></i></button>
                <button class="btn-shadow btn btn-danger btn-sm mt-1 mb-1" (click)="openModalDelete(content, row.idmainevent)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Elimina main event"></i></button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="d-block p-4 text-center card-footer">
    </div>
  </div>
  
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="this.idmodal == 'normal'">{{bodyModal}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      <button *ngIf="this.actionOp == '1'" type="button" class="btn btn-primary" (click)="deleteMainEvent(); c('Close click')">{{buttonAction}}</button>
      <button *ngIf="this.actionOp == '2'" type="button" class="btn btn-primary" (click)="saveMainEvent(); c('Close click')">{{buttonAction}}</button>
    </div>
  </ng-template>
<body class="text-center" style="height: 100vh;">
  <form #loginform="ngForm" (ngSubmit)="onClickSubmit(loginform.value)" class="form-signin">
<img class="mb-4" src="../../../../assets/images/logo_sboss.png"  />
<!-- <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1> -->
<label for="username" class="sr-only">Username</label>
<input type="text" placeholder="Username" required="required"
                          aria-required="true" class="form-control"
                          id="username"
                          name="username"
                          ngModel autofocus style="margin-bottom: -3px;"/>
<!-- <input type="text" id="inputEmail" ngModel class="form-control" placeholder="Username" required autofocus> -->
<label for="inputPassword" class="sr-only">Password</label>
<!-- <input type="password" id="inputPassword" class="form-control" placeholder="Password" required> -->
<input type="password" placeholder="Password" required="required"
                          aria-required="true" class="form-control"
                          id="password"
                          name="password"
                          ngModel >
<!-- <div class="checkbox mb-3">
  <label>
    <input type="checkbox" value="remember-me"> Remember me
  </label>
</div> -->
<div class="alert alert-danger" role="alert" *ngIf="auth == 'false'">
  <strong>{{message}}</strong>
</div>
<button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
<p class="mt-5 mb-3 text-muted">&copy; Explorer Marine SBOSS - {{yearFront}}</p>
</form>
</body>
<!-- <div class="h-100 bg-midnight-bloom bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <form #loginform="ngForm" (ngSubmit)="onClickSubmit(loginform.value)">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <div class="row">
                <div class="col-4">
                  <div class="app-logo-inverse mx-auto mb-3"></div>
                </div>
                <div class="col-8">
                  <h4 class="mt-2">
                    <div>Benvenuti su geosbossnavyweb 1.0,</div>
                    <span>autenticarsi per accedere al sistema.</span>
                  </h4>
                </div>
              </div>

            </div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                 class="form-group">
              <div><input type="text" placeholder="Username" required="required"
                          aria-required="true" class="form-control"
                          aria-describedby="exampleInputGroup1__BV_description_"
                          id="username"
                          name="username"
                          ngModel>
              </div>
            </div>
            <div id="exampleInputGroup2" role="group" class="form-group">
              <div><input type="password" placeholder="Password" required="required"
                          aria-required="true" class="form-control"
                          id="password"
                          name="password"
                          ngModel></div>
            </div>
            <div id="exampleInputGroup2" role="group" class="form-group">
              <select class="form-control" id="exampleFormControlSelect1" placeholder="Password" required="required"
              aria-required="true" id="schema" name="schema" ngModel>
                <option value="0">...</option>
                <option *ngFor="let schema of Schemas" value="{{schema.name}}">{{schema.description}}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer clearfix">
            
              <div class="col-10">
                <div class="alert alert-success float-left mb-0 w-100" *ngIf="auth === 'true'" role="success">
                  {{message}}
                </div>
                <div class="alert alert-danger float-left mb-0 w-100" *ngIf="auth === 'false'" role="danger">
                  {{message}}
                </div>
              </div>
              <div class="col-2">
                <div class="float-right">
                  <button type="submit" class="btn btn-primary btn-lg">Login</button>
                </div>
              </div>

          </div>
        </form>
         </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Banca Profilo SpA {{yearFront}}
      </div>
    </div>
  </div>
</div> -->

<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>

<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Colorful Dots</h5>
        <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div class="vertical-timeline-item dot-danger vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">All Hands Meeting</h4>
              </div>
            </div>
          </div>
          <div class="vertical-timeline-item dot-warning vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <p>Yet another one, at <span class="text-success">15:00 PM</span></p>
              </div>
            </div>
          </div>
          <div class="vertical-timeline-item dot-success vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">Build the production release
                  <div class="badge badge-danger ml-2">NEW</div>
                </h4>
              </div>
            </div>
          </div>
          <div class="vertical-timeline-item dot-primary vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">
                  Something not important
                </h4>
              </div>
            </div>
          </div>
          <div class="vertical-timeline-item dot-info vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">This dot has an info state</h4>
              </div>
            </div>
          </div>
          <div class="vertical-timeline-item dot-dark vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">This dot has a dark state</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Simple Dots</h5>
        <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
          <div class="vertical-timeline-item vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in"><h4 class="timeline-title">All Hands Meeting</h4></div>
            </div>
          </div>
          <div class="vertical-timeline-item vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in"><p>Yet another one, at <span class="text-success">15:00 PM</span></p></div>
            </div>
          </div>
          <div class="vertical-timeline-item vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in"><h4 class="timeline-title">Build the production release</h4></div>
            </div>
          </div>
          <div class="vertical-timeline-item vertical-timeline-element">
            <div><span class="vertical-timeline-element-icon bounce-in"></span>
              <div class="vertical-timeline-element-content bounce-in"><h4 class="timeline-title text-success">Something not important</h4></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>

<form [formGroup]="form" autocomplete="off">
    <mat-table [dataSource]="dataSource">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.get('id').value}}. </mat-cell>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="userId">
        <mat-header-cell *matHeaderCellDef> User </mat-header-cell>
        <mat-cell *matCellDef="let element" [formGroup]="element">
          <mat-form-field floatLabel="never">
            <mat-select formControlName="userId" (selectionChange)="onUserChange($event, element)" required>
              <mat-option *ngFor="let user of users" [value]="user.id">
                {{ user.username }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
        <mat-cell *matCellDef="let element;" [formGroup]="element">
          <mat-form-field floatLabel="never" *ngIf="element.get('userId').value !== 3">
            <input matInput placeholder="Title" formControlName="title" required>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</form>
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Form value
      </mat-panel-title>
    </mat-expansion-panel-header>
    <code>
      {{form.value | json}}
    </code>
  </mat-expansion-panel>
</mat-accordion>
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { RestApiService } from 'src/app/shared/rest-api.service';

@Injectable({
    providedIn: 'root'
  })
export class PrintPreventivi {
    Preventivo: any = [];
    DettaglioPreventivo: any = [];
    TotaliPreventivo: any = [];
    InfoAzienda: any = [];

    constructor() { }
        
      company_logo_src = "";
      company_logo_w = 0;
      company_logo_h = 0;
      
      fontSizes={
        HeadTitleFontSize:18,
        Head2TitleFontSize:16,
        TitleFontSize:14,
        SubTitleFontSize:12,
        NormalFontSize:10,
        SmallFontSize:8
      };
       
      lineSpacing={
        NormalSpacing:12,
        Spacing12:12,
        Spacing14:14,
        Spacing16:16,
        Spacing18:18,
        Spacing20:20
      };
      
      generate_acquisto(preventivo, dettagliopreventivo, totaliapreventivo, infoazienda) {
        
          this.Preventivo = preventivo; 
          this.DettaglioPreventivo = dettagliopreventivo;
          this.TotaliPreventivo = totaliapreventivo;
          this.InfoAzienda = infoazienda;
          this.company_logo_src = this.InfoAzienda[0].logo_path;
          this.company_logo_w = parseInt(this.InfoAzienda[0].logo_w);
          this.company_logo_h = parseInt(this.InfoAzienda[0].logo_h);
          var doc = new jsPDF('p', 'pt');
        
          var rightStartCol1=400;
          var rightStartCol2=480;
      
      
          var InitialstartX=40;
          var startX=40;
          var InitialstartY=50;
          var startY=0;
      
          var lineHeights=12;
      
        var res = (doc as any).autoTableHtmlToJson(document.getElementById("basic-table"));
        res = (doc as any).autoTableHtmlToJson(document.getElementById("tblInvoiceItemsList"));
          
        doc.setFontSize(this.fontSizes.SubTitleFontSize);
        doc.setFont('times','bold');
        //doc.setFont('times','bold');
        
        //pdf.addImage(agency_logo.src, 'PNG', logo_sizes.centered_x, _y, logo_sizes.w, logo_sizes.h);
        doc.addImage(this.company_logo_src, 'PNG', startX,startY+=50, this.company_logo_w, this.company_logo_h);
    
        doc.text(this.InfoAzienda[0].ragionesociale, startX, startY+=15+this.company_logo_h);
        doc.setFontSize(this.fontSizes.SmallFontSize);
        doc.text(this.InfoAzienda[0].indirizzo + " - " + this.InfoAzienda[0].citta + "  " + this.InfoAzienda[0].cap + "  " + this.InfoAzienda[0].provincia, startX, startY+=this.lineSpacing.NormalSpacing);

        doc.setFontSize(this.fontSizes.SmallFontSize);
        doc.setFont('times','bold');
        doc.text("P. Iva: " + this.InfoAzienda[0].partitaiva + " C. Fisc.: " + this.InfoAzienda[0].codicefiscale, startX, startY+=this.lineSpacing.NormalSpacing);
        doc.setFontSize(this.fontSizes.SmallFontSize);
        doc.setFont('times','bold');
        doc.text("Telefono: " + this.InfoAzienda[0].telefono + " - Email: " + this.InfoAzienda[0].email, startX, startY+=this.lineSpacing.NormalSpacing);
        doc.setLineWidth(1);
        
        doc.line(40, startY+30, 552, startY+30);
        doc.line(40, startY+50, 552, startY+50);
        
        // if(tipologia == "ddt"){
        //   doc.line(40, startY+70, 552, startY+70);
        // }
        

        doc.setFont('times','bold');
        doc.text("www.lkreazioni.com", startX, startY+=this.lineSpacing.NormalSpacing);
        doc.setFontSize(this.fontSizes.NormalFontSize);

        doc.text("Preventivo Nr.: " + this.Preventivo[0].progressivo + "      " + "Data: " + this.Preventivo[0].datapreventivo + "      " + "Pagamento: " + this.Preventivo[0].pagamento, startX, startY+=this.lineSpacing.NormalSpacing+20);

        var tempY=InitialstartY;
            
        doc.setFont('times','bold');
        doc.setFontSize(this.fontSizes.NormalFontSize);
        
        doc.text("PREVENTIVO",  rightStartCol1+20, tempY+=this.lineSpacing.NormalSpacing);

        doc.setFont('times','normal');
         
        doc.setLineWidth(1);
        
        doc.setFontSize(this.fontSizes.NormalFontSize);
        doc.setFont('times','bold');
      
        //-------Customer Info Shipping---------------------
        var rightcol1=340;
        var rightcol2=400;
      
        startY=InitialstartY;
        doc.setFont('times','bold');
        doc.text("Spett.le,", rightcol2+20, startY+=30);
        doc.text(this.Preventivo[0].ragionesociale, rightcol2+20, startY+=this.lineSpacing.NormalSpacing);
        doc.setFontSize(this.fontSizes.NormalFontSize);
        doc.setFont('times','bold');
        doc.text(this.Preventivo[0].indirizzo, rightcol2+20, startY+=this.lineSpacing.NormalSpacing);
        
         
        doc.setFont('times','bold');
        doc.text(this.Preventivo[0].citta + " " + this.Preventivo[0].cap + " " + this.Preventivo[0].provincia, rightcol2+20, startY+=this.lineSpacing.NormalSpacing);
      
        doc.setFont('times','bold');
        doc.text("P.Iva: " + this.Preventivo[0].partitaiva, rightcol2+20, startY+=this.lineSpacing.NormalSpacing);


        var header = function(data) {
            doc.setFontSize(8);
            doc.setTextColor(40);
            doc.setFont('times','nornal')
            //doc.text("TAX INVOICE", data.settings.margin.left, 50);
       
            //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
           // doc.text("Testing Report", 110, 50);
          };
         // doc.autoTable(res.columns, res.data, {margin: {top:  startY+=30}});
         doc.setFontSize(8);
         doc.setFont('times','nornal')
         
          var startDet = 80; 

          var options = {
            beforePageContent: header,
            margin: {
              top: 50 
            },
            styles: {
                fontWeight: 'normal',
                overflow: 'linebreak',
                columnWidth: 'auto'
              },
              columnStyles: {
                country: {columnWidth: 'wrap'}
              },
            startY: startY+=startDet
          };
        
        var columns = [
            {title: "Codice", dataKey: "barcode",width: 90},
            {title: "Descrizione", dataKey: "descrizione",width: 120}, 
            {title: "UM", dataKey: "um",width: 40}, 
            {title: "Quantita", dataKey: "quantita",width: 40}, 
            {title: "Prezzo", dataKey: "prezzo",width: 40}, 
            {title: "Sc.", dataKey: "sconto",width: 40}, 
            {title: "Totale", dataKey: "totale",width: 40}, 
            {title: "Iva", dataKey: "iva",width: 40}, 
        ];
        //console.log(this.DettaglioAcquisto);
        var rows = this.DettaglioPreventivo;
      
        (doc as any).autoTable(columns, rows, options);   //From dynamic data.
        // doc.autoTable(res.columns, res.data, options); //From htmlTable
        
        //-------Invoice Footer---------------------
        var rightcol1=340;
        var rightcol2=430;
      
        startY=(doc as any).autoTableEndPosY()+30;
        doc.setFontSize(this.fontSizes.NormalFontSize);
        
        doc.setFont('times','bold');
        doc.text("Totale Imponibile ", rightcol1+40, startY+=this.lineSpacing.NormalSpacing);
        doc.text("€. " + parseFloat(this.TotaliPreventivo[0].imponibile).toFixed(2), rightcol2+40, startY);
        doc.text("Totale Imposta ", rightcol1+40, startY+=this.lineSpacing.NormalSpacing);
        doc.text("€. " + parseFloat(this.TotaliPreventivo[0].imposta).toFixed(2), rightcol2+40, startY);
        doc.text("Totale Ordine ", rightcol1+40, startY+=this.lineSpacing.NormalSpacing);
        doc.text("€. " + parseFloat(this.TotaliPreventivo[0].totale).toFixed(2), rightcol2+40, startY);
    //doc.text('Authorised Signatory', rightcol2, startY+=this.lineSpacing.NormalSpacing+50);
        
    //doc.save("invoice.pdf");
    return doc.output('datauristring');
    }
    
      
}
<link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
<link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.2.2/font/bootstrap-icons.css" rel="stylesheet">

<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()" (mouseleave)="sidebarOut()"
     (mouseout)="sidebarHover()" style="margin-top:50px;background-color: #fff!important; border-right:1px #ccc solid;">
  <div class="app-header__logo" style="background-color: #fff!important;">
    <div class="logo-src" style="background-color: #fff!important;"></div>
    <div class="header__pane ml-auto" style="background-color: #fff!important;">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content" style="background-color: #fff!important;max-height: 90%;overflow-y: scroll;">
     <div class="app-sidebar-scroll">
      <div class="container p-0">
        <!-- <button type="button" class="btn btn-outline-primary btn-block ml-4 mt-1 mb-2" style="width:200px;" (click)="openSearch()">Search Data</button> -->
        <a routerLink="/search" routerLinkActive="active-item" *ngIf="item_visible == true"
                        [routerLinkActiveOptions]="{exact: true}" class="btn btn-outline-primary btn-block ml-4 mt-1 mb-2" style="width:200px;">
                          Search Data
                        </a>
        <div id="accordion" style="background-color: #fff; border:0px solid #fff;">
            <div class="card" style="background-color: #fff; border:0px solid #fff;">
                <div class="card-header p-0 m-0" id="headingOne" >
                    <h5 class="mb-0 d-inline" style="background-color: #fff; border:0px solid #fff;">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style="background-color: #fff; border:0px solid #fff;text-decoration: none; ">
                          <i class="fa fa-compass fa-lg" style="color:#0f1a34;" *ngIf="item_visible == true"></i>
                          <i class="fa fa-compass fa-2x" style="color:#0f1a34; padding-left:15px;" *ngIf="item_visible == false"></i>
                          <span class="ms-1 d-none d-sm-inline pl-2  title" *ngIf="item_visible == true" style="font-weight: bold;">RIG</span>
                        </button>
                     </h5>
                     <!-- <a href="#" data-target="[data-parent='#child1']" data-toggle="collapse" class="my-2 float-right">close all</a> -->
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" *ngIf="item_visible == true">
                  <div class="card-body pl-2" style="background-color: #fff;">
                    <div *ngFor="let obj of ListPlatform" class="vsm-item mb-1 itemhover">
                      <!-- <a routerLink="" routerLinkActive="active-item" 
                                            [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;"><span
                      class="badge w-100 text-left mb-1 pt-1 pl-2 itemhover text-decoration-none" style="border:0px solid #fff;font-family: merriweather sans,sans-serif; font-size:12pt;">{{obj.denominazione}}</span></a> -->
                      <a href="#"  routerLinkActive="active-item" (click)="navigateToMap(obj.idpiattaforma,obj.latitudine, obj.longitudine)"
                       class="badge w-100 text-left pl-4 itemhover text-decoration-none rounded-sm" data-toggle="collapse" [attr.data-target]="'#collapseOne' + y" style="border:0px solid #fff;font-family: 'Calibri Light',merriweather sans,sans-serif; font-size:12pt;">{{obj.denominazione}}</a>
                      <!-- <a href="#" class="badge w-100 text-left mb-1 pt-1 pl-2 itemhover text-decoration-none" data-toggle="collapse" [attr.data-target]="'#collapseOne' + y" style="border:0px solid #fff;font-family: merriweather sans,sans-serif; font-size:12pt;">{{obj1.anno}}</a> -->
                    </div>
                  </div>
                </div>
            </div>
            <div class="card " style="background-color: #fff;border:0px solid #fff;">
                <div class="card-header p-0 m-0 " id="headingTwo">
                    <h5 class="mb-0 p-0 m-0 " style="text-decoration: none;border:0px solid #fff;">
                      <button class="btn btn-link collapsed  text-decoration-none" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <i class="fa fa-location-arrow fa-lg" style="color:#0f1a34;" *ngIf="item_visible == true"></i>
                        <i class="fa fa-location-arrow fa-2x" style="color:#0f1a34; padding-left:15px;" *ngIf="item_visible == false"></i>
                        <span class="ms-1 d-none d-sm-inline pl-2  title" *ngIf="item_visible == true" style="font-size:17px;font-weight: bold;">Positioning</span>
                        <button *ngIf="activeall == false && item_visible == true" class="btn btn-light btn-sr m-0 p-0 pl-1 pr-1" id="activeall" (click)="showAllPosiz()" style="margin-left:50px!important;">{{activealltext}}</button>
                        <button *ngIf="activeall == true && item_visible == true" class="btn btn-success btn-sr m-0 p-0 pl-1 pr-1" id="activeall" (click)="hideAllPosiz()" style="margin-left:50px!important;">{{activealltext}}</button>
                      </button>
                    </h5>
                </div>
                <div id="collapseTwo" [ngClass]="iscollapseAnno == true ? 'collapse p-0 m-0s' : 'collapse p-0 m-0 show'"  aria-labelledby="headingTwo" *ngIf="item_visible == true" style="border:0px solid #fff; background-color: #fff;">
                  <div class="card-body p-2" style="background-color: #fff;">
                    <!-- <input class="form-control form-control-sm mb-2" type="text" placeholder="Search Positioning" id="search" name="search" [(ngModel)]="search" (input)="onSearchChange($event.target.value)"> -->
                    <div *ngFor="let obj of ListPosizionamenti; let x = index" class="card-body p-0 m-0" [attr.id]="'child' + x" style="border:0px solid #fff; background-color: #fff;">
                          <div *ngFor="let obj1 of obj.anni; let y = index" class="card p-0 pr-2 m-0" style="border:0px solid #fff;background-color: #fff;">
                              <div [attr.id]="'year'+obj1.anno" class="card-header p-0 m-0 mt-1">
                                  <button [attr.id]="'linkyear'+obj1.anno" href="" class="badge w-100 text-left pl-2 itemhover text-decoration-none rounded-sm bg-white" data-toggle="collapse" [attr.data-target]="'#collapseOne' + y" style="border:0px solid #fff;font-family: 'Calibri Light',merriweather sans,sans-serif; font-size:13pt;" >{{obj1.anno}}</button>
                              </div>
                              <div [ngClass]="yearsel == obj1.anno ? 'card-body p-0 m-0 collapse show' : 'card-body p-0 m-0 collapse'" [attr.data-parent]="'#child' + x" [attr.id]="'collapseOne' + y" style="border:0px solid #fff; background-color: #fff!important;">
                                <div [attr.id]="'naz'+obj2.idnazione" *ngFor="let obj2 of obj1.nazioni;  let z = index" class="card-body p-0 m-0" [attr.id]="'child' + x + '_' + z" style="background-color: #fff!important;">
                                  <div class="card p-0 m-0 mt-1" style="border:0px solid #fff; background-color: #fff!important; border:0px solid #fff!important;">
                                      <div class="card-header p-0 m-0 " style="background-color: #fff!important; border:0px solid #fff!important;">
                                          <button [attr.id]="'linknaz'+obj2.idnazione" href="" class="badge w-100 text-left pl-3 itemhover text-decoration-none rounded-sm bg-white" data-toggle="collapse" [attr.data-target]="'#collapseOne' + y + z" style="border: 0px solid #fff!important; font-family: 'Calibri Light',merriweather sans,sans-serif; font-size:12pt; font-weight: bold;">{{obj2.naz_eng}}</button>
                                      </div>
                                      <div [ngClass]="nationsel == obj2.idnazione ? 'card-body collapse show pl-1 p-0 pt-0 pb-0' : 'card-body collapse pl-1 p-0 pt-0 pb-0'" data-parent="#child{{x}}_{{z}}" [attr.id]="'collapseOne' + y + z" style="font-family: 'Calibri Light',merriweather sans,sans-serif; font-size:10pt;">
                                          <button [attr.id]="'linkpozzo'+obj3.idposizionamento" class="badge w-100 text-left pl-3 itemhover text-decoration-none rounded-sm bg-white" *ngFor="let obj3 of obj2.posizionamento" href="#" style="font-size: 14px; border: 0px solid #fff!important;" (click)="openlargemodal(obj3.idposizionamento, obj2.idnazione, obj1.anno)">
                                            <span *ngIf="obj3.codicepozzo != ''">{{obj3.codicepozzo}} - {{obj3.nomepozzo}} - {{obj3.codiceprogetto}}</span>
                                            <span *ngIf="obj3.codicepozzo == ''">{{obj3.nomepozzo}} - {{obj3.codiceprogetto}}</span>
                                          </button>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card " style="border:0px solid #0f1a34;">
              <div class="card-header p-0 m-0 " id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed  text-decoration-none" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <i class="fa fa-map fa-lg" style="color:#020f2b;" *ngIf="item_visible == true"></i>
                      <i class="fa fa-map fa-lg" style="color:#020f2b; padding-left:15px;" *ngIf="item_visible == false"></i>

                      <span class="ms-1 d-none d-sm-inline pl-2  title text-decoration-none" *ngIf="item_visible == true" style="font-weight: bold;">KML</span>
                    </button>
                  </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" *ngIf="item_visible == true">
                  <div class="card-body">
                    <div *ngFor="let obj of ListKml" class="vsm-item mb-1" style="line-height: 1.4rem; height: 1.4rem;" (click)="changeKmlStatus(obj.idkmz_user, obj.kmlactive)">
                      <button routerLinkActive="active-item" 
                                             [routerLinkActiveOptions]="{exact: true}" [ngClass]="obj.kmlactive == '1' ? 'btn btn-outline-success btn-sm btn-block p-0 active' : 'btn btn-outline-success btn-sm btn-block p-0'" class="" style="line-height: 1.4rem; height: 1.4rem;">
                                             {{obj.kmldesc}}
                    </button>
                    </div>
                  </div>
              </div>
          </div>
            <div class="card " style="border:0px solid #0f1a34;" *ngIf="auth_level == '1'">
                <div class="card-header p-0 m-0 " id="headingFour">
                    <h5 class="mb-0">
                      <button class="btn btn-link collapsed  text-decoration-none" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <i class="fa fa-lock fa-lg" style="color:#0f1a34;" *ngIf="item_visible == true"></i>
                        <i class="fa fa-lock fa-2x" style="color:#0f1a34; padding-left:15px;" *ngIf="item_visible == false"></i>

                        <span class="ms-1 d-none d-sm-inline pl-2  title" *ngIf="item_visible == true" style="font-weight: bold;">Admin Area</span>
                      </button>
                    </h5>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" *ngIf="item_visible == true">
                    <div class="card-bodycard-body pl-0 pt-1">
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/anagraficakml" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Kml</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/piattaforme" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Piattaforme</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/pozzi" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Pozzi</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/mainevents" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Main Events</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/anagrafica-mainevents" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Anagrafica Main Events</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/clienti" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Clienti</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/utenti" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Utenti</span>
                        </a>
                      </div>
                      <div class="vsm-item" style="line-height: 1.4rem; height: 1.4rem;">
                        <a routerLink="/downloads" routerLinkActive="active-item" 
                        [routerLinkActiveOptions]="{exact: true}" class="vsm-link itemhover pl-4" style="position:absolute;line-height: 1.4rem;height: 22px;width: 200px!important;">
                          <span class="vsm-title whitebgd itemhover" style="position:absolute;line-height: 1.4rem;height: 22px;width: 223px!important;">Gestione Downloads</span>
                        </a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  </div>
</div>


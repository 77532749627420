import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestApiService } from 'src/app/shared/rest-api.service';
import {Md5} from 'ts-md5/dist/md5';
import * as schemas from 'src/assets/images/schemas.json';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styleUrls: ['./login-boxed.component.css']
})

export class LoginBoxedComponent implements OnInit {
  auth: string = "init";
  message: string = "";
  yearFront:string;
  schema:string;
  Schemas: any = [];
  ServerConf: any = [];
  token:string; 
  ListShapeFiles: any = [];
  ListDataOffset: any = [];
  ListSettings: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public restApi: RestApiService,
    private router:Router
  ) { }
  
  ngOnInit() {
    var d = new Date();
    var n = d.getFullYear();
    this.yearFront = n.toString();
    this.token = "dkfjsw3xHRWxmfsfe3QDDqZw429013";
    this.getSettings();
  }

  getShapeDataList(){
    this.restApi.getShapeFiles(this.token).subscribe((data: {}) => {
      this.ListShapeFiles = data;
      sessionStorage.setItem("ListShapeFiles", JSON.stringify(this.ListShapeFiles));
      console.log('listshapefiles = ' + JSON.stringify(this.ListShapeFiles));
      this.router.navigate(['/Dashboard/analysis']);
    });
  }

  getSettings(){
    this.restApi.getSettings().subscribe((data: {}) => {
      this.ListSettings = data;

      this.ListSettings.forEach((currentValue, index) => {
        var idsettings = currentValue.idsettings;
        var property = currentValue.property;
        var parameters = currentValue.parameters;

        sessionStorage.setItem(property, parameters);

      });
    });
  }

  onClickSubmit(data) {
    const md5 = new Md5();
    var user =  data.username;
    //console.log('user = ' + user); 
    var pass = md5.appendStr(data.password).end();
    //console.log('pwd = ' + pass);

    this.login(user, pass);
    
 }

    // Get employees list
    login(username, password) {
      return this.restApi.getLogin(username,password).subscribe((data: {}) => {
        var login = data;

        var idutente = login[0].idutente;

        //console.log('idutente = ' + idutente);
        sessionStorage.removeItem("authorize");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("auth_level");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("attivo");
        sessionStorage.removeItem("dbname");
        sessionStorage.removeItem("schema");
        sessionStorage.removeItem("logo");
        sessionStorage.removeItem("idcliente");

        sessionStorage.removeItem("latsession");
        sessionStorage.removeItem("lngsession");
        sessionStorage.removeItem("idplatform");
        if(idutente == "0000"){
          sessionStorage.setItem("authorize", "0000");
          this.auth = "false";
          this.message = "Incorrect credentials. Try again.";
        }else{
          var active = login[0].attivo;
          if(active == "1"){
            sessionStorage.setItem("authorize", login[0].idutente);
            sessionStorage.setItem("idutente", login[0].idutente);
            sessionStorage.setItem("user", login[0].nome + " " + login[0].cognome);
            sessionStorage.setItem("username", login[0].username);
            sessionStorage.setItem("auth_level", login[0].auth_level);
            sessionStorage.setItem("email", login[0].email);
            sessionStorage.setItem("attivo", login[0].attivo);
            sessionStorage.setItem("ragionesociale", login[0].ragionesociale);
            sessionStorage.setItem("idcliente", login[0].idcliente);
            
            if(login[0].logo){
              sessionStorage.setItem("logo", login[0].logo);
            }else{
              sessionStorage.setItem("logo", "user.png");
            }
            
            this.auth = "true";
            this.message = "Authorized user.";
            
            var user = login[0].username;

            //console.log('login =' + login[0].Nome);
            this.getShapeDataList();
            
          }else{
            sessionStorage.setItem("authorize", "9999");            
            this.auth = "false";
            this.message = "Inactive user";
          }

        }
                
      });
    }

    genHexString(len) {
      let output = '';
      for (let i = 0; i < len; ++i) {
          output += (Math.floor(Math.random() * 16)).toString(16);
      }
      return output;
    }


}


interface MyObj {
  IdUtente: string;
  Username: string;
  Nome: string;
  Cognome: string;
  AuthLevel:string;
  Email:string;
  Attivo:string;
}
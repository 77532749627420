<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
        <div class="form-inline">
            <h5>Search Data</h5>
            <span class="ml-auto"><button type="button" class="btn btn-link" (click)="gotoDash()">Dashboard</button></span>
        </div>
    </div>
    <div class="card-body">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-default">Insert keyword for search</span>
            </div>
            <input type="text" class="form-control" placeholder="Search data" id="search" name="search" autofocus [(ngModel)]="search" (input)="onSearchChange($event.target.value)" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" #yourInput (blur)="onBlur($event)">
        </div>
        <div class="card" *ngIf="showresultrig">
            <h6 class="card-header p-1 pl-2">Result search rig for <span style="color:blue;">{{search}}</span></h6>
            <div class="card-body">
                <table class="table table-sm table-hover table-striped" *ngIf="listrigshow">
                    <thead>
                      <tr>
                        <th scope="col">RIG</th>
                        <th scope="col">Latitude</th>
                        <th scope="col">Longitude</th>
                        <th scope="col">Heading</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of ListPlatform; let i = index">
                        <td>{{item.denominazione}}</td>
                        <td>{{item.latitudine}}</td>
                        <td>{{item.longitudine}}</td>
                        <td>{{item.heading}}</td>
                        <td style="text-align: right"><button type="button" class="btn btn-primary btn-sm" (click)="gotoRig(item.idplatformuser, item.latitudine, item.longitudine)">Show</button></td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
        <div class="card mt-2" *ngIf="showresultpos">
            <h6 class="card-header p-1 pl-2">Result search positioning for <span style="color:blue;">{{search}}</span></h6>
            <div class="card-body">
                <table class="table table-sm table-hover table-striped" *ngIf="listposshow">
                    <thead>
                      <tr>
                        <th scope="col">Project Code</th>
                        <th scope="col">Well Code</th>
                        <th scope="col">Well Name</th>
                        <th scope="col">Year</th>
                        <th scope="col">Nation</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of ListPosizionamenti; let i = index">
                            <td>{{item.codiceprogetto}}</td>
                            <td>{{item.codicepozzo}}</td>
                            <td>{{item.nomepozzo}}</td>
                            <td>{{item.anno}}</td>
                            <td>{{item.nazione}}</td>
                            <td style="text-align: right"><button type="button" class="btn btn-primary btn-sm" (click)="gotoPlatform(item.anno, item.idnazione, item.idposizionamento)">Show</button></td>
                        </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    </div>
  </div>
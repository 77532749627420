<div class="main-card mb-0 card" style="height: calc(100vh - 60px);">
    <div class="card-header pb-1">
     <div class="form-inline" *ngIf="status=='master'">
        <label class="sr-only" for="inlineFormInputName2">Name</label>
        <input type="text" class="form-control form-control-sm mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Ricerca Piattaforme" [(ngModel)]="keysearch">
        <button type="button" class="btn btn-primary btn-sm mb-2 mr-1" (click)="searchItemPiattaforme()">Search</button>
        <button type="button" class="btn btn-warning btn-sm mb-2" (click)="loadPiattaforme()">Reset</button>
        <button type="button" class="btn btn-success btn-sm ml-2 mb-2" (click)="loadDetail()"><fa-icon class="mr-2" [icon]="faPlus"></fa-icon> New Rig</button>
        <button type="button" class="btn btn-primary btn-sm ml-4 mb-2" (click)="startTimer()"><i class="fa fa-fw" aria-hidden="true" title="enable monitoring"></i> Enable monitoring</button>
        <button type="button" class="btn btn-danger btn-sm ml-2 mb-2" (click)="stopTimer()"><i class="fa fa-fw" aria-hidden="true" title="disable monitoring"></i> Disable monitoring</button>
        <span [ngClass]="monitorstate == true ? 'green' : 'red'"><strong>{{monitordesc}}</strong></span>
        <span class="ml-auto"><strong><a routerLink="{{root_href}}">{{root_title}}</a></strong></span>
      </div>
  
      <div class="row" *ngIf="status=='detail'">
        <div class="col-md-8 mt-2">
          <div class="form-inline">
            <div class="form-group">
              <h5 class="card-title"><strong>{{denominazione}}</strong></h5>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-1 mb-2">
          <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Elenco Piattaforme</button>
          <button class="btn btn-primary active pull-right ml-2" (click)="savePiattaforma()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Salva</button>
          <button class="btn btn-primary active pull-right" (click)="newPiattaforma()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo</button>
        </div>
      </div>
    </div>
  
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-4 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Denominazione</span></div>
            <input type="text" class="form-control" id="denominazione" name="denominazione" [(ngModel)]="denominazione"/>
          </div>
        </div>
        <div class="col-md-4 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text primary">Latitudine</span></div>
            <input type="text" class="form-control" id="latitudine" name="latitudine" [(ngModel)]="latitudine"/>
          </div>
        </div>
        <div class="col-md-4 mt-2">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text primary">Longitudine</span></div>
              <input type="text" class="form-control" id="longitudine" name="longitudine" [(ngModel)]="longitudine"/>
            </div>
          </div>
    </div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend primary"><span class="input-group-text primary">Heading</span></div>
          <input type="text" class="form-control" id="heading" name="heading" [(ngModel)]="headings" />
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend warning"><span class="input-group-text primary">Latitudine°</span></div>
          <input type="text" class="form-control" id="latitudine_gradi" name="latitudine_gradi" [(ngModel)]="latitudine_gradi"/>
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend warning"><span class="input-group-text primary">Longitudine°</span></div>
          <input type="text" class="form-control" id="longitudine_gradi" name="longitudine_gradi" [(ngModel)]="longitudine_gradi"/>
        </div>
      </div>
    </div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
        <div class="col-md-4 mt-2">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend warning"><span class="input-group-text primary">Tipologia</span></div>
              <!-- <input type="text" class="form-control" id="tipo_piattaforma" name="tipo_piattaforma" [(ngModel)]="tipo_piattaforma"/> -->
              <select class="custom-select custom-select-sm" id="tipo_piattaforma" name="tipo_piattaforma" [(ngModel)]="tipo_piattaforma" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
                <option value="1">Piattaforma fissa</option>
                <option value="2">Piattaforma mobile</option>
              </select>
            </div>
        </div>   
        <div class="col-md-4 mt-2">
          <div class="col-md-12 p-0">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend"><span class="input-group-text primary">Cliente</span></div>
              <select class="custom-select custom-select-sm" name="idcliente" id="idcliente" placeholder="cliente" [(ngModel)]="idcliente" style="margin:0rem;">
                <option value="">select...</option>
                <option *ngFor="let um of Clienti" value="{{um.idcliente}}">{{um.ragionesociale}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend primary"><span class="input-group-text primary">Attivo</span></div>
            <select class="custom-select custom-select-sm" id="attivo" name="attivo" [(ngModel)]="attivo" style="border-top-left-radius: 0rem; border-bottom-left-radius: 0rem;">
              <option value="1">Attivo</option>
              <option value="0">Non attivo</option>
            </select>
          </div>
        </div>
        <div class="col-md-2 mt-2">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend warning"><span class="input-group-text primary">Last Update</span></div>
            <input type="text" class="form-control" id="lastupdate" name="lastupdate" [(ngModel)]="lastupdate"/>
          </div>
        </div>
    </div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend success"><span class="input-group-text primary">Server Rdp</span></div>
          <input type="text" class="form-control" id="heading" name="heading" [(ngModel)]="serverrdp" />
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend success"><span class="input-group-text primary">User Rdp</span></div>
          <input type="text" class="form-control" id="latitudine_gradi" name="latitudine_gradi" [(ngModel)]="userrdp"/>
        </div>
      </div>
      <div class="col-md-4 mt-2">
        <div class="input-group input-group-sm">
          <div class="input-group-prepend success"><span class="input-group-text primary">Pwd Rdp</span></div>
          <input type="text" class="form-control" id="longitudine_gradi" name="longitudine_gradi" [(ngModel)]="passwordrdp"/>
        </div>
      </div>
    </div>
    <div>
    <div class="form-row ml-4 mr-4 mb-2"  *ngIf="status=='detail'">
      <div class="col-12">
        <div class="input-group pt-1 pb-0 m-0" *ngIf="showupload == false">
          <div class="input-group-prepend">
              <span class="input-group-text bg-primary text-white">Image RIG</span>
              <!-- <span class="input-group-text"  *ngIf="progress > 0">{{progress}}% {{message}}</span> -->
          </div>
          <input type="text" class="form-control" id="image_rig" name="image_rig" [(ngModel)]="image_rig"/>          
          <div class="input-group-append">
              <button id="upload" class="btn btn-outline-primary" [disabled]="!status" (click)="showUploadForm()">Upload image</button>
          </div>
        </div> 
        <div *ngIf="showupload == true">
          <div *ngIf="uploadResponse && uploadResponse.status === 'error'">
            {{ uploadResponse.message }}
          </div>
          <div *ngIf="uploadResponse && uploadResponse.status === 'success'">
            <img [src]='uploadResponse.url' />
          </div>
        
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
        
            <!-- <input type="file" name="avatar" (change)="onFileSelect($event)" /> -->
            <!-- <button type="submit">Upload</button> -->
            <div class="input-group pt-1 pb-0 m-0">
              <div class="input-group-prepend">
                  <span class="input-group-text bg-primary text-white">Upload Image RIG (268 × 229 px - jpg)</span>
                  <!-- <span class="input-group-text"  *ngIf="progress > 0">{{progress}}% {{message}}</span> -->
              </div>
              <input type="file" id="avatar" name="avatar" #file class="form-control" (change)="onFileSelect($event)" accept="image/*"/> <br /> 
              <div class="input-group-append">
                  <button id="upload" class="btn btn-outline-primary" [disabled]="!status"  type="submit">Upload</button>
              </div>
            </div>  
          </form>
        </div>
      <!-- </div> -->
      </div>
      </div>
    </div>
    <div class="row m-2" *ngIf="status=='detail'">
      <div class="col-md-8 mt-2">
        <div class="form-inline">
          <div class="form-group">
            <h5 class="card-title"><strong>Allegati</strong></h5>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-1 mb-2">
        <button class="btn btn-primary active pull-right ml-2" (click)="loadMaster()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Elenco Piattaforme</button>
        <button class="btn btn-primary active pull-right ml-2" (click)="savePiattaforma()"><i class="fa fa-fw" aria-hidden="true" title="floppy-o"></i> Salva</button>
        <button class="btn btn-primary active pull-right" (click)="newPiattaforma()"><i class="fa fa-fw" aria-hidden="true" title="plus-circle"></i> Nuovo</button>
      </div>
    </div>
      <!-- <div *ngIf="isVisibleTableSearch === true" style="overflow-y: scroll; overflow-x: hidden; height: 200px; margin-left:18px;padding-left:-5px; padding-right:16px; border:2px solid #999">
        <table class="table table-sm table-bordered table-hover ml-3">
          <thead>
            <tr>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Codice</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;" colspan="3">Città</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Provincia</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">CAP</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Regione</th>
              <th class="bg-primary" style="font-size:8pt;color:#fff;font-weight:normal;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of Locations; let i = index">
              <th style="font-size:8pt;font-weight:normal;">{{item.idcitta}}</th>
              <td style="font-size:8pt;font-weight:normal;" colspan="3">{{item.comune}}</td>
              <td style="font-size:8pt;font-weight:normal;" >{{item.provincia}}</td>
              <td style="font-size:8pt;font-weight:normal;">{{item.cap}}</td>
              <th style="font-size:8pt;font-weight:normal;">{{item.regione}}</th>
              <td style="font-size:8pt;font-weight:normal;">
                <button class="btn btn-outline-success btn-sm pull-right" style="margin:3px;" (click)="selectLocationItem(item.idcitta)">
                  <i class="fa fa-fw" aria-hidden="true" title="seleziona città"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <div class="alert alert-success m-2" role="alert" *ngIf="showList == false">
        <h4 class="alert-heading">Elenco piattaforme vuoto</h4>
        <p>Nessuna piattaforma presente. Creare una nuova piattaforma e riporvare.</p>
      </div>  
     <div class="mat-elevation-z0" *ngIf="status=='master' && showList == true">
      <table mat-table [dataSource]="dataSource" matSort>
    
        <!-- ID Column -->
        <ng-container matColumnDef="denominazione">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Piattaforma </th>
          <td mat-cell *matCellDef="let row"> {{row.denominazione}} </td>
        </ng-container>
    
        <!-- Progress Column -->
        <ng-container matColumnDef="latitudine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitudine </th>
          <td mat-cell *matCellDef="let row"> {{row.latitudine}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="longitudine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitudine </th>
          <td mat-cell *matCellDef="let row"> {{row.longitudine}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="heading">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Heading </th>
          <td mat-cell *matCellDef="let row"> {{row.heading}} </td>
        </ng-container>

        <!-- Name Column 
        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row"> {{row.ragionesociale}} </td>
        </ng-container>-->

        <!-- Name Column -->
        <ng-container matColumnDef="latitudine_gradi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitudine </th>
            <td mat-cell *matCellDef="let row">  
              <div class="form-check form-check-inline">            
                <div class="threshold mr-1">
                  {{row.latitudine_gradi}}<span></span>
                </div>
              
                <div class="singlequot mr-1">
                  {{row.latitudine_primi}}<span></span>
                </div>
              
                <div class="doublequot">
                  {{row.latitudine_secondi}}<span></span>
                </div>
              </div>
            </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="longitudine_gradi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitudine </th>
            <td mat-cell *matCellDef="let row">         
              <div class="form-check form-check-inline">            
                <div class="threshold mr-1">
                  {{row.longitudine_gradi}}<span></span>
                </div>
              
                <div class="singlequot mr-1">
                  {{row.longitudine_primi}}<span></span>
                </div>
              
                <div class="doublequot">
                  {{row.longitudine_secondi}}<span></span>
                </div>
              </div>
           </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="tipo_piattaforma">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo piattaforma </th>
            <td mat-cell *matCellDef="let row"> {{row.tipo_piattaforma_desc}} </td>
        </ng-container>        

        <!-- Name Column -->
        <ng-container matColumnDef="lastupdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Update </th>
            <td mat-cell *matCellDef="let row"> {{row.lastupdate}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="stato">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stato </th>
            <td mat-cell *matCellDef="let row"> 
                <span class="badge bg-danger p-1" *ngIf="row.attivo == '0'" style="color:#fff;">Non attivo</span>
                <span class="badge bg-success p-1" *ngIf="row.attivo == '1'"  style="color:#fff; font-weight:bolder;">Attivo</span>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> Action </th>
          <td mat-cell *matCellDef="let row" class="text-right"> 
            <div role="group" class="btn-group-sm btn-group">
              <button class="btn-shadow btn btn-success btn-sm mr-1 mt-1 mb-1" (click)="piattaformaLoad(row.idpiattaforma)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Modifica piattaforma"></i></button>
              <button class="btn-shadow btn btn-danger btn-sm mt-1 mb-1" (click)="openModalDelete(content, row.idpiattaforma)"><i class="fa fa-fw fa-lg pt-2 pb-2" aria-hidden="true" style="color:#fff;" title="Cancella piattaforma"></i></button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>
    
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <div class="d-block p-4 text-center card-footer">

    </div>
  </div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{bodyModal}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
      <button type="button" class="btn btn-primary" (click)="deletePiattaforma(idpiattaforma); c('Close click')">{{buttonAction}}</button>
    </div>
  </ng-template>
import { ChangeDetectorRef, Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnagraficaKml } from 'src/app/shared/anagraficakml';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { SharedService } from 'src/app/shared/shared-service';

@Component({
  selector: 'app-anagrafica-kml',
  templateUrl: './anagrafica-kml.component.html',
  styleUrls: ['./anagrafica-kml.component.css']
})
export class AnagraficaKmlComponent implements OnInit {

  displayedColumns: string[] = ['descrizione', 'path', 'type', 'action'];
  dataSource: MatTableDataSource<AnagraficaKml>;
  public ngVersion: string = VERSION.full;
  public matVersion: string = '5.1.0'; 
  public breakpoint: number; 
  faPlus = faPlus;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  idkmz:string;
  idmodal:string;
  status:string;
  showList:boolean;
  KmlList: any = [];
  KmlSingle: any = [];
  keysearch: string;
  destitle:string;
  heading = 'Modals';
  subheading = 'Wide selection of modal dialogs styles and animations available.';
  icon = 'pe-7s-phone icon-gradient bg-premium-dark';
  closeResult: string;
  titleModal: string;
  bodyModal: string;
  buttonAction: string;
  actionOp: string;
  saveupd: string;
  action:string;
  share_message;

  public root_href: string = "";
  public root_title: string = "";
  public page_href: string = "";
  public page_title: string = "";
  public detail_href: string = "";
  public detail_title: string = "";
  public token= "dkfjsw3xHRWxmfsfe3QDDqZw429013";

  constructor(
    public restApi: RestApiService,
    private router:Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.status = "master";
    this.showList = true;
    this.destitle = "Nuova anagrafica";
    this.loadKmlList();
  }

  loadKmlList(){
    
    return this.restApi.getKmlList(this.token).subscribe((data: {}) => {
      this.KmlList = data;
      var count = this.KmlList.length;
      if(count == 0){
        this.showList = false;
        console.log('showlist = false');
      }
      this.dataSource = new MatTableDataSource(this.KmlList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  newKml(){
    this.status = "detail";
    this.action = "new";
    // this.nome = "";
    // this.cognome = "";
    // this.username = "";
    // this.email = "";
    // this.password = "";
    // this.confirmpassword = "";
    // this.ruolo = "";
    // this.attivo = "";
    // this.idutente = "";
  }

  loadMaster(){
    this.loadKmlList();
    this.status = "master";
    console.log("master");
  }

  loadDetail(){
    this.status = "detail";
    console.log("detail");
  }

  openModalDelete(content, idkmz) {
    this.idkmz = idkmz;
    this.idmodal = "normal";
    this.titleModal = "Elimina Anagrafica Main";
    this.bodyModal = "Vuoi eliminare il record anagrafica main event selezionato?";
    this.buttonAction = "Elimina";
    this.actionOp = "1";
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


}

<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="scatterChartData"
        [options]="scatterChartOptions"
        [labels]="scatterChartLabels"
        [chartType]="scatterChartType">
      </canvas>
    </div>
  </div>
</div>
